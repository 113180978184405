.production-stage {
  //.t-wrapper {
  //  &__inner {
  //    span {
  //      padding-top: 0;
  //      margin-bottom: 0;
  //    }
  //  }
  //}
  &__list {
    border-top: 1px solid var(--line-on-white);
  }

  &__wrap-content {
    height: 0;
    opacity: 0;
    transition: var(--default-transition);
  }

  &__item {
    border-bottom: 1px solid var(--line-on-white);
    position: relative;
    overflow: hidden;
    transition: transform;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);

    &._opened {
      .production-stage {
        &__link {
          padding-bottom: 0;
        }

        &__wrap-content {
          opacity: 1;
          transform: translateY(0);
        }

        &__link-plus {
          &:after {
            transform: rotate(180deg);
          }
        }

        &__image {
          scale: 1;
        }
      }
    }
  }

  &__link {
    cursor: pointer;
    display: flex;
    width: 100%;
    z-index: 50;
    position: relative;
    align-items: center;
    transition: padding-bottom;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__link-plus {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;

    &:after {
      width: 16px;
      height: 2px;
      top: 50%;
      content: " ";
      position: absolute;
      background: var(--accent-brown);
      transform: rotate(90deg);
      transition: transform;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &:before {
      width: 16px;
      height: 2px;
      top: 50%;
      content: " ";
      position: absolute;
      background: var(--accent-brown);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    scale: 1.2;
    transition: scale;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__wrap-picture {
    position: relative;
    z-index: 20;
  }

  &__wrap-link {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }
}

@include respond-up('s-large') {
  .production-stage {
    margin-top: 150px;

    &__link {
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column6));
      column-gap: var(--grid-gap);

      &:hover {
        .production-stage__link-text {
          color: var(--accent-brown);
        }
      }
    }

    &__link-text {
      width: var(--grid-column5);
      transition: color;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-timing-function);
    }

    &__wrap-picture {
      top: -60px;
      flex: 0 0 var(--grid-column3);
    }

    &__item {

      &._opened {
        .production-stage {
          &__image {
            scale: 1;
          }
        }
      }
    }

    &__wrap {
      margin-top: 30px;
    }

    &__picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 244 / 375);
    }

    &__content {
      display: grid;
      grid-template-columns: var(--grid-column3) var(--grid-column5);
      grid-column-gap: calc(var(--grid-column3) + var(--grid-gap) * 2);
      padding-top: 30px;
      padding-bottom: 5px;
    }

    &__content-text {
      padding-bottom: 80px;
      flex: 0 0 var(--grid-column5);
      margin-right: calc(var(--grid-column) + var(--grid-gap));
    }

    &__link-count {
      position: relative;
      z-index: 10;
    }

    &__wrap-content {
      position: relative;
      z-index: 100;
    }
  }
}

@include respond-up('medium') {
  .production-stage {

    &__link {
      padding-top: 12px;
      padding-bottom: 17px;
    }
  }
}

@include respond('medium') {
  .production-stage {
    margin-top: 100px;

    &__wrap-link {
      margin-left: 30px;
    }

    &__content {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    &__wrap {
      margin-top: 20px;
    }

    &__picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 242 / 372);
    }

    &__content-text {
      width: var(--grid-column5);
    }

    &__wrap-picture {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .production-stage {
    &__content {
      display: flex;
      flex-direction: column-reverse;
    }

    &__wrap-link {
      width: 100%;
    }

    &__link-text {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .production-stage {
    margin-top: 50px;

    &__link {
      padding-top: 8px;
      padding-bottom: 12px;
    }

    &__wrap {
      margin-top: 12px;
    }

    &__wrap-link {
      margin-left: 13px;
    }

    &__picture {
      margin-top: 30px;
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 220 / 338);
    }

    &__content {
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }
}