.small-recall-form {
  &__policy,
  input[type="hidden"] {
    transition: opacity var(--transition);
    + div {
      button[type="submit"] {
        display: none;
      }
    }
  }

  &__success-title-wrap,
  &__success-subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@include respond-up('s-large') {
  .small-recall-form {
    &__submit {
      &.button {
        width: 100%;
      }
    }

    &__success-title-wrapper {
      width: var(--grid-column8);
    }

    &__success-subtitle {
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .small-recall-form {
    &__policy {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .small-recall-form {
    &__form {
      .small-recall-form {
        &__field {
          &::before {
            top: 0;
            height: 100%;
            width: 30px;
            right: -15px;
          }
        }
      }
    }

    &__wrap {
      display: flex;
    }

    &__fields {
      flex: 1 0 50%;
    }



    &__buttons {
      flex: 1 0 50%;
    }

    &__submit {
      &.button {
        .button__right-corner {
          &_fill {
            &::after {
              background: unset;
            }
          }
        }
      }
    }

    &__success {
      padding: 0 30px;
    }

    &__success-subtitle {
      margin-top: 15px;
    }
  }
}

@include respond-down('medium') {
  .small-recall-form {
    &__success-title-wrapper {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .small-recall-form {
    &__policy {
      margin-top: 20px;
    }

    &__success {
      padding: 0 18px;
    }

    &__success-title-wrap {
      width: calc(var(--grid-column4) - 36px);
      flex-direction: column;
      align-items: center;
    }

    &__success-subtitle {
      margin-top: 10px;
      text-align: center;
    }
  }
}