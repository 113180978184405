.reviews {
  &__title {
    display: flex;
  }

  &__list {
    display: grid;
  }

  &__item {
    position: relative;

    &:nth-child(4n + 1) {
      .reviews__title-square-name {
        background: var(--base-black-100);

        &::first-letter {
          color: var(--neutral-biege);
        }
      }
    }

    &:nth-child(4n + 2) {
      .reviews__title-square-name {
        background: var(--accent-blue);

        &::first-letter {
          color: var(--base-white-100);
        }
      }
    }

    &:nth-child(4n + 3) {
      .reviews__title-square-name {
        background: var(--neutral-biege);

        &::first-letter {
          color: var(--accent-brown);
        }
      }
    }

    &:nth-child(4n + 4) {
      .reviews__title-square-name {
        background: var(--accent-brown);

        &::first-letter {
          color: var(--base-white-100);
        }
      }
    }
  }

  &__item-text {
    max-height: 140px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; /* Добавляем многоточие для обрезанного текста */
    white-space: normal;

    &.open {
      -webkit-line-clamp: unset; /* Максимум строк до обрезания */
    }

    &:not(.skip-anim).text__body-little {
      transition: max-height, opacity;
      transition-duration: 0.4s;
      transition-timing-function: var(--default-transition-function);
    }
  }

  &__item-title {
    display: flex;
  }

  &__title-square-name {
    font-size: 0;

    &::first-letter {
      font-weight: 700;
      font-family: var(--font);
      line-height: 140%;
      text-transform: uppercase;
    }
  }

  &__item-wrap-sub-name {
    display: flex;
    align-items: center;
  }

  &__item-wrap-sub-name-square {
    width: 4px;
    height: 4px;
    background: var(--base-black-50);
  }

  &__file-weight,
  &__date,
  &__city {
    color: var(--base-black-50);
  }

  &__wrap-text {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__file {
    display: flex;
    align-items: center;
  }

  &__file-doc {
    background: var(--neutral-grey);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__file-wrap-text {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__file-name {
    color: var(--accent-brown);
  }

  &__wrap-item-text {
    display: flex;
    flex-direction: column;
  }

  &__wrap-picture {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__picture {
    display: flex;
    position: relative;

    &:after {
      background-color: rgba(50, 48, 44, 0.2);
      content: " ";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-text-opener {

    &:before {
      content: 'Читать далее';
      color: var(--accent-blue)
    }

    &.open {
      &:before {
        content: 'Скрыть';
      }
    }
  }
}

@include respond-up('s-large') {
  .reviews {
    &__wrap-list {
      margin-bottom: 150px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__list {
      grid-template-columns: 1fr 1fr;
      row-gap: 80px;
      column-gap: var(--grid-gap);
    }

    &__wrap-item-text {
      width: var(--grid-column5);
      margin-top: 40px;
    }

    &__wrap-picture {
      margin-top: 71px;
    }

    &__item {
      margin-top: 20px;

      &:nth-child(2n + 1) {
        &:before {
          content: "";
          position: absolute;
          width: var(--grid-column12);
          height: 1px;
          top: -20px;
          left: 0;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2n + 2) {
        &:before {
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          left: -20px;
          background: var(--line-on-white);
        }
      }
    }

    &__picture {
      width: calc(var(--grid-column5) / 3 - 12);
      height: calc((var(--grid-column5) / 3) * 141 / 214);
    }

    &__item-text-opener {
      position: relative;

      &:after {
        content: "";
        bottom: 0;
        left: 0;
        background: var(--accent-blue);
        width: 107px;
        height: 1px;
        position: absolute;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform var(--default-timing) var(--teaser-transition-func);
      }

      &.open {
        &:after {
          width: 62px;
        }
      }
    }

    &__item-text-opener {
      &:hover {
        &:after {
          transform-origin: left;
          transition: transform var(--default-timing) var(--teaser-transition-func);
          transform: scaleX(1);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .reviews {
    &__list {
      margin-top: 40px;
    }

    &__title {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      margin-top: 30px;
    }


    &__item-text-opener {
      margin-top: 10px;
    }

    &__title-square-name {
      padding: 20px 24px;
      width: 60px;
      height: 60px;

      &::first-letter {
        font-size: 14px;
      }
    }

    &__wrap-text {
      margin-left: 30px;
    }

    &__wrap-btn {
      width: var(--grid-column2);
    }

    &__item-wrap-sub-name {
      gap: 10px;
    }

    &__file {
      margin-top: 30px;
      gap: 20px;
    }

    &__wrap-picture {
      gap: 6px;
      width: var(--grid-column5);
    }
  }
}

@include respond('medium') {
  .reviews {
    &__wrap-list {
      margin-bottom: 100px;
    }

    &__list {
      gap: 50px;
      //margin-bottom: 50px;
    }

    &__wrap-picture {
      margin-top: 50px;
    }

    &__picture {
      width: calc(var(--grid-column5) / 3 - 12);
      height: calc((var(--grid-column5) / 3) * 137 / 210);
    }

    &__wrap-item-text {
      width: var(--grid-column5);
      margin-top: 30px;
    }

    &__item {
      padding-top: 20px;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        left: 0;
        background: var(--line-on-white);
      }
    }
  }
}

@include respond-down('medium') {
  .reviews {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}

@include respond-down('small') {
  .reviews {
    &__wrap-list {
      margin-bottom: 50px;
    }

    &__list {
      margin-top: 20px;
      gap: 30px;
      //margin-bottom: 30px;
    }

    &__title-square-name {
      padding: 16px 21px 18px;
      width: 52px;
      height: 52px;
    }

    &__wrap-text {
      margin-left: 18px;
    }

    &__title {
      flex-direction: column;
      justify-content: left;
      gap: 20px;
      margin-top: 20px;
    }

    &__title-square-name {
      &::first-letter {
        font-size: 13px;
      }
    }

    &__wrap-btn {
      width: 100%;
    }

    &__item {
      padding-top: 10px;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        left: 0;
        background: var(--line-on-white);
      }
    }

    &__item-wrap-sub-name {
      gap: 8px;
    }

    &__file {
      margin-top: 20px;
      gap: 14px;
    }

    &__wrap-item-text {
      width: var(--grid-column4);
      margin-top: 20px;
    }

    &__wrap-picture {
      margin-top: 30px;
      gap: 4px;
      width: var(--grid-column4);
    }


    &__item-text-opener {
      margin-top: 8px;
    }

    &__picture {
      width: calc(var(--grid-column4) / 3 - 8);
      height: calc((var(--grid-column4) / 3) * 73 / 110);
    }
  }
}