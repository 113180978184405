.work-scheme-tabs {
  &__label {
    color: var(--base-black-50);
  }

  &__table {
    display: grid;
    gap: var(--grid-gap);
  }

  &__item-body {
    border-right: 1px solid var(--line-on-white);
    border-bottom: 1px solid var(--line-on-white);
    border-left: 1px solid var(--line-on-white);
  }

  &__item-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background: var(--base-black-100);
    padding: 0 var(--grid-gap);

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      content: " ";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-color: transparent var(--base-white-100) transparent transparent;
      transform: rotate(0deg);
    }
  }

  &__item-head-text {
    color: var(--neutral-biege);
  }

  //&__icon{
  //  svg{
  //    path{
  //      fill: var(--accent-brown);
  //      stroke-width: 0;
  //    }
  //    circle{
  //      stroke: var(--accent-brown);
  //    }
  //  }
  //}

  &__item-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: var(--grid-gap);
  }

  &__item-body-top {
    display: flex;
    flex-direction: column;
  }

  &__item-description{
    display: block;
  }
}

@include respond-up('s-large') {
  .work-scheme-tabs {
    &__description {
      margin-top: 70px;
      width: var(--grid-column8);
    }

    &__table {
      margin-top: 70px;
      grid-template-columns: repeat(2, var(--grid-column6));
    }

    &__item-body {
      height: 489px;
    }
  }
}

@include respond-up('medium') {
  .work-scheme-tabs {
    &__wrap-content {
      margin-top: 30px;
    }

    &__item-head {
      height: 44px;

      &:after {
        border-width: 0 15px 15px 0;
      }
    }

    &__item-body-top {
      gap: 30px;
    }

    &__icon {
      width: 60px;
      height: 60px;

      svg {
        width: 60px;
        height: 60px;
      }
    }

    &__item-description{
      width: var(--grid-column5);
    }
  }
}

@include respond('medium') {
  .work-scheme-tabs {
    &__description {
      margin-top: 40px;
      width: var(--grid-column6);
    }

    &__table {
      margin-top: 60px;
    }

    &__item-body-top-title {
      letter-spacing: 0.01px;
    }

    &__item-body {
      height: 449px;
    }
  }
}

@include respond-down('medium') {
  .work-scheme-tabs {

    &__table {
      margin-top: 70px;
      grid-template-columns: 1fr;
    }
  }
}

@include respond-down('small') {
  .work-scheme-tabs {
    &__wrap-content {
      margin-top: 20px;
    }

    &__description {
      margin-top: 20px;
      width: var(--grid-column4);
    }

    &__table {
      margin-top: 40px;
    }

    &__item-body {
      height: 457px;
    }

    &__item-head {
      height: 38px;

      &:after {
        border-width: 0 12px 12px 0;
      }
    }

    &__item-body-top {
      gap: 20px;
    }

    &__icon {
      width: 50px;
      height: 50px;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    &__item-description{
      width: var(--grid-column4);
    }
  }
}