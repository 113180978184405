.built-project {
  display: flex;
  width: 100%;
  background: var(--neutral-grey);
  cursor: pointer;

  &__wrap-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__text-bottom {
    color: var(--accent-brown);
    position: relative;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('s-large') {
  .built-project {
    &__wrap-text {
      padding: 30px 40px 35px;
      column-gap: 40px;
    }

    &__picture {
      display: flex;
      flex-shrink: 0;
      //height: calc((var(--grid-column1) + var(--grid-gap) + var(--grid-gap)/2) * 120 / 159);
      width: calc(var(--grid-column1) + var(--grid-gap) + var(--grid-gap) / 2);
    }

    //&__text-bottom{
    //  position: relative;
    //  display: block;
    //  &:after {
    //    content: '';
    //    width: 100%;
    //    height: 1px;
    //    display: block;
    //    position: absolute;
    //    background: var(--accent-brown);
    //    bottom: 0;
    //    transform: scaleX(0);
    //    transform-origin: right;
    //    transition: transform 0.3s ease-out;
    //  }
    //}
    //&.underline{
    //  .built-project {
    //    &__text-bottom{
    //      &::after {
    //        transform-origin: left;
    //        transition: transform 0.3s var(--teaser-transition-func);
    //        transform: scaleX(1);
    //      }
    //    }
    //  }
    //}
  }
}

@include respond-up('medium') {
  .built-project {
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__wrap-text {
      align-items: center;
    }

    &__arrow {
      flex: 0 0 20px;
      height: 20px;
    }
  }
}

@include respond('medium') {
  .built-project {
    &__wrap-text {
      padding: 25px 30px;
      column-gap: 30px;
    }

    &__picture {
      display: flex;
      flex-shrink: 0;
      //height: calc((var(--grid-column1) + var(--grid-gap)) * 102 / 135);
      width: calc(var(--grid-column1) + var(--grid-gap));
    }
  }
}

@include respond-down('medium') {
  .built-project {

  }
}

@include respond-down('small') {
  .built-project {
    &__image {
      position: absolute;
      width: calc(var(--grid-column1) + var(--grid-gap) - 6px);
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__wrap-text {
      padding: 14px 18px 16px;
      align-items: flex-start;
      column-gap: 16px;
    }

    &__arrow {
      flex: 0 0 14px;
      height: 14px;
    }

    &__picture {
      //flex-shrink: 0;
      //height: calc((var(--grid-column1) + var(--grid-gap) - 6px) * 97 / 83);
      flex: 0 1 calc(var(--grid-column1) + var(--grid-gap) - 6px);
      display: flex;
      overflow: hidden;
      position: relative;
    }
  }
}