.production {
  &__wrap-video{
    margin-top: var(--grid-gap);

    .video-block {
      &__link {
        width: 100%;
      }
    }
  }
}

@include respond-up('s-large') {
  .production {
    &__text-sub-accordion{
      margin-top: 100px;
      width: var(--grid-column8);
    }
    &__link{
      margin-top: 80px;
    }

    .images-block-small__video-link-title-wrap{
      width: var(--grid-column10);
    }
  }
}

@include respond-up('medium') {
  .production {

  }
}

@include respond('medium') {
  .production {
    &__text-sub-accordion{
      margin-top: 70px;
      width: var(--grid-column6);
    }
    &__link{
      margin-top: 60px;
    }

    .images-block-small__video-link-title-wrap{
      width: var(--grid-column4);
    }
  }
}

@include respond-down('medium') {
  .production {

  }
}

@include respond-down('small') {
  .production {
    &__text-sub-accordion{
      margin-top: 40px;
      width: var(--grid-column4);
    }
    &__link{
      margin-top: 30px;
    }
    .images-block-small__video-link-title-wrap{
      width: var(--grid-column3);
    }
  }
}