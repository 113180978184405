.floating-field {
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: all .3s var(--default-transition-function);
    margin: 0;

    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  &._filled textarea + label,
  textarea:focus + label,
  &._filled input + label,
  input:focus + label {
    font-size: 12px;
    font-weight: 500;
    line-height: 120%;
    padding-top: 9px;
  }

  & > input[type="text"], & > input[type="password"], & > input[type="search"] {
    &:-internal-autofill-selected {
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
  }
}

@include respond-up('medium') {
  .floating-field {
    label {
      padding: 17px 20px;
    }
  }
}

@include respond-down('small') {
  .floating-field {
    label {
      font-size: 15px;
      padding: 15px;
    }
  }
}