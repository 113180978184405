.article-card {
  position: relative;
  display: flex;
  flex-direction: column;

  &__item-link {
    display: flex;
    flex-direction: column;
  }

  &__item-title-wrap {
    display: inline-flex;
    flex-direction: column;
  }

  &__item-title {
    transition: color var(--default-timing) var(--default-transition-function);

    span {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        background: var(--accent-brown);
        bottom: 1px;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-out;
      }
    }
  }

  &__wrap-image {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    width: 100%;
    display: flex;
    overflow: hidden;
  }

  &__text-wrap {
    margin-top: 20px;
  }

  &__text {
    max-height: 66px;
    overflow: hidden;
    transition: max-height 0.4s linear;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; /* Добавляем многоточие для обрезанного текста */
    white-space: normal;

    &.open {
      -webkit-line-clamp: unset; /* Максимум строк до обрезания */
    }
  }

  &__text-opener {

    &:before {
      content: 'Читать далее';
      color: var(--accent-blue)
    }

    &.open {
      &:before {
        content: 'Скрыть';
      }
    }
  }
}

@include respond-up('s-large') {
  .article-card {
    &__item-link {

      &:hover {
        .article-card {
          &__item-date-arrow {
            opacity: 1;
          }

          &__item-title {
            color: var(--accent-brown);

            span {
              &::after {
                transform-origin: left;
                transition: transform 0.3s var(--teaser-transition-func);
                transform: scaleX(1);
              }
            }

          }
        }
      }
    }

    &__item-date {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 50px;
    }

    &__item-date-arrow {
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__picture {
      height: calc(var(--grid-column3) * 240 / 375);
    }
  }
}

@include respond-up('medium') {
  .article-card {
    &__wrap-image {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .article-card {
    &__picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 238 / 372);
    }
  }
}

@include respond-down('medium') {
  .article-card {
    &__item-date-arrow {
      display: none;
    }

    &__item-date {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .article-card {
    width: var(--grid-column4);

    &__wrap-image {
      margin-top: 18px;
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 216 / 338);
    }
  }
}