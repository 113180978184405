.project-variants {
  --icon-size: 14px;

  &__price {
    color: var(--item-active-color);
  }

  &__inner-item-left-icon {
    opacity: 0;
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: var(--item-right-padding);

    svg {
      height: var(--icon-size);
      width: var(--icon-size);
      display: flex;

      path {
        stroke: var(--item-title-color);
      }
    }
  }

  &__inner-item-right-icon {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: var(--item-right-padding);
    //opacity: 0;
    transition: opacity;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);

    svg {
      height: var(--icon-i-size);
      width: var(--icon-i-size);
      display: flex;

      path {
        fill: var(--item-title-color);
      }

      rect {
        fill: var(--icon-bg-color);
      }
    }
  }

  &__description {
    position: absolute;
    opacity: 0;
    transition: transform, opacity;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
    transform: translateY(10%);
    transform-origin: bottom left;
    padding: 16px 18px;
    background: var(--base-white-100);
    border: 1px solid var(--line-on-white);
    color: var(--base-black-100);
    z-index: -1;
  }

  &__item-bottom {
    padding: var(--item-right-padding) var(--item-right-padding) var(--inner-item-top-padding) var(--item-right-padding);
  }

  &__inner-list {
    border-bottom: 1px solid var(--item-border-color);
  }

  &__name {
    color: var(--item-color);
  }

  &__inner-item {
    padding: var(--inner-item-top-padding) var(--item-right-padding) var(--inner-item-top-padding) calc(var(--item-right-padding) * 2 + 4px);
    position: relative;
    flex: 1 0 auto;
    height: 100%;
    border-top: 1px solid var(--item-border-color);
    display: flex;
    flex-direction: row;
    align-items: center;

    &._active {
      .project-variants {
        &__inner-item-left-icon {
          opacity: 1;
        }

        &__name {
          color: var(--item-active-color);
        }
      }
    }
  }

  &__item-title {
    padding: var(--item-right-padding);
  }

  &__item {
    padding-bottom: var(--item-right-padding);
    background-color: var(--item-background-color);
    --haver-background: var(--base-black-5);

    .h3 {
      span {
        color: var(--item-title-color);
      }
    }

    &:not(:last-child) {
      margin-right: var(--grid-gap);

      &:before {
        width: calc(100% + var(--grid-gap));
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background: var(--line-on-white);
    }

    &:not(:first-child) {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        top: 0;
        left: calc(-1 * var(--grid-gap) / 2);
        background: var(--line-on-white);
      }
    }

    &:nth-child(3n+1) {
      --item-background-color: var(--neutral-grey);
      --item-title-color: var(--accent-brown);
      --item-border-color: var(--line-on-grey);
      --icon-bg-color: var(--base-white-80);
      --item-color: var(--base-black-50);
      --item-active-color: var(--base-black-100);
    }

    &:nth-child(3n+2) {
      --item-background-color: var(--neutral-biege);
      --item-title-color: var(--accent-brown);
      --item-border-color: var(--line-on-biege);
      --icon-bg-color: var(--base-white-50);
      --item-color: var(--base-black-50);
      --item-active-color: var(--base-black-100);
    }

    &:nth-child(3n) {
      --item-background-color: var(--accent-brown);
      --item-title-color: var(--base-white-100);
      --item-border-color: var(--line-on-brown);
      --icon-bg-color: var(--base-white-20);
      --item-color: var(--base-white-50);
      --item-active-color: var(--base-white-100);
    }
  }
}

@include respond-up('s-large') {
  .project-variants {
    padding-top: 75px;
    margin-top: 75px;

    .wide-scroll__outer,
    .swiper {
      padding-bottom: 150px;
      margin-bottom: -150px;
    }

    &__description {
      right: 10px;
      left: calc(var(--grid-column1) + var(--grid-gap) + 23px);
      pointer-events: none;

      &._first{
        top: 54px;
      }

      &._second{
        bottom: 54px;
      }

      &._hover {
        opacity: 1;
        transform: translateY(0);
        z-index: 100;
      }
    }

    &__inner-item {
      transition: background;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);

      &:hover {
        background-color: var(--haver-background);
        .project-variants {
          &__inner-item-right-icon {
            opacity: 1;
          }
        }
      }
    }

    &__item {
      &:before {
        top: -20px;
      }
    }

    &__list {
      padding-top: 20px;
      margin-top: 30px;
    }

    &__inner-item-right-icon {
      opacity: 0;
    }
  }
}

@include respond('s-large') {
  .project-variants {
  }
}

@include respond-up('medium') {
  .project-variants {
    --item-right-padding: 30px;
    --inner-item-top-padding: 10px;
    --icon-i-size: 22px;

    &__wrapper {
      position: relative;
    }

    &__item-bottom {
      .button {
        margin-top: 24px;
      }
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column4);
      }
    }
  }
}

@include respond('medium') {
  .project-variants {
    margin-top: 50px;
    padding-top: 50px;

    .wide-scroll__outer,
    .swiper {
      padding-bottom: 100px;
      margin-bottom: -100px;
    }

    &__item {
      &:before {
        top: -15px;
      }
    }

    &__list {
      padding-top: 15px;
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .project-variants {
    &__description {
      right: 8px;
      left: 40px;
      &._first{
        top: 52px;
      }

      &._second{
        bottom: 52px;
      }
    }

    &__inner-item{
      &:hover{
        .project-variants__description{
          opacity: 1;
          transform: translateY(0);
          z-index: 100;
        }
      }
    }
  }
}

@include respond-down('small') {
  .project-variants {
    margin-top: 25px;
    padding-top: 25px;
    --icon-i-size: 18px;
    --item-right-padding: 18px;
    --inner-item-top-padding: 8px;

    .wide-scroll__outer,
    .swiper {
      padding-bottom: 20px;
      margin-bottom: -20px;
    }

    &__item-bottom {
      .button {
        margin-top: 16px;
      }
    }

    &__item {
      &.swiper-slide {
        width: calc(var(--grid-column4) - var(--grid-spacer-and-indent));
      }
    }

    &__item {
      &:before {
        top: -9px;
      }
    }

    &__list {
      padding-top: 9px;
      margin-top: 12px;
    }
  }
}