.slider-pagination {
  .swiper-pagination-bullet {
    border-radius: 0;
    background: var(--base-white-30);
    position: relative;
    width: 8px;
    transition: width var(--default-timing) var(--default-transition-function);

    &:before {
      content: " ";
      left: 0;
      top: 0;
      height: 8px;
      width: 0;
      display: block;
      position: absolute;
      background: var(--t);
      transition: background var(--default-timing) var(--default-transition-function);
    }

    &.swiper-pagination-bullet-active {
      width: 26px;

      &:before {
        background: var(--base-white-100);
        animation: pagination-fill 5s linear forwards;
      }
    }
  }
}

@keyframes pagination-fill {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@include respond-down('small') {
  .slider-pagination {
    .swiper-pagination-bullet {
      width: 6px;

      &.swiper-pagination-bullet-active {
        width: 22px;

      }
    }
  }
}