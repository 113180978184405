.wide-scroll {
  padding-left: var(--grid-spacer-and-indent);
  padding-right: var(--grid-spacer-and-indent);
  margin-left: calc(-1 * var(--grid-spacer-and-indent));
  margin-right: calc(-1 * var(--grid-spacer-and-indent));
  overflow: scroll;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }

  &__outer {
    margin-left: calc(-1 * var(--grid-spacer-and-indent));
    margin-right: calc(-1 * var(--grid-spacer-and-indent));
    overflow: scroll;

    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */

    &::-webkit-scrollbar {
      display: none;
      width: 0px;
    }
  }

  &__inner {
    padding-left: var(--grid-spacer-and-indent) !important;
    padding-right: var(--grid-spacer-and-indent) !important;
  }
}