.city-cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1010;
  display: none;

  &._sticky {
    .city-cover {
      &__content-inner {
        transform: translate3d(0, var(--minus-header-height), 0);
        transition: transform .3s var(--default-transition-function);
      }
    }
  }

  &._up {
    .city-cover {
      &__content-inner {
        transform: translate3d(0, 0, 0) !important;
        transition-duration: .3s !important;
      }
    }
  }

  &._hide-bg {
    .city-cover {
      &__bg {
        opacity: 0;
      }
    }
  }

  &._show {
    display: block;
  }


  &._to-choice {
    .city-cover {
      &__question {
        display: none;
      }

      &__cities-wrap {
        display: block;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(64, 64, 61, 0.4);
    z-index: 120;
    transition: opacity var(--transition);
  }

  &__content {
    position: absolute;
    z-index: 130;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  &__content-inner {
    position: absolute;
    top: var(--header-height);
    pointer-events: all;
    background-color: var(--base-white-100);
    border: 1px solid var(--line-on-white);
    --minus-header-height: calc(-1 * var(--header-height));
  }

  &__question {
    display: flex;
    flex-direction: column;
  }

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }

  &__cities-wrap {
    display: none;
  }

  &__no {
    text-wrap: nowrap;
  }
}

@include respond-up('s-large') {
  .city-cover {
    &__content-inner {
      min-width: calc(294/1700 * 100vw);
    }

    &__content-inner {
      left: 356px; // logo + burger width (272 + 84)
    }
  }
}

@include respond-up('medium') {
  .city-cover {
    &__content-inner {
      padding: 30px 40px;
    }

    &__question {
      row-gap: 20px;
    }

    &__yes {
      width: 72px;
    }
  }
}

@include respond('medium') {
  .city-cover {
    &__content-inner {
      left: var(--grid-spacer-and-indent);
      top: calc(var(--header-height) + 30px);
      min-width: calc(var(--grid-column2) + var(--grid-gap) + 16px);
    }
  }
}

@include respond-down('medium') {
  .city-cover {
    &__content-inner {
      left: var(--grid-spacer-and-indent);
    }
  }
}

@include respond-down('small') {
  .city-cover {
    &__content-inner {
      right: var(--grid-spacer-and-indent);
      top: calc(var(--header-height) + 18px);
      padding: 18px;
    }

    &__question {
      row-gap: 14px;
    }

    &__yes {
      &.button {
        width: 72px;
      }
    }
  }
}