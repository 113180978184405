.footer {
  background: var(--base-black-100);

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title-text {
    color: var(--neutral-biege);
  }

  &__title-contacts {
    display: flex;
    flex-direction: column;
  }

  &__title-phone {
    color: var(--neutral-biege)
  }

  &__title-mail {
    margin-top: 2px;
    color: var(--accent-blue)
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__list-title {
    color: var(--neutral-biege-50);
  }

  &__list-links {
    display: flex;

    li {
      display: flex;
    }

    a {
      flex-wrap: wrap;
      padding: 4px 0;
      color: var(--neutral-biege);
    }

    a::first-letter {
      text-transform: uppercase;
    }
  }

  &__list-links-left {
    display: flex;
    flex-direction: column;
  }

  &__list-links-right {
    display: flex;
    flex-direction: column;
  }

  &__social {
    display: flex;
    flex-direction: column;
  }

  &__social-title {
    color: var(--neutral-biege-50);
  }

  &__social-items {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--line-on-black);

    .social-link {
      &__text {
        &::after {
          background: var(--neutral-biege);
        }
      }
    }
  }

  &__social-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0;
    border-bottom: 1px solid var(--line-on-black);

  }

  &__social-item-text {
    color: var(--neutral-biege)
  }

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__info-association {
    display: flex;
    align-items: center;
  }

  &__info-association-text {
    color: var(--neutral-biege-50)
  }

  &__info-developer {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__info-developer-company {
    color: var(--neutral-biege-50)
  }

  &__info-developer-politic {
    color: var(--neutral-biege)
  }

  &__info-developer-make {
    color: var(--neutral-biege-50)
  }

  &__info-developer-maker {
    color: var(--neutral-biege)
  }

  &__list-links-text {
    &.t-wrapper__inner {
      span {
        transition-delay: unset !important;
      }
    }
  }
}

@include respond-up('s-large') {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;

    &__item {
      a {
        span {
          transition: color var(--default-timing) var(--teaser-transition-func);
        }
      }

      &:hover {
        .footer {
          &__item {
            a {
              span {
                color: #918A7A;
              }
            }
          }
        }

      }
    }

    &__title {
      grid-row: 1/2;
      grid-column: 1/4;
    }

    &__title-contacts {
      margin-top: 40px;
    }

    &__list-left {
      grid-column: 5/8;
      grid-row: 1/2;
    }

    &__list-links-wrap {
      margin-top: 46px;
    }

    &__list-links-text {
      &.t-wrapper__inner {
        width: unset;
      }
    }

    &__list-right {
      grid-column: 8/11;
      grid-row: 1/2;
    }

    &__social {
      grid-column: 11/13;
      grid-row: 1/2;
    }

    &__social-items {
      margin-top: 50px;
    }

    &__info {
      margin-top: 50px;
      grid-column: 1/13;
      grid-row: 2/3;
    }

    &__info-association {
      width: calc(var(--grid-column2) + var(--grid-gap));
    }

    &__logo-bottom {
      margin-top: 2px;
      grid-row: 3/4;
      grid-column: 1/13;

      svg {
        height: 100%;
        width: var(--grid-column12);
      }
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__title-text {
      margin-top: 30px;
    }


    &__list-links {
      flex-direction: column;

      a {
        width: var(--grid-column2);
      }
    }

    &__info-association-icon {
      svg {
        width: 45px;
        height: 45px;
      }
    }

    &__info-association {
      gap: 20px;
    }
  }
}

@include respond('medium') {
  .footer {
    padding-top: 30px;
    padding-bottom: 30px;

    &__title {
      grid-row: 1/2;
      grid-column: 1/4;
    }

    &__title-contacts {
      margin-top: 30px;
    }

    &__list-links-wrap {
      margin-top: 26px;
    }

    &__list-left {
      flex-direction: column;
      grid-row: 1/2;
      grid-column: 4/7;
    }

    &__list-right {
      margin-top: 36px;
      grid-row: 2/3;
      grid-column: 4/7;
    }

    &__social {
      margin-top: 36px;
      grid-row: 2/3;
      grid-column: 1/3;
    }

    &__social-items {
      margin-top: 20px;
    }

    &__info {
      margin-top: 50px;
      grid-row: 3/4;
      grid-column: 1/7;
    }

    &__logo-bottom {
      margin-top: 10px;
      grid-row: 4/5;
      grid-column: 1/7;

      svg {
        height: 100%;
        width: var(--grid-column6);
      }
    }

    &__info-association {
      width: calc(var(--grid-column2) + var(--grid-gap));
    }

    &__info-developer {
      width: var(--grid-column3);
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__list-links {
      margin-bottom: -4px;
    }
  }
}

@include respond-down('small') {
  .footer {
    padding-top: 18px;
    padding-bottom: 18px;

    &__title {
      grid-row: 1/2;
      grid-column: 1/5;
    }

    &__title-icon {
      svg {
        width: 36px;
        height: 36px;
      }
    }

    &__title-text {
      margin-top: 20px;
    }

    &__title-contacts {
      margin-top: 20px;
    }

    &__list-left {
      flex-direction: column;
      grid-row: 2/3;
      grid-column: 1/5;
      margin-top: 22px;
    }

    &__list-right {
      grid-row: 3/4;
      grid-column: 1/5;
      margin-top: 22px;
    }

    &__list-links-wrap {
      margin-top: 11px;
      display: flex;
      column-gap: var(--grid-gap);
    }

    &__list-links {
      flex-direction: column;
      flex: 0 0 var(--grid-column2);

      a {
        width: var(--grid-column2);
      }
    }

    &__list-right {
      .footer__list-links {
        //flex-direction: row;
        //gap: var(--grid-gap);
      }
    }

    &__list-links-left {
      width: var(--grid-column2);
    }

    &__list-links-right {
      width: var(--grid-column2);
    }

    &__social {
      grid-row: 4/5;
      grid-column: 1/5;
      margin-top: 22px;
    }

    &__social-items {
      margin-top: 10px;
    }

    &__info {
      grid-row: 5/6;
      grid-column: 1/5;
      margin-top: 22px;
      flex-direction: column;
      justify-content: flex-start;
      width: var(--grid-column3);
      gap: 30px;
    }

    &__info-association-icon {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    &__info-association {
      gap: 18px;
    }

    &__logo-bottom {
      margin-top: 22px;
      grid-row: 6/7;
      grid-column: 1/5;

      svg {
        height: 100%;
        width: var(--grid-column4);
      }
    }

    &__list-left {
      .footer {
        &__list-links {
          flex: 0 0 var(--grid-column4);

          a {
            width: var(--grid-column4);
          }
        }
      }
    }
  }
}