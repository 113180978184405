.partners-page {
}

@include respond-up('s-large') {
  .partners-page {
    &__link{
      margin-top: 80px;
    }
  }
}

@include respond-up('medium') {
  .partners-page {
    margin-top: 70px;
  }
}

@include respond('medium') {
  .partners-page {
    &__link{
      margin-top: 60px;
    }
  }
}

@include respond-down('medium') {
  .partners-page {

  }
}

@include respond-down('small') {
  .partners-page {
    margin-top: 40px;
    &__link{
      margin-top: 40px;
    }
  }
}