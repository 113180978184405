.about-block {
  display: flex;
  flex-direction: column;

  &__content {
    border-top: 1px solid var(--line-on-white);
    padding-top: 20px;
  }

  &__image-wrap {
    width: var(--grid-column3);
  }

  &__image-wrap,
  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture,
  &__image {
    width: 100%;
    height: 100%;
  }

  &__image {
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .about-block {
    row-gap: 70px;

    &__title-wrap {
      width: var(--grid-column7);
    }

    &__content {
      margin-top: 30px;
      display: grid;
      grid-template-columns: var(--grid-column3) var(--grid-column3) var(--grid-column5) var(--grid-column);
      grid-column-gap: var(--grid-gap);
    }

    &__image-wrap {
      height: calc(var(--grid-column3) * 332/375);
      grid-column: 1/2;
    }

    &__text-wrap {
      grid-column: 3/4;
    }

    &__bottom-text-wrap {
      width: var(--grid-column8);
      margin-top: 150px;
    }
  }
}

@include respond-up('medium') {
  .about-block {

  }
}

@include respond('medium') {
  .about-block {
    row-gap: 40px;

    &__title-wrap {
      width: var(--grid-column5);
    }

    &__content {
      margin-top: 20px;
      row-gap: 70px;
    }

    &__image-wrap {
      height: calc(var(--grid-column3) * 329/372);
    }

    &__bottom-text-wrap {
      width: var(--grid-column6);
      margin-top: 100px;
    }
  }
}

@include respond-down('medium') {
  .about-block {
    &__content {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

@include respond-down('small') {
  .about-block {
    row-gap: 20px;

    &__title-wrap {
      width: var(--grid-column4);
    }

    &__content {
      margin-top: 12px;
      row-gap: 40px;
    }

    &__image-wrap {
      height: calc(var(--grid-column3) * 220/249);
    }

    &__bottom-text-wrap {
      width: var(--grid-column4);
      margin-top: 50px;
    }
  }
}