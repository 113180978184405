.drop-menu {
  position: absolute;
  z-index: 4650;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  padding-top: var(--header-height);

  &._opened {
    pointer-events: auto;

    .drop-menu {
      &__inner-background {
        transform: scale3d(1, 1, 1);
      }

      &__item-value-wrap {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition-delay: .1s;
      }
    }
  }

  &__inner {
    transition: transform .4s var(--default-transition-function);
    position: relative;
    border-top: 1px solid var(--line-on-white);

    overflow: scroll;
    max-height: calc(100vh - var(--header-height));

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__inner-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--base-white-100);
    transform-origin: top;
    transition: transform .4s var(--default-transition-function);
    transform: scale3d(1, 0, 1);
  }

  &__item-value-wrap {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
    transition: {
      property: transform, opacity;
      duration: .4s;
      timing-function: var(--default-transition-function);
    };
  }

  &__link {
    display: block;
  }

  &__contacts-wrap {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__contacts-socials-list {
    display: flex;
    align-items: center;
    margin: -10px;
  }

  &__contacts-socials-link {
    padding: 10px;
    display: flex;
  }

  &__item {
    &_completed-projects {
        .drop-menu {
          &__link-list {
            margin-top: -5px;
          }
        }
    }
  }
}

@include respond-up('s-large') {
  .drop-menu {
    &__inner {
      padding: 40px 0;
    }

    &__wrap {
      display: grid;
      grid-template-columns: var(--grid-column3) 1fr;
      grid-column-gap: calc(var(--grid-column) + var(--grid-gap) * 2);
    }

    &__city-wrap {
      &.city-opener {
        display: none;
      }
    }

    &__list {
      grid-column: 2/3;
      grid-column-gap: calc(var(--grid-column) + var(--grid-gap) * 2);
    }

    &__link {
      padding: 4px 0;

      &.underline {
        .drop-menu {
          &__link-title {
            color: var(--accent-brown);

            &::after {
              transform-origin: left;
              transition: transform 0.3s var(--teaser-transition-func);
              transform: scaleX(1);
            }
          }
        }
      }
    }

    &__link-title {
      position: relative;
      transition: color var(--transition);

      &::after {
        content: '';
        width: 100%;
        border-bottom: 1px solid var(--accent-brown);
        display: block;
        position: absolute;
        bottom: -2px;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-out;
      }

      &.t-wrapper__inner {
        overflow: visible;
      }
    }

    &__link-list {
      margin-top: 26px; // 30px - 4px link padding
      margin-bottom: -4px;
    }

    &__phone {
      display: none;
    }

    &__contacts-wrap {
      grid-column: 1/3;
      margin-top: 60px;
    }

    &__images-list {
      grid-column: 1/2;
      position: relative;
      height: calc(var(--grid-column3) * 233 / 375);
      overflow: hidden;
    }

    &__image-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(1.1);
      opacity: 0;
      transition: opacity .6s var(--default-transition-function), transform .6s var(--default-transition-function) .6s;

      &._active {
        transform: scale(1);
        opacity: 1;
        transition-delay: 0s;
        transition: opacity .6s var(--default-transition-function), transform .6s var(--default-transition-function) 0s;
      }
    }

    &__picture {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__link-item {
      width: var(--grid-column3);
    }

    &__city-opener-closer {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .drop-menu {
    &__list {
      display: grid;
      grid-template-areas: "type company"
                           "style company"
                           ". company"
                           "completed-projects completed-projects";
      grid-template-columns: repeat(2, var(--grid-column3));
    }

    &__item {
      &_projects-by-type {
        grid-area: type;
      }
      &_projects-by-style {
        grid-area: style;
        margin-top: 50px;
      }
      &_company {
        grid-area: company;
      }
      &_completed-projects {
        grid-area: completed-projects;
        margin-top: 40px;
      }
      &_mob-links {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .drop-menu {
    &__inner {
      padding: 30px 0 40px;
    }

    &__list {
      margin-top: 70px;
      grid-column-gap: var(--grid-gap);
    }

    &__link-list {
      margin-top: 25px; // 30px - 5px link padding
      margin-bottom: -5px;
    }

    &__contacts-left {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &__contacts-left,
    &__contacts-socials-wrap {
      flex: 0 0 var(--grid-column3);
    }

    &__city-wrap {
      .city-opener {
        &__cities-wrap {
          top: calc(100% + 20px);
          width: unset;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .drop-menu {
    height: calc(var(--vh, 1svh) * 100);

    &__inner,
    &__container,
    &__wrap {
      height: 100%;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
    }

    &__images-list {
      display: none;
    }

    &__link {
      padding: 5px 0;
    }

    &__contacts-wrap {
      margin-top: auto;
    }

    &__city-wrap {
      z-index: 100;
      .city-opener {
        &__cities-wrap {
          box-shadow: 0 20px 20px 0 #40403D1A;
        }
      }
    }

    &__city-opener-closer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    &._opened {
      .drop-menu {
        &__item {
          &_company {
            &::before {
              opacity: 1;
              top: 0;
            }
          }
        }
      }
    }

    &__inner {
      padding: 20px 0;
    }

    &__item-title,
    &__email {
      display: none;
    }

    &__list {
      margin-top: 35px;
    }

    &__item {
      display: none;

      &_company {
        margin-top: 19px;
        padding-top: 15px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -20px;
          left: 0;
          right: 0;
          border-bottom: 1px solid var(--line-on-white);
          opacity: 0;
          transition: {
            property: opacity, top;
            duration: .4s;
            timing-function: var(--default-transition-function);
            delay: .1s;
          };
        }
      }

      &_company,
      &_mob-links {
        display: block;
      }
    }

    &__contacts-wrap {
      flex-direction: column;
      row-gap: 10px;
      align-items: flex-start;
    }

    &__city-wrap {
      .city-opener {
        &__cities-wrap {
          top: calc(100% + 10px);
        }
      }
    }

    &__phone {
      &.text__subheader-middle {
        font-size: 15px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: unset;
      }
    }

    &__contacts-socials-list {
      margin: -8px;
    }

    &__contacts-socials-link {
      padding: 8px;
    }
  }
}