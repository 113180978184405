.equipment-image-block {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 10;
  }

  &::before {
    top: 0;
    background: linear-gradient(0deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.6) 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.8) 100%);
  }

  &__image-wrap {
    width: 100%;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    z-index: 20;
    left: var(--grid-spacer-and-indent);
    right: var(--grid-spacer-and-indent);
  }

  &__left-text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__item-list {
    border-top: 1px solid var(--base-white-100);
    border-bottom: 1px solid var(--base-white-100);
  }

  &__item {
    padding-top: 9px;
    &:not(:first-child) {
      border-top: 1px solid var(--base-white-100);
    }
  }
}

@include respond-up('s-large') {
  .equipment-image-block {
    &::before {
      height: calc(100vw * 396/1700);
    }

    &::after {
      height: calc(100vw * 349/1700);
    }

    &__picture {
      height: calc(100vw * 819/1700);
    }

    &__content {
      top: 30px;
      bottom: 70px;
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column6));
      grid-column-gap: var(--grid-gap);
    }

    &__left-text-wrap {
      justify-content: space-between;
    }

    &__title-wrap {
      margin-top: 40px;
    }

    &__right-text-wrap {
      display: flex;
      align-items: flex-end;
    }


  }
}

@include respond-up('medium') {
  .equipment-image-block {
    &__description {
      width: var(--grid-column5);
    }

    &__item-list {
      width: var(--grid-column3);
    }

    &__item {
      padding-bottom: 8px;
    }
  }
}

@include respond('medium') {
  .equipment-image-block {
    &::before {
      height: calc(100vw * 240/834);
    }

    &::after {
      height: calc(100vw * 272/834);
    }

    &__picture {
      height: calc(100vw * 760/834);
    }

    &__content {
      top: 30px;
      bottom: 60px;
    }

    &__title-wrap {
      margin-top: 30px;
    }

    &__item-list {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .equipment-image-block {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

@include respond-down('small') {
  .equipment-image-block {
    &::before {
      height: calc(100vw * 230/374);
    }

    &::after {
      height: calc(100vw * 290/374);
    }

    &__picture {
      height: calc(100vw * 639/374);
    }

    &__content {
      top: 18px;
      bottom: 30px;
    }

    &__title-wrap {
      margin-top: 20px;
    }

    &__item-list {
      margin-top: 30px;
    }

    &__item {
      padding-bottom: 6px;
    }
  }
}