.advantage-item {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__head {
    border-top: 1px solid var(--line-on-white);
    border-bottom: 1px solid var(--line-on-white);
  }

  &__content {
    height: 100%;
  }

  &__text-wrap {
    display: flex;

    &_grey {
      background: var(--neutral-grey);
    }

    &_beige {
      background: var(--neutral-biege);
    }

    &_grey,
    &_beige {
      svg {
        fill: var(--accent-brown);

        path, rect {
          fill: var(--accent-brown);
        }

        #circle,
        #check {
          fill: var(--t);
          stroke: var(--accent-brown);
        }

        &:has(#circle):has(#check) {
          path {
            &:not(#check) {
              stroke: var(--t);
            }
          }
        }
      }
    }

    &_brown {
      background: var(--accent-brown);

      svg {
        fill: var(--neutral-biege);

        path, rect {
          fill: var(--neutral-biege);
        }

        #circle,
        #check {
          fill: var(--t);
        }

        &:has(#circle):has(#check) {
          path {
            &:not(#check) {
              stroke: var(--t);
            }
          }
        }
      }
    }

    &_black {
      background: var(--base-black-100);

      svg {
        path, rect {
          fill: var(--neutral-biege);
        }

        #circle,
        #check {
          fill: var(--t);
        }
      }
    }

    &_brown,
    &_black {
      .advantage-item {
        &__text {
          color: var(--neutral-biege);
        }
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('s-large') {
  .advantage-item {
    &__head,
    &__content {
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column6));
      grid-column-gap: var(--grid-gap);
    }

    &__content {
      padding: 20px 0;
    }

    &__text-wrap {
      position: relative;
      padding: 40px 44px 108px 40px;

      min-height: 420px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(-1 * var(--grid-gap) / 2);
        border-left: 1px solid var(--line-on-white);
      }
    }

    &__text {
      width: calc(var(--grid-column4) - 40px);
    }
  }
}

@include respond-up('medium') {
  .advantage-item {
    &__head {
      padding: 15px 0;
    }

    &__text-wrap {
      justify-content: space-between;
    }

    &__icon {
      flex: 0 0 60px;
      height: 60px;
    }
  }
}

@include respond('medium') {
  .advantage-item {
    &__head {
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column3));
      grid-column-gap: var(--grid-gap);
    }

    &__content {
      padding: 30px 0 15px;
    }

    &__text-wrap {
      margin-top: 20px;
      padding: 30px 30px 88px;
    }

    &__text {
      width: calc(var(--grid-column4) - 30px);
    }
  }
}

@include respond-down('medium') {
  .advantage-item {
    &__content {
      display: flex;
      flex-direction: column;
    }

    &__text-wrap {
      height: 100%;
    }
  }
}

@include respond-down('small') {
  .advantage-item {
    &__head {
      padding: 10px 0;
      display: flex;
      column-gap: 14px;
    }

    &__content {
      padding: 20px 0 9px;
    }

    &__text-wrap {
      flex-direction: column-reverse;
      justify-content: flex-end;
      row-gap: 20px;
      padding: 18px 18px 50px;
      margin-top: 15px;
    }

    &__icon {
      width: 50px;
      height: 50px;
    }
  }
}