.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.basic-slider {
  position: relative;

  .swiper-button {
    transition: opacity;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__item {
    position: relative;

    &.swiper-slide {
      &:after {
        position: absolute;
        content: '';
        height: 1px;
        background: var(--line-on-white);
        top: calc(-1 * var(--grid-gap) / 2);
      }

      &:not(:first-child) {
        &:before {
          position: absolute;
          content: '';
          height: 100%;
          width: 1px;
          background: var(--line-on-white);
          left: calc(-1 * var(--grid-gap) / 2);
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .basic-slider {
    &__nav {
      display: flex;

      &_left {
        bottom: calc(841 / 1010 * var(--grid-column5) + var(--grid-gap) / 2 + 35px);
      }
    }

    &__button {
      margin-left: 30px;
      width: 189px;

      &_tablet {
        display: none;
      }
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column5);
      }
    }
  }
}

@include respond-up('medium') {
  .basic-slider {
    &__item {
      &.swiper-slide {
        &:not(:last-child) {
          margin-right: var(--grid-gap) !important;

          &:after {
            width: calc(100% + var(--grid-gap));
          }
        }

        &:last-child {
          &:after {
            width: 100%;
          }
        }
      }
    }

    &__nav {
      position: absolute;
      right: 0;
      top: 0;

      &_left {
        left: 0;
        top: unset;
        right: unset;
      }
    }
  }
}

@include respond('medium') {
  .basic-slider {
    &__nav {
      &_left {
        bottom: calc(841 / 1010 * var(--grid-column4) + var(--grid-gap) / 2 + 22px);
      }
    }

    &__button {
      display: none;

      &_tablet {
        margin-top: 30px;
        width: 189px;
        padding-left: var(--grid-spacer-and-indent);
      }
    }
  }

  .images-block {
    &__item {
      &.swiper-slide {
        width: var(--grid-column4);
      }
    }
  }
}

@include respond-down('small') {
  .basic-slider {
    position: relative;

    &__nav {
      display: flex;
    }

    &__button {
      margin-left: 20px;
      flex: 1 0 auto;

      .button_lined {
        width: auto;
      }

      &_tablet {
        display: none;
      }
    }

    &__item {
      &.swiper-slide {

        &:not(:last-child) {
          padding-right: var(--grid-spacer-and-indent) !important;

          &:after {
            width: 100%;
          }
        }

        &:last-child {
          width: calc(var(--grid-column4) - var(--grid-spacer-and-indent));

          &:after {
            width: calc(100% - var(--grid-gap));
          }
        }
      }
    }

    &__nav {
      margin-top: 20px;
      padding-left: var(--grid-spacer-and-indent);
      padding-right: var(--grid-spacer-and-indent);
    }
  }
}