.picture-wrapper {
  overflow: hidden;
  background: var(--image-background);

  &_black {
    background: #40403D;
  }
}

.animate {
  .picture-wrapper {
    &__picture {
      transition: transform, opacity;
      transition-duration: var(--text-timing);
      transition-timing-function: var(--default-transition-function);
    }
  }
}

.need-animation {
  .picture-wrapper {
    &__picture {
      transform: scale(1.05);
      opacity: 0;
    }
  }
}

.animate-out {
  .picture-wrapper {
    transform: scale(1);
    opacity: 1;
  }
}

@include respond-up('s-large') {
  .picture-wrapper {
    &__image-hover {
      &:hover {
        .picture-wrapper {
          &__image {
            transform: scale(1.05);
          }
        }
      }
    }

    &__image {
      transition: transform;
      transition-duration: var(--picture-timing);
      transition-timing-function: var(--default-transition-function);
    }
  }
}