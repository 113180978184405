.slider-grid {
  display: grid;

  &__title {
    grid-area: title;
    padding-left: var(--grid-spacer-and-indent);
  }

  &__arrows {
    grid-area: arrows;
  }

  &__slider {
    grid-area: slider;
  }

  &__button {
    grid-area: button;
  }
}

@include respond-up('s-large') {
  .slider-grid {
    grid-template-areas: 'title . arrows button'
                         'slider slider slider slider';
    grid-template-columns: var(--grid-column6) 1fr auto auto;

    &__button {
      margin-left: 30px;
      padding-right: var(--grid-spacer-and-indent);
    }
  }
}

@include respond-up('medium') {
  .slider-grid {
    &__button {
      .button {
        width: calc(var(--grid-column1)*1.5);
        min-width: 189px;
      }
    }
  }
}

@include respond('medium') {
  .slider-grid {
    grid-template-areas: 'title arrows'
                         'slider slider'
                         'button .';
    grid-template-columns: calc(var(--grid-column5) - 30px) 1fr;
    column-gap: 30px;

    &__button {
      padding-left: var(--grid-spacer-and-indent);
      margin-top: 30px;
    }

    &__arrows {
      padding-right: var(--grid-spacer-and-indent);
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

@include respond-down('small') {
  .slider-grid {
    grid-template-areas: 'title title'
                         'slider slider'
                         'arrows button';
    grid-template-columns: auto 1fr;
    column-gap: 20px;

    &__title,
    &__button {
      padding-right: var(--grid-spacer-and-indent);
    }

    &__arrows {
      padding-left: var(--grid-spacer-and-indent);
    }

    &__button {
      .button {
        width: 100%;
      }
    }

    &__arrows,
    &__button {
      margin-top: 20px;
    }
  }
}