:root {
  --default-input-height: 56px;
  --default-textarea-height: 200px;
  --errors-color: #C6896F;
}

$inputs: 'input[type="text"], input[type="email"], input[type="search"], input[type="password"]';

form {
  --white-field: #E7E4E0;

  .field {
    width: 100%;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
    }

    &::before {
      bottom: 0;
      top: 15px;
      width: 15px;
      height: calc(var(--default-input-height) - 15px);
      right: 0;
      background-color: var(--neutral-grey);
      transition: background .3s var(--default-transition-function);
    }

    &::after {
      transform: rotate(-90deg);
      top: 0;
      right: 0;
      border-color: #fff #fff transparent;
      border-style: solid;
      border-width: 0 0 15px 15px;
      border-bottom-color: transparent;
      border-left-color: var(--neutral-grey);
      border-right-color: var(--neutral-grey);
      border-top-color: var(--neutral-grey);
      transition: border-color .3s var(--default-transition-function);
    }

    &._filled,
    &._focused {
      &::before {
        background-color: #D6D2CE;
      }

      &::after {
        border-left-color: #D6D2CE;
        border-right-color: #D6D2CE;
        border-top-color: #D6D2CE;
      }
    }

    &._filled {
      #{$inputs}, textarea, select {
        background: #D6D2CE;
      }
    }

    &:has(textarea) {
      &::before {
        height: calc(var(--default-textarea-height) - 15px);
      }
    }

    &_white {
      &::before {
        background-color: var(--white-field);
      }

      &::after {
        border-left-color: var(--white-field);
        border-right-color: var(--white-field);
        border-top-color: var(--white-field);
      }

      &._filled,
      &._focused {
        &::before {
          background-color: var(--white-field);
        }

        &::after {
          border-left-color: var(--white-field);
          border-right-color: var(--white-field);
          border-top-color: var(--white-field);
        }
      }

      &._filled {
        #{$inputs}, textarea, select {
          background: var(--white-field);
        }
      }

      #{$inputs}, textarea, select {
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px var(--white-field) inset;
        }
      }

      #{$inputs}, textarea {
        background: var(--white-field);

        &:focus {
          background: var(--white-field);
        }
      }
    }
  }

  #{$inputs}, textarea, select {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #D6D2CE inset;
      -webkit-text-fill-color: black;
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
  }

  textarea {
    resize: none;
    height: var(--default-textarea-height);
  }

  label {
    color: var(--base-black-100);
    display: block;
  }

  ul.errors {
    list-style: none;
    color: var(--errors-color);
    font-size: 13px;

    li {
      margin-bottom: 2px;
      font-family: var(--font);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  #{$inputs}, textarea {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding-right: 15px;
    display: block;
    appearance: none;
    background: var(--neutral-grey);
    width: calc(100% - 15px);
    transition: background .3s var(--default-transition-function);

    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    &:focus {
      background: #D6D2CE;
    }

    &:required {
      + label {
        &::after {
          content: " *";
        }
      }
    }
  }

  #{$inputs} {
    height: var(--default-input-height);
  }

  &.price {
    .price__select-wrapper {
      --underline-link-color: var(--accent-blue-light);
      --underline-link-offset: 0px;
      --base-color: var(--accent-blue);
      --hover-color: var(--accent-blue-light);
      position: relative;

      //&:after {
      //  content: '';
      //  width: 100%;
      //  height: 1px;
      //  display: block;
      //  position: absolute;
      //  background: var(--underline-link-color);
      //  bottom: var(--underline-link-offset);
      //  transform: scaleX(0);
      //  transform-origin: right;
      //  transition: transform var(--default-timing) var(--teaser-transition-func);
      //}

      &:before {
        z-index: 50;
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: var(--base-color) transparent transparent transparent;
      }
    }

    select {
      appearance: none;
      position: relative;
      z-index: 40;
      padding: 0 0 0 14px;
      background: var(--base-white-100);
      border: none;
      font-family: var(--font);
      font-weight: 600;
      line-height: 140%;
      text-align: left;
      transition: color;
      color: var(--base-color);
    }
  }

  textarea {
    padding: 5px 10px;
  }
}

@include respond-up('s-large') {
  form {
    #{$inputs}, textarea, select {
      &:hover {
        background: #D6D2CE;
      }
    }

    .field {
      &:not(.field_white) {
        &:hover {
          &::before {
            background-color: #D6D2CE;
          }

          &::after {
            border-left-color: #D6D2CE;
            border-right-color: #D6D2CE;
            border-top-color: #D6D2CE;
          }
        }
      }

      &_white {
        #{$inputs}, textarea, select {
          &:hover {
            background: var(--white-field);
          }
        }
      }
    }

    &.price {
      .price__select-wrapper {
        &:hover {
          &::after {
            transform-origin: left;
            transition: transform var(--default-timing) var(--teaser-transition-func);
            transform: scaleX(1);
          }
        }
      }

      select {
        transition: color var(--default-timing) var(--default-transition-function);

        &:hover {
          background: var(--base-white-100);
          color: var(--hover-color);
        }
      }
    }
  }
}

@include respond-up('medium') {
  form {
    #{$inputs}, textarea, select {
      padding-top: 25px;
      padding-left: 20px;
      padding-bottom: 9px;
    }

    ul.errors {
      li {
        &:first-child {
          margin-top: 6px;
        }
      }
    }

    &.price {
      select {
        font-size: 16px;
      }
    }
  }
}

@include respond-down('small') {
  :root {
    --default-input-height: 50px;
    --default-textarea-height: 130px;
  }

  form {
    #{$inputs}, textarea, select, label {
      font-size: 15px;
    }

    #{$inputs}, textarea, select {
      padding-top: 22px;
      padding-left: 14px;
      padding-bottom: 7px;
    }

    &.price {
      select {
        font-size: 15px;
      }
    }

    ul.errors {
      li {
        &:first-child {
          margin-top: 4px;
        }
      }
    }
  }
}