.label-button{
  display: flex;

  &._blue{
    --text-color: var(--base-white-100);
    --background-color: var(--accent-blue);
  }

  &._brown{
    --text-color: var(--base-white-100);
    --background-color: var(--accent-brown);
  }

  &._silver{
    --text-color: var(--base-black-100);
    --background-color: var(--accent-silver);
  }

  &__text{
    background: var(--background-color);
    color: var(--text-color);
  }

  &__corner {
    display: block;
    position: relative;
    width: 8px;

    &:before {
      width: 8px;
      content: "";
      position: absolute;
      bottom: 0;
      background: var(--background-color);
    }

    &:after {
      border-width: 8px 0 0 8px;
      content: "";
      position: absolute;
      top: 0;
      border-style: solid;

      width: 0px;
      height: 0px;
      border-style: solid;
      border-color: transparent transparent transparent var(--background-color);
      transform: rotate(0deg);
    }
  }
}

@include respond-up('medium') {
  .label-button{
    height: 32px;

    &__text{
      padding: 6px 4px  6px 12px;
    }

    &__corner {
      &:before {
        height: 24px;
      }
    }
  }
}

@include respond('small') {
  .label-button{
    height: 26px;

    &__text{
      padding: 4px 0 4px 8px;
    }

    &__corner {
      &:before {
        height: 18px;
      }
    }
  }
}