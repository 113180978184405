.completed-projects {
  &__other-list-item {
    &:not(:last-child) {
      &:before {
        width: calc(100% + var(--grid-gap));
      }
    }

    &:last-child {
      &:before {
        width: 100%;
      }
    }

    &:before {
      position: absolute;
      content: '';
      top: calc(-1 * var(--grid-gap) / 2);
      height: 1px;
      background: var(--line-on-white);
    }

    &:not(:first-child) {
      position: relative;
      margin-left: var(--grid-gap);

      &:after {
        position: absolute;
        content: '';
        top: 0;
        height: 100%;
        width: 1px;
        background: var(--line-on-white);
        left: calc(-1 * var(--grid-gap) / 2);
      }
    }
  }
}

@include respond-up('s-large') {
  .completed-projects {
    &__other-list-item {
      &.swiper-slide {
        width: var(--grid-column5);
      }
    }

    &__other {
      margin-top: 150px;
    }

    &__other-list {
      margin-top: 30px;
      padding-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .completed-projects {

  }
}

@include respond('medium') {
  .completed-projects {
    &__other-list-item {
      &.swiper-slide {
        width: var(--grid-column4);
      }
    }

    &__other {
      margin-top: 100px;
    }

    &__other-list {
      margin-top: 20px;
      padding-top: 15px;
    }
  }
}

@include respond-down('medium') {
  .completed-projects {

  }
}

@include respond-down('small') {
  .completed-projects {
    &__other-list-item {
      &.swiper-slide {
        width: calc(var(--grid-column4) - var(--grid-gap));
      }
    }

    &__other {
      margin-top: 50px;
    }

    &__other-list {
      padding-top: 9px;
      margin-top: 12px;
    }
  }
}