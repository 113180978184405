.header {
  width: 100%;
  z-index: 4700;
  height: var(--header-height);

  &._sticky {
    .header {
      &__content {
        transform: translate3d(0, -100%, 0);
        transition: transform .3s var(--default-transition-function);
      }
    }
  }

  &._up {
    .header {
      &__content {
        transform: translate3d(0, 0, 0) !important;
        transition-duration: .3s !important;
      }
    }
  }

  &__content {
    background: var(--base-white-100);
    border-bottom: 1px solid var(--line-on-white);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  &__wrap {
    width: 100%;
    display: flex;
    z-index: 4670;
    position: relative;
  }

  &__wrap-mob {
    display: none;
  }

  &__left-menu {
    display: flex;
    flex-direction: row;
  }

  &__logo {
    background: var(--accent-brown);
    display: flex;
    margin-bottom: -1px;
  }

  &__burger {
    padding: 31px 25px 30px 25px;
    border-right: 1px solid var(--line-on-white);

    &._opened {
      .header {
        &__burger-string {
          &:first-child {
            transform: translateY(5px) rotate(45deg);
          }

          &:last-child {
            transform: translateY(-5px) rotate(-45deg);
          }
        }
      }
    }
  }

  &__burger-rows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 12px;
  }

  &__burger-string {
    height: 2px;
    width: 34px;
    content: "";
    background: var(--base-black-100);
    transition: all var(--default-timing);
  }

  &__navigation {
    display: flex;
    margin: 0 -15px;
  }

  &__navigation-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
  }

  &__connect {
    display: flex;
    align-items: center;
  }

  &__connect-phone {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 40px;
  }

  &__navigation-item-wrap {
    &:has(.header__nav-children-list) {
      position: relative;

      &:hover {
        .header {
          &__nav-children-list-wrap {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }

  &__nav-children-list-wrap {
    position: absolute;
    top: 100%;
    background: var(--base-white-100);
    transition: opacity .3s;
    border: 1px solid var(--line-on-white);
    opacity: 0;
    pointer-events: none;
  }

  &__nav-children-list {
    display: flex;
    flex-direction: column;
  }

  &__nav-child {
    text-wrap: nowrap;
    display: flex;

    &.underline {
      .link-button__text {
        color: var(--accent-brown);
      }
    }
  }
}

@media (min-width: 1330px) {
  .header {
    &__nav-child-item {
      &_contacts {
        display: none;
      }
    }
  }
}

@media (max-width: 1330px) {
  .header {
    &__navigation-item-wrap {
      &_contacts {
        display: none;
      }
    }
  }
}

@include respond-up('s-large') {
  .header {
    &__wrap {
      justify-content: space-between;
    }

    &__logo {
      padding: 23px 40px 26px 40px;
    }

    &__connect-modal {
      transition: background-color;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);

      &.button {
        width: 167px;
      }

      &:hover {
        background-color: var(--image-background);
      }
    }
  }
}

@include respond-up('medium') {
  .header {
    &__phone-mob {
      display: none;
    }

    &__nav-children-list-wrap {
      padding: 30px 40px;
      left: -30px;
    }

    &__nav-children-list {
      margin: -7px 0;
    }

    &__nav-child {
      padding: 7px 0;
    }
  }
}

@include respond('medium') {
  .header {
    &__logo {
      padding: 23px 30px 26px 30px;
    }

    &__navigation {
      position: relative;
    }

    &__navigation-item-wrap {
      &_completed-projects,
      &_contacts {
        display: none;
      }
    }

    &__connect-phone {
      display: none;
    }

    &__connect-modal {
      &.button {
        width: 147px;
      }
    }

    &__left-menu {
      margin-right: 40px;
    }

    &__connect {
      margin-left: auto;
    }
  }
}

@include respond-down('medium') {
  .header {
    &__city-wrap {
      &.city-opener {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .header {
    &__left-menu-wrap {
      width: 100%;
    }

    &__left-menu {
      flex-direction: row-reverse;
    }

    &__logo {
      flex: auto;
      background: unset;
      align-items: center;
      justify-content: center;

      svg {
        width: 146px;
        height: 19px;

        g {
          path {
            fill: var(--accent-brown);
          }
        }
      }
    }

    &__navigation,
    &__connect-phone {
      display: none;
    }

    &__connect-modal {
      &.button {
        display: none;
      }
    }

    &__wrap-mob {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__burger {
      background: var(--accent-brown);
      padding: 20px 18px;

      &._open {
        .header {
          &__burger-string {
            &:first-child {

              transform: translateY(4px) rotate(45deg);
            }

            &:last-child {
              transform: translateY(-4px) rotate(-45deg);
            }
          }
        }
      }
    }

    &__burger-rows {
      height: 10px;
    }

    &__burger-string {
      width: 22px;
      background: var(--neutral-biege);
    }

    &__logo-mob {
      display: flex;
      padding: 15px 0 16px;
    }

    &__phone-mob {
      display: flex;
      padding: 17px 18px;
      border-left: 1px solid var(--line-on-white);
    }

    &__nav-children-list-wrap {
      padding: 18px;
      left: -18px;
    }

    &__nav-children-list {
      margin: -6px 0;
    }

    &__nav-child {
      padding: 6px 0;
    }
  }
}