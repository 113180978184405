.article-more {
  &__item-title-wrap {
    width: 100%;
    overflow: hidden;
  }

  &__wrap {
    display: grid;
    column-gap: var(--grid-gap);
    position: relative;
  }

  &__wrap-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__title {
    width: 100%;
  }
}

@include respond-up('s-large') {
  .article-more {
    margin-top: 150px;
    margin-bottom: 150px;

    &__wrap {
      grid-template-columns: repeat(4, var(--grid-column3));
      margin-top: 50px;

      & > * {
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          left: -20px;
          top: 0;
          background: var(--line-on-white);
        }
      }

      & > :first-child {
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column12);
          height: 1px;
          left: 0;
          top: -20px;
          background: var(--line-on-white);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .article-more {
    &__link {
      width: 189px;
    }
  }
}

@include respond('medium') {
  .article-more {
    margin-top: 100px;
    margin-bottom: 100px;

    &__wrap {
      grid-template-columns: 1fr 1fr;
      margin-top: 35px;

      & > * {
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          left: -15px;
          top: 0;
          background: var(--line-on-white);
        }
      }

      & > :first-child {
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column6);
          height: 1px;
          left: 0;
          top: -15px;
          background: var(--line-on-white);
        }
      }

      & > :nth-child(1n + 3) {
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .article-more {
    &__wrap {
      & > :nth-child(1n + 3) {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .article-more {
    margin-top: 50px;
    margin-bottom: 104px;
    position: relative;

    &__wrap {
      grid-template-columns: 1fr;
      gap: 30px;
      margin-top: 12px;

      & > * {
        padding-top: 9px;

        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column4);
          height: 1px;
          left: 0;
          top: 0;
          background: var(--line-on-white);
        }
      }
    }

    &__link {
      width: 100%;
      position: absolute;
      bottom: -54px;

      .button_lined {
        width: inherit;
      }
    }
  }
}