.info-number {

  &__text-label {
    color: var(--base-black-50);
  }

  &__table {
    display: grid;
    border-bottom: 1px solid var(--base-black-50);

    &.index-page {
      .info-number {
        &__table-main-info {
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }

  &__table-main-info {
    display: flex;
    position: relative;
    margin-top: 0.12em;
  }

  &__table-main-number {
    display: flex;
    align-items: baseline;
    font-size: 0;

    span {
      color: var(--accent-brown);
    }
  }

  &__table-main-text {
    width: var(--grid-column2);
  }

  &__wrap-dop-text {
    display: flex;
  }
}

@include respond-up('s-large') {
  .info-number {
    margin-top: 30px;

    &__text {
      margin-top: 70px;
      width: var(--grid-column8);
    }

    &__table {
      margin-top: 70px;
      padding-bottom: 20px;
      gap: var(--grid-gap);
      grid-template-columns: repeat(2, 1fr);
      padding-top: 20px;

      &.index-page {
        margin-top: 80px;
        grid-template-columns: repeat(4, 1fr);

        .info-number {
          &__table-main-info {
            width: 236px;
          }

          &__table-main-info:nth-child(1) {
            &:after {
              content: "";
              position: absolute;
              top: -20px;
              left: 0;
              width: var(--grid-column12);
              height: 1px;
              background: var(--base-black-50);
            }
          }

          &__table-main-info:nth-child(1n + 2) {
            &:after {
              content: "";
              position: absolute;
              left: -20px;
              top: 0;
              width: 1px;
              height: 100%;
              background: var(--base-black-50);
            }
          }

          &__table-main-info {
            height: 230px;
          }
        }
      }

      .info-number__table-main-info:nth-child(2n + 1) {
        &:after {
          content: "";
          position: absolute;
          top: -20px;
          left: 0;
          width: var(--grid-column12);
          height: 1px;
          background: var(--base-black-50);
        }
      }

      .info-number__table-main-info:nth-child(2n + 2) {
        &:after {
          content: "";
          position: absolute;
          left: -20px;
          top: 0;
          width: 1px;
          height: 100%;
          background: var(--base-black-50);
        }
      }
    }

    &__table-main-info {
      height: 175px;
    }

    &__wrap-dop-text {
      margin-top: 100px;
      justify-content: flex-end;
    }
  }
}

@include respond-up('medium') {
  .info-number {

    &__table-main-info {
      gap: var(--grid-gap);
    }

    &__table-main-text {
      width: calc(var(--grid-column2));
    }

    &__table-main-number {
      width: var(--grid-column3);
    }

    &__wrap-dop-text {
      gap: var(--grid-gap);
    }

    &__dop-text {
      width: var(--grid-column3);
    }
  }
}

@include respond('medium') {
  .info-number {
    margin-top: 20px;

    &__text {
      margin-top: 40px;
    }

    &__table {
      margin-top: 60px;

      &.index-page {
        .info-number {
          padding: 15px 0;

          &__table-main-info {
            height: 186px;
          }

          &__table-main-info:nth-child(2n + 1) {
            &:after {
              top: -15px;
              width: var(--grid-column6);
            }
          }

          &__table-main-info:nth-child(2n + 2) {
            &:after {
              left: -15px;
            }
          }
        }
      }
    }

    &__table-main-info {
      height: 111px;
      padding: 15px 0;
    }

    &__wrap-dop-text {
      margin-top: 80px;
    }
  }
}

@include respond-down('medium') {
  .info-number {
    &__table {
      display: flex;
      flex-direction: column;

      &.index-page {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--grid-gap);

        .info-number {
          &__table-main-info {
            padding: 0;
          }

          &__table-main-info:nth-child(2n + 1) {
            &:after {
              content: "";
              position: absolute;
              left: 0;
              height: 1px;
              background: var(--base-black-50);
            }
          }

          &__table-main-info:nth-child(2n + 2) {
            &:after {
              content: "";
              position: absolute;
              top: 0;
              width: 1px;
              height: 100%;
              background: var(--base-black-50);
            }
          }
        }
      }
    }

    &__table-main-info {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        background: var(--base-black-50);
        width: 100%;
      }
    }
  }
}

@include respond-down('small') {
  .info-number {
    margin-top: 18px;

    &__text {
      margin-top: 20px;
    }

    &__table {
      margin-top: 40px;

      &.index-page {
        padding: 10px 0 9px 0;

        .info-number {
          &__table-main-info {
            height: 163px;
          }

          &__table-main-info:nth-child(2n + 1) {
            &:after {
              top: -10px;
              width: var(--grid-column4);
            }
          }

          &__table-main-info:nth-child(2n + 2) {
            &:after {
              left: -9px;
            }
          }

          &__table-main-text {
            width: var(--grid-column2);
          }
        }
      }
    }

    &__table-main-info {
      flex-direction: column;
      justify-content: space-between;
      height: 110px;
      padding: 10px 0;
    }

    &__table-main-text {
      width: var(--grid-column3);
    }

    &__wrap-dop-text {
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;
    }
  }
}