.about-awards {

  &__wrap-content {
    display: flex;
  }

  &__wrap-year {
    display: flex;
  }

  &__year {
    width: var(--grid-column1);
    display: inline-block;
  }

  &__wrap-content {
    display: flex;
    flex-direction: column;
  }

  &__certificate {
    cursor: pointer;
    display: flex;
    gap: var(--grid-gap);
  }

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__wrap-name {
    display: flex;
    gap: 2px;
    flex-direction: column;
  }

  &__download {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

@include respond-up('s-large') {
  .about-awards {
    margin-top: 150px;

    &__wrap-content {
      flex-direction: row;
      margin-top: 30px;
      gap: 40px;
      position: relative;
    }

    &__image-frame {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 438 / 375);
      background: var(--neutral-grey);
      position: sticky;
      top: calc(var(--header-height) + 20px);
    }

    &__wrap-certificate {
      width: var(--grid-column9);

      position: relative;

      &:after {
        content: " ";
        background: var(--line-on-white);
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -20px;
      }
    }

    &__certificate {
      width: var(--grid-column8);

      &:hover {
        .about-awards {
          &__download {
            opacity: 1;
          }

          &__wrap-name {
            > :first-child {
              color: var(--base-black-50);
            }
          }
        }
      }
    }

    &__wrap-name {
      width: var(--grid-column7);

      > :first-child {
        transition: color .6s var(--default-transition-function), transform .6s var(--default-transition-function) .6s;
      }
    }

    &__download {
      opacity: 0;
      justify-content: space-between;
      transition: opacity .6s var(--default-transition-function), transform .6s var(--default-transition-function) .6s;
    }

    &__wrap-picture {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity .6s var(--default-transition-function), transform .6s var(--default-transition-function) .6s;

      &._active {
        opacity: 1;
        transition-delay: 0s;
        transition: opacity .6s var(--default-transition-function), transform .6s var(--default-transition-function) 0s;

        .about-awards__image {
          transform: scale(1);
        }
      }
    }

    &__image {
      transform: scale(1.1);
      transition: scale .6s var(--default-transition-function), transform .6s var(--default-transition-function) 0s;
    }

    &__picture {
      width: calc(var(--grid-column2) - 6px);
      height: calc((var(--grid-column2) - 6px) * 327 / 231);
    }
  }
}

@include respond-up('medium') {
  .about-awards {
    &__wrap-content {
      border-top: 1px solid var(--line-on-white);
      padding-top: 20px;
    }

    &__wrap-year {
      flex-direction: row;
      gap: var(--grid-gap);
    }

    &__wrap-year {
      border-bottom: 1px solid var(--line-on-white);

      &:first-child {
        .about-awards {
          &__certificate {
            padding: 0 0 22px;
          }

          &__year {
            padding-top: 0;
          }
        }
      }

      &:last-of-type {
        border-bottom: unset;
      }
    }

    &__certificate {
      flex-direction: row;
      padding: 20px 0 22px;
      border-top: 1px solid var(--line-on-white);

      &:first-child {
        border-top: unset;
      }
    }

    &__year {
      padding-top: 20px;
    }

    &__download {
      width: var(--grid-column1);
    }
  }
}

@include respond('medium') {
  .about-awards {
    margin-top: 100px;

    &__wrap-content {
      margin-top: 20px;
    }


    &__certificate {
      width: var(--grid-column5);
    }

    &__wrap-name {
      width: var(--grid-column4);
    }

    &__download {
      justify-content: flex-end;
      gap: 20px;
    }

    &__wrap-button {
      padding-left: calc(var(--grid-column1) + var(--grid-gap));
    }
  }
}

@include respond-down('medium') {
  .about-awards {
    &__image-frame {
      display: none;
    }

    &__wrap-picture {
      display: none;
    }
  }
}

@include respond-down('small') {
  .about-awards {
    margin-top: 50px;

    &__wrap-content {
      margin-top: 12px;
    }

    &__wrap-year {
      flex-direction: column;
    }

    &__block {
      margin-top: 12px;
    }

    &__certificate {
      border-top: 1px solid var(--line-on-white);
      flex-direction: column;
      width: 100%;
      padding: 12px 0;
    }

    &__wrap-name {
      width: 100%;
    }


    &__download {
      width: 100%;
      justify-content: space-between;
    }

  }
}