:not(.skip-anim) {
  &.text {
    &__subheader-middle,
    &__subheader-big {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      width: fit-content;
      margin-top: -0.12em;

      span {
        display: block;
        padding-top: 0.12em;
        margin-bottom: -0.12em;
        transition: transform;
        transition-duration: var(--text-timing);
        transition-timing-function: var(--default-transition-function);
      }

      @for $i from 2 through 50 {
        &:nth-child(#{$i}) {
          span {
            transition-delay: calc(#{$i} * 0.1s);
          }
        }
      }
    }

    &__body-little {
      transition: opacity var(--text-timing) var(--default-transition-function);

      &_delay {
        transition-delay: .3s;
      }
    }
  }
}

.animate {
  :not(.skip-anim) {
    &.text {
      &__subheader-middle,
      &__subheader-big {
        span {
          transform: translateY(0);
        }
      }

      &__body-little {
        opacity: 1;
      }
    }
  }
}

.need-animation {
  :not(.skip-anim) {
    &.text {
      &__subheader-middle,
      &__subheader-big {
        span {
          transform: translateY(100%);
        }
      }

      &__body-little {
        opacity: 0;
      }
    }
  }
}