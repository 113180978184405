.project-page {
  &__built{
    display: flex;
  }
}

@include respond-up('s-large') {
  .project-page {
    &__built {
      margin-top: 70px;
      margin-left: calc(var(--grid-column6) + var(--grid-gap));
      width: var(--grid-column6);
    }
  }
}

@include respond-up('medium') {
}

@include respond('medium') {
  .project-page {
    &__built {
      margin-top: 60px;
    }
  }
}

@include respond-down('medium') {
  .project-page {
    &__built {
      width: 100%;
    }
  }
}

@include respond('small') {
  .project-page {
    &__built {
      margin-top: 30px;
    }
  }
}