.history-slide {
  height: 100%;

  &__title-wrap {
    display: flex;
    flex-direction: column;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .history-slide {
    padding: 30px;
    display: grid;
    grid-template-areas: "title image"
                         "text text";
    grid-template-columns: calc(var(--grid-column3) - 30px) calc(var(--grid-column2) - 30px);
    grid-column-gap: var(--grid-gap);
    grid-row-gap: 150px;

    &__title-wrap {
      grid-area: title;
    }

    &__image-wrap {
      grid-area: image;
    }

    &__description {
      grid-area: text;
      display: flex;
      align-items: flex-end;
    }
  }
}

@include respond-up('medium') {
  .history-slide {
    &__title-wrap {
      row-gap: 10px;
    }

    &__image-wrap {
      width: calc(var(--grid-column2) - 30px);
      height: calc((var(--grid-column2) - 30px) * 177/207);
    }
  }
}

@include respond('medium') {
  .history-slide {
    padding: 30px 20px 30px 30px;

    &__description {
      margin-top: 30px;
    }

    &__image-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .history-slide {
    display: flex;
    flex-direction: column;

    &__description {
      margin-bottom: auto;
    }
  }
}

@include respond-down('small') {
  .history-slide {
    padding: 18px;

    &__title-wrap {
      row-gap: 5px;
    }

    &__description {
      margin-top: 20px;
    }

    &__image-wrap {
      width: calc(var(--grid-column2) - 18px);
      height: calc((var(--grid-column2) - 18px) * 121/142);
      margin-top: 30px;
    }
  }
}