.partners-products-slider {
  display: flex;

  &__image-wrap {
    width: 100%;
    height: 100%;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    position: relative;
    display: flex;

    &::before {
      content: '';
      position: absolute;
    }
  }

  &__text-slider-list {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
  }

  &__text-slider {
    flex: auto;
  }

  &__text-slide {
    grid-column: 1;
    grid-row: 1;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s var(--default-transition-function);

    &._active {
      opacity: 1;
      z-index: 15;
      pointer-events: auto;
    }
  }

  &__nav-wrap {
    display: flex;
  }

  &_grey {
    .partners-products-slider {
      &__text-wrap {
        background: var(--neutral-grey);
      }
    }
  }

  &_biege {
    .partners-products-slider {
      &__text-wrap {
        background: var(--neutral-biege);
      }
    }
  }

  &_black {
    .partners-products-slider {
      &__text-wrap {
        background: var(--base-black-100);
      }

      &__text-slide-title,
      &__text-slide-description {
        color: var(--neutral-biege);
      }

      &__pagination {
        &.swiper-pagination-fraction {
          color: var(--neutral-biege);
        }
      }
    }
  }

  &__pagination {
    &.swiper-pagination-fraction {
      width: fit-content;
    }
  }

  &__item {
    &.swiper-slide {
      overflow: hidden;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: 20;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--t);
        transition: background 1s var(--default-transition-function);
      }
    }
  }

  &__item {
    &.swiper-slide-prev {
      &::before {
        background: var(--base-black-100);
      }
    }
  }
}

@include respond-up('s-large') {
  .partners-products-slider {
    column-gap: var(--grid-gap);

    &__slider {
      &.swiper {
        width: var(--grid-column6);
      }
    }

    &__slider-wrap,
    &__text-wrap {
      flex: 0 0 var(--grid-column6);
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column6);
        height: calc(var(--grid-column6) * 502/790);
      }
    }

    &__text-wrap {
      flex-direction: column;
      padding: 40px;
      row-gap: 50px;

      &::before {
        top: 0;
        bottom: 0;
        left: calc(-1 * (var(--grid-gap) / 2));
        border-left: 1px solid var(--line-on-white);
      }
    }

    &__text-slide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(var(--grid-column4) - 40px);
    }

    &__nav-wrap {
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}

@include respond-up('medium') {
  .partners-products-slider {

  }
}

@include respond('medium') {
  .partners-products-slider {
    &__item {
      &.swiper-slide {
        width: var(--grid-column6);
        height: calc(var(--grid-column6) * 492/774);
      }
    }

    &__text-wrap {
      min-height: 245px;
      padding: 30px;
      margin-top: 30px;

      &::before {
        top: -15px;
      }
    }

    &__text-slide {
      width: calc(var(--grid-column4) - 30px);
    }

    &__text-slide-description {
      margin-top: 30px;
    }

    &__nav-wrap {
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      height: auto;
    }
  }
}

@include respond-down('medium') {
  .partners-products-slider {
    flex-direction: column;

    &__text-wrap {
      &::before {
        left: 0;
        right: 0;
        border-bottom: 1px solid var(--line-on-white);
      }
    }
  }
}

@include respond-down('small') {
  .partners-products-slider {
    &__item {
      &.swiper-slide {
        width: var(--grid-column4);
        height: calc(var(--grid-column4) * 215/338);
      }
    }

    &__text-wrap {
      min-height: 293px;
      padding: 18px 18px 30px;
      margin-top: 18px;
      flex-direction: column-reverse;
      row-gap: 30px;

      &::before {
        top: -9px;
      }
    }

    &__text-slide-description {
      margin-top: 10px;
    }

    &__nav-wrap {
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}