.promo {
  margin-top: var(--grid-gap);
  background-color: var(--accent-brown);
  display: flex;
  position: relative;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    position: absolute;
    bottom: 0;
  }
}

@include respond-up('x-large') {
  .promo {
    padding: 65px calc(var(--grid-column4) + var(--grid-gap)) 65px 60px;
    &__picture {
      width: 449px;
      height: 251px;
      right: 60px;
    }
  }
}

@include respond('large') {
  .promo {
    padding: 65px calc(var(--grid-column4) + var(--grid-gap)) 65px 60px;

    &__title {

    }

    &__picture {
      max-height: 251px;
      width: calc(var(--grid-column3) + 2 * var(--grid-gap));
      height: calc(223 / 449 * (var(--grid-column3) + 2 * var(--grid-gap)));
      right: 60px;
    }
  }
}

@include respond('s-large') {
  .promo {
    padding: 65px calc(var(--grid-column4) + var(--grid-gap)) 65px 60px;

    &__title {

    }

    &__picture {
      max-height: 251px;
      width: calc(var(--grid-column3) + 2 * var(--grid-gap));
      height: calc(223 / 449 * (var(--grid-column3) + 2 * var(--grid-gap)));
      right: 60px;
    }
  }
}

@include respond-up('medium') {
  .promo {
  }
}

@include respond('medium') {
  .promo {
    padding: 40px 40px calc(30px + 223 / 449 * (var(--grid-column4) + 1 / 2 * var(--grid-gap)));

    &__picture {
      width: calc(var(--grid-column4) + 1/2 * var(--grid-gap));
      height: calc(223 / 449 * (var(--grid-column4) + 1/2 * var(--grid-gap)));
      right: 50px;
    }
  }
}

@include respond-down('medium') {
  .promo {
  }
}

@include respond-down('small') {
  .promo {
    padding: 18px 18px calc(30px + 223 / 449 * (var(--grid-column3) + 2 * var(--grid-gap)));

    &__picture {
      width: calc(var(--grid-column3) + 2 * var(--grid-gap));
      height: calc(223 / 449 * (var(--grid-column3) + 2 * var(--grid-gap)));
      right: 50%;
      transform: translateX(50%);
    }
  }
}