.share {
  &__social-title {
    color: var(--base-black-50);
  }
}

@include respond-up('s-large') {
  .share {
    &__social-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--grid-column2);
    }
  }
}

@include respond('medium') {
  .share {
    &__social-wrap {
      margin-top: 40px;
    }

    &__social {
      display: flex;
      justify-content: space-between;
    }
  }
}

@include respond-down('medium') {
  .share {
    &__social-wrap {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .share {
    &__social-wrap {
      margin-top: 30px;
    }
  }
}