.city-opener {
  display: flex;
  position: relative;

  &__pin {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }

  &__cities-wrap {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: 100%;
    left: -1px;
    width: 100%;
    background: var(--base-white-100);
    transition: opacity .3s var(--default-transition-function);
    border: 1px solid var(--line-on-white);
  }
}

@include respond-up('s-large') {
  .city-opener {
    padding-left: 40px;

    &__pin {
      // чтобы не конфликтовать с дроп меню
      padding-right: 40px;
    }

    &__cities-wrap {
      min-width: calc(284 / 1700 * 100vw);
    }

    &:hover {
      .city-opener__cities-wrap {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

@include respond-up('medium') {
  .city-opener {
    &__cities-wrap {
      padding: 30px 40px;
    }
  }
}

@include respond('medium') {
  .city-opener {
    &__cities-wrap {
      min-width: var(--grid-column3);
    }
  }
}

@include respond-down('medium') {
  .city-opener {
    &__pin {
      svg path {
        stroke: var(--accent-brown);
      }
    }

    &__pin-text {
      color: var(--accent-brown);
    }

    &._opened {
      .city-opener {
        &__cities-wrap {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}

@include respond-down('small') {
  .city-opener {
    &__pin {
      width: 100%;
    }

    &__cities-wrap {
      width: var(--grid-column4);
      padding: 18px;
    }
  }
}