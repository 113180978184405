.equipment-block {
  &__label,
  &__description-wrap {
    padding: 0 var(--grid-spacer-and-indent);
  }
}

@include respond-up('s-large') {
  .equipment-block {
    padding: 30px 0 170px;

    &__content {
      margin-top: 70px;
    }

    &__description-wrap {
      margin-top: 100px;
      display: grid;
      grid-template-columns: var(--grid-column6) var(--grid-column5) var(--grid-column);
      grid-gap: var(--grid-gap);
    }

    &__text {
      grid-column: 2/3;
    }

    &__slider-wrap {
      grid-template-columns: var(--grid-column7) 1fr;
      grid-column-gap: var(--grid-gap);
    }

    &__slider {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .equipment-block {
    &__slider-wrap {
      display: grid;
      grid-template-areas: 'title arrows'
                           'slider slider';
    }

    &__title-wrap {
      grid-area: title;
      padding-left: var(--grid-spacer-and-indent);
    }

    &__arrows-wrap {
      grid-area: arrows;
      padding-right: var(--grid-spacer-and-indent);
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    &__slider {
      grid-area: slider;
    }
  }
}

@include respond('medium') {
  .equipment-block {
    padding: 30px 0 120px;

    &__content {
      margin-top: 40px;
    }

    &__description-wrap {
      margin-top: 60px;
      width: var(--grid-column5);
    }

    &__slider-wrap {
      grid-template-columns: 1fr auto;
      grid-column-gap: 40px;
    }

    &__slider {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .equipment-block {
    padding: 18px 0 60px;

    &__content {
      margin-top: 30px;
    }

    &__title-wrap {
      padding: 0 var(--grid-spacer-and-indent);
    }

    &__description-wrap {
      margin-top: 50px;
    }

    &__slider {
      margin-top: 12px;
    }

    &__arrows-wrap {
      margin-top: 30px;
      padding-left: var(--grid-spacer-and-indent);
    }
  }
}