.changeable-content {
  &.smart-content {
    display: block;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    height: 0;
    width: 0;
    overflow: hidden;

    &._active {
      opacity: 1;
      visibility: visible;
      position: relative;
      pointer-events: all;
      height: auto;
      width: auto;
    }
  }
}