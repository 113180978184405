.contacts-cities {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    &:not(:has(.contacts-city-card__contacts-list-item._active)) {
      display: none;
    }
  }
}

@include respond-up('s-large') {
  .contacts-cities {
    margin-top: 70px;
    position: relative;

    &__tabs {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }
  }
}

@include respond-up('medium') {
  .contacts-cities {
    &__list {
      row-gap: 80px;
    }
  }
}

@include respond('medium') {
  .contacts-cities {
    margin-top: 40px;

    &__list {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .contacts-cities {

  }
}

@include respond-down('small') {
  .contacts-cities {
    margin-top: 20px;

    &__list {
      row-gap: 40px;
      margin-top: 30px;
    }
  }
}