.history-slider {
  &__pagination {
    display: flex;
    position: relative;
    left: 0;
    transition: transform var(--transition);
    overflow-x: scroll;
    scrollbar-width: none;
    bottom: 0;
    width: unset;
    padding: 0 var(--grid-spacer-and-indent);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__pagination-item {
    width: max-content;
    height: max-content;
    display: block;
    cursor: pointer;
    color: var(--base-black-50);
    transition: color var(--transition);

    &._active {
      color: var(--base-black-100);
    }
  }

  &__slider {
    &.swiper {
      padding: 0 var(--grid-spacer);
    }
  }

  &__dates-wrap {
    overflow: hidden;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: var(--grid-spacer-and-indent);
      right: var(--grid-spacer-and-indent);
      border-bottom: 1px solid var(--line-on-white);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__slide {
    &.swiper-slide {
      background: var(--neutral-grey);
      height: auto;
    }

    &:not(:last-child) {
      &::before {
        content: '';
        position: absolute;
        right: calc(-1 * (var(--grid-gap) / 2));
        top: 0;
        bottom: 0;
        border-right: 1px solid var(--line-on-white);
      }
    }
  }
}

@include respond-up('s-large') {
  .history-slider {
    &__pagination-item {
      &:hover {
        color: var(--base-black-100);
      }
    }

    &__dates-wrap {
      margin-top: 30px;
    }

    &__slider-wrap {
      margin-top: 20px;
    }

    &__slide {
      &.swiper-slide {
        width: var(--grid-column5);
      }
    }
  }
}

@include respond-up('medium') {
  .history-slider {
    &__pagination-item {
      &._active {
        + li.history-slider__pagination-item {
          color: var(--base-black-100);
        }
      }
    }

    &__dates-wrap {
      height: 68px;
    }

    &__pagination-item {
      padding: 24px 15px;
    }

    &__pagination {
      margin: 0 -15px;
    }
  }
}

@include respond('medium') {
  .history-slider {
    &__dates-wrap {
      margin-top: 20px;
    }

    &__slider-wrap {
      margin-top: 15px;
    }

    &__slide {
      &.swiper-slide {
        width: var(--grid-column4);
      }
    }
  }
}

@include respond-down('small') {
  .history-slider {
    &__dates-wrap {
      margin-top: 12px;
      height: 42px;
    }

    &__slider-wrap {
      margin-top: 10px;
    }

    &__slide {
      &.swiper-slide {
        width: calc(var(--grid-column4) - 18px);
      }
    }

    &__pagination-item {
      padding: 12px 9px;
    }

    &__pagination {
      margin: 0 -9px;
    }
  }
}