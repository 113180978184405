.arrow-down {
  width: 54px;
  height: 54px;
  border: 1px solid var(--base-white-100);
  background: var(--t);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background;
  transition-duration: var(--default-timing);
  transition-timing-function: var(--default-timing-function);

  svg {
    line,
    path {
      transition: stroke;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-timing-function);
    }
  }

  &:hover {
    background: var(--base-white-100);

    svg {
      line,
      path {
        stroke: var(--primary-color);
      }
    }
  }
}