.swiper {
  img{
    pointer-events: none;
  }
}

@include respond-up('large') {
  .swiper {

  }
}

@include respond-up('medium') {
  .swiper {

  }
}

@include respond('medium') {
  .swiper {

  }
}

@include respond-down('medium') {
  .swiper {

  }
}

@include respond-down('small') {
  .swiper {

  }
}