.article {
  &__content {
    .images-block__picture {
      background: var(--image-background);
    }
  }

  &__link-wrap {
    display: flex;
    flex-direction: column;

    .text__subheader-little {
      color: var(--accent-brown);
    }

    .text__body-little {
      margin-top: 8px;
    }
  }

  &__links {
    display: grid;
    grid-row-gap: calc(var(--grid-gap) / 2);
  }

  &__link {
    display: flex;
    background: var(--image-background);
    justify-content: space-between;

    svg {
      flex: 0 0 var(--icon-size);
      height: var(--icon-size);
      display: flex;
      justify-content: center;
      overflow: visible;
    }
  }

  &__hero-image,
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__hero-picture,
  &__picture {
    display: flex;
  }

  &__container {
    position: relative;
  }

  &__picture {
    display: flex;
  }
}

@include respond-up('s-large') {
  .article {
    &__content {
      .images-block-small {
        &__images-wrapper {
          margin-bottom: 50px;
        }
      }

      .images-block {
        &__picture {
          height: calc(var(--grid-column6) * 502 / 790);
        }

        &__images-wrapper {
          margin-bottom: 50px;
        }
      }
    }

    &__link {
      .text__subheader-little {
        transition: color var(--default-timing) var(--teaser-transition-func);
      }

      &:hover {
        .text__subheader-little {
          color: var(--accent-brown);
        }
      }
    }

    &__links {
      margin-top: 80px;
    }

    &__hero-picture {
      height: calc(var(--grid-column12) * 746 / 1620);
    }

    &__container {
      margin-top: 100px;
    }
  }
}

@include respond-up('medium') {
  .article {
    &__link {
      --icon-size: 20px;

      svg {
        margin-top: 10px;
      }
    }

    &__link-wrap {
      padding-right: 30px;
    }

    &__link {
      padding: 30px 40px;
    }

    &__title {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .article {
    &__content {
      .images-block-small {
        &__images-wrapper {
          margin-bottom: 30px;
        }
      }

      .images-block {
        &__picture {
          height: calc(var(--grid-column6) * 502 / 790);
        }

        &__images-wrapper {
          margin-bottom: 30px;
        }
      }
    }

    &__links {
      margin-top: 60px;
    }

    &__hero-picture {
      height: calc(var(--grid-column6) * 356 / 774);
    }

    &__container {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .article {
    &__container {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .article {
    &__link {
      --icon-size: 14px;

      svg {
        margin-top: 6px;
      }
    }

    &__content {
      .images-block-small {
        &__images-wrapper {
          margin-bottom: 10px;
        }
      }

      .images-block {
        &__picture {
          height: calc(var(--grid-column4) * 502 / 790);
        }

        &__images-wrapper {
          margin-bottom: 10px;
        }
      }
    }

    &__link-wrap {
      padding-right: 18px;
    }

    &__link {
      padding: 18px;
    }

    &__links {
      margin-top: 30px;
    }

    &__hero-picture {
      height: calc(var(--grid-column4) * 216 / 338);
    }

    &__container {
      margin-top: 30px;
    }
  }
}