.field._white {
  input[type="text"] {
    background-color: var(--base-white-100);
  }
  &:before{
    background-color: var(--base-white-100);
  }
  &:after{
    border-left: 15px solid var(--base-white-100);
    border-right: 0 solid var(--base-white-100);
    border-top: 0 solid var(--base-white-100);
  }
}

@include respond-up('s-large') {
  .field {

  }
}

@include respond-up('medium') {
  .field {

  }
}

@include respond('medium') {
  .field {

  }
}

@include respond-down('medium') {
  .field {

  }
}

@include respond-down('small') {
  .field {

  }
}