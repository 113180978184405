.pagination {
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__item {
    display: flex;
    border: 1px solid var(--line-on-white);
    justify-content: center;
    align-items: center;
    transition: color, border-color, background;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);

    &_previous {
      display: flex;
      margin-right: 4px;
    }

    &_next {
      margin-left: 4px;
      display: flex;
    }

    &_active {
      border: 1px solid var(--accent-brown);
      background: var(--accent-brown);
      color: var(--base-white-100);
    }
  }
}

@include respond-up('s-large') {
  .pagination {
    margin-top: 80px;
    margin-bottom: 150px;

    &__item {
      &:hover {
        border: 1px solid var(--line-on-white);
        background: var(--neutral-grey);
        color: var(--primary-color);
      }
    }

    &__list-wrap {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .pagination {
    &__item {
      width: 48px;
      height: 40px;
    }
  }
}

@include respond('medium') {
  .pagination {
    margin-top: 50px;
    margin-bottom: 100px;

    &__list-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .pagination {

  }
}

@include respond-down('small') {
  .pagination {
    margin-top: 30px;
    margin-bottom: 50px;

    &__item {
      width: 44px;
      height: 34px;
    }


    &__list-wrap {
      margin-top: 20px;
    }

  }
}