.city-select-form {
  &__input {
    input[type="radio"] {
      display: none;

      &:checked + label {
        color: var(--accent-brown);

        &::after {
          opacity: 1;
        }
      }
    }

    label {
      cursor: pointer;
      font-family: var(--font);
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      transition: color .3s var(--default-transition-function);
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      position: relative;

      &::before {
        content: '';
        width: var(--underline-width); // variable set by js - check underline-text
        height: 1px;
        display: block;
        position: absolute;
        background: var(--accent-brown);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-out;
      }

      &::after {
        content: '';
        display: flex;
        width: 14px;
        height: 14px;
        background-image: url("../images/svg/check.svg");
        background-repeat: no-repeat;
        background-size: 14px 14px;
        background-position: center;
        transition: opacity .3s var(--default-transition-function);
        opacity: 0;
      }

      &.underline {
        color: var(--accent-brown);

        &::before {
          transform-origin: left;
          transition: transform 0.3s var(--teaser-transition-func);
          transform: scaleX(1);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .city-select-form {
    &__field {
      margin: -7px 0;
    }

    &__input {
      label {
        padding: 7px 0;

        &::before {
          bottom: 7px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .city-select-form {
    &__field {
      margin: -6px 0;
    }

    &__input {
      label {
        padding: 6px 0;
        font-size: 15px;
      }

      &::before {
        bottom: 6px;
      }
    }
  }
}