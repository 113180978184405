.about-sidebar {
  &__pagination{
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

@include respond-up('s-large') {
  .about-sidebar {
    background: conic-gradient(from -49.7deg, var(--base-black-100) var(--animation-progress, 0%), var(--line-on-white) 0%);
    --animation-progress: 0;
    position: relative;
    height: #{"round(up, calc(318 / 375 * var(--grid-column3)), 1px)"};;

    &__content {
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      background: var(--base-white-100);
    }

    &__icon-wrap {
      position: absolute;
      top: 30px;
      left: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__list {
      width: 100%;
      height: 100%;
      padding: 30px 30px 23px;

      display: grid;
    }

    &__item {
      grid-column: 1;
      grid-row: 1;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &._active {
        .about-sidebar {
          &__pagination {
            opacity: 1;
          }

          &__title {
            opacity: 1;
            transition-delay: .3s;

            span {
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }

      &._next {
        .about-sidebar {
          &__title {
            span {
              transform: translate3d(0, 100%, 0);
            }
          }
        }
      }

      &._prev {
        .about-sidebar {
          &__title {
            span {
              transform: translate3d(0, -100%, 0);
            }
          }
        }
      }
    }

    &__title {
      opacity: 0;
      transition: opacity var(--transition);

      span {
        transform: translate3d(0, 100%, 0);
      }
    }

    &__pagination {
      width: fit-content;
      align-self: flex-end;
      opacity: 0;
      transition: opacity var(--transition);
    }
  }
}

@include respond-down('medium') {
  .about-sidebar {
    display: none;
  }
}