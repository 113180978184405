.index-slider {
  &__text-slider {
    position: absolute;
    left: var(--grid-spacer-and-indent);
    bottom: 0;
    z-index: 50;
  }

  &__text-slider-list {
    position: relative;
  }

  &__text-slide {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    opacity: 0;
    transition-delay: var(--long-timing);

    &._active {
      z-index: 15;
      opacity: 1;
      transition-delay: unset;

      .index-slider {
        &__title-wrap {
          .t-wrapper__inner {
            span {
              transform: translate3d(0, 0, 0);
            }
          }
        }

        &__label,
        &__button {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    &._out-next-act {
      .index-slider {
        &__button {
          transition-delay: .1s;
        }

        &__label,
        &__button {
          opacity: 0;
          transform: translate3d(0, -20px, 0);
        }

        &__title-wrap {
          .t-wrapper__inner {
            span {
              transition-duration: .4s;
              transform: translate3d(0, -110%, 0);
            }
          }
        }
      }
    }

    &._in-next-act {
      .index-slider {
        &__label {
          transition-delay: .4s;
        }
      }
    }
  }

  &__title-wrap {
    .t-wrapper__inner {
      span {
        transform: translate3d(0, 110%, 0);
        transition-duration: .7s;
      }

      @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
          span {
            transition-delay: calc(#{$i} * 0.1s + 0.1s);
          }
        }
      }
    }
  }

  &__button {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: {
      property: opacity, transform;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
      delay: .7s;
    };
  }

  &__label {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: {
      property: opacity, transform;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
      delay: .2s;
    };
  }

  .content-header {
    &__wrapper {
      &::before,
      &::after {
        transition: opacity var(--transition);
      }
    }

    &__slider-nav,
    &__pagination-wrapper {
      transition: opacity var(--transition);
    }

    &__slider {
      &.picture-wrapper__picture {
        opacity: 1;
        transition: {
          property: opacity, transform;
          duration: var(--long-timing);
          timing-function: var(--default-transition-function);
        };
      }
    }

    &__slider-item {
      &::before {
        transition-duration: var(--long-timing);
      }

      &.swiper-slide-prev {
        &::before {
          background: var(--base-black-100);
        }
      }
    }
  }

  &.need-animation {
    .index-slider {
      &__button,
      &__label {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
      }

      &__title-wrap {
        .t-wrapper__inner {
          span {
            opacity: 1;
            transform: translate3d(0, 110%, 0);
          }
        }
      }
    }

    .content-header {
      &__wrapper {
        &::before,
        &::after {
          opacity: 0;
        }
      }

      &__slider-nav,
      &__pagination-wrapper {
        opacity: 0;
      }
    }
  }

  &__slide {
    &.swiper-slide {
      .content-header__picture {
        transition: transform var(--long-timing) var(--default-transition-function);
      }
    }

    &.swiper-slide-next {
      .index-slider {
        &__image {
          transform: scale(1.2);
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--t);
      transition: background var(--long-timing) var(--default-transition-function);
    }

    &.swiper-slide-prev {
      &::after {
        background: var(--base-black-100);
      }
    }
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform var(--long-timing) var(--default-transition-function);
  }
}

@include respond-up('s-large') {
  .index-slider {
    &__text-slider {
      bottom: 60px;
    }

    &__button {
      margin-top: 40px;
    }

    &__picture {
      height: clamp(10vh, calc(100vh - var(--header-height)), 1200px);
    }
  }
}

@include respond-up('medium') {
  .index-slider {
    &__text-slider {
      width: var(--grid-column5);
    }

    &__label {
      margin-bottom: 20px;
    }

    &__button {
      &.button {
        width: fit-content;
        min-width: 295px;
      }
    }

    &__picture {
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .index-slider {
    &__text-slider {
      bottom: 120px;

      &_single {
        bottom: 40px;
      }
    }

    &__button {
      margin-top: 30px;
    }

    &__picture {
      height: clamp(10vh, calc(100vh - var(--header-height)), 1200px);
    }
  }
}

@include respond-down('medium') {
  .index-slider {

  }
}

@include respond-down('small') {
  .index-slider {
    &__wrap {
      &.content-header__wrapper {
        height: calc(100vw * 578 / 374);
      }
    }

    &__text-slider {
      bottom: 94px;

      &_single {
        bottom: 30px;
      }
    }

    &__text-slider {
      width: var(--grid-column4);
    }

    &__label {
      margin-bottom: 15px;
    }

    &__button {
      margin-top: 20px;
    }

    &__picture {
      height: calc(100vw * 578 / 374);

      &_desk {
        display: none;
      }
    }
  }
}