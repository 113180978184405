.articles {
  &__list {
    display: grid;
    column-gap: var(--grid-gap);
    border-top: 1px solid var(--line-on-white);
  }

  &__item-link {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  &__item-title-wrap {
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__item-title {
    transition: color var(--default-timing) var(--default-transition-function);

    span {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        background: var(--accent-brown);
        bottom: 1px;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-out;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    width: 100%;
    display: flex;
    overflow: hidden;
  }

  &__text-wrap {
    margin-top: 20px;
  }

  &__text {
    max-height: 66px;
    overflow: hidden;
    transition: max-height 0.4s linear;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; /* Добавляем многоточие для обрезанного текста */
    white-space: normal;

    &.open {
      -webkit-line-clamp: unset; /* Максимум строк до обрезания */
    }
  }

  &__text-opener {
    display: flex;
  //
  //  &:before {
  //    content: 'Читать далее';
  //    color: var(--accent-blue)
  //  }
  //
  //  &.open {
  //    &:before {
  //      content: 'Скрыть';
  //    }
  //  }
  }

  &__wrap-image {
    display: flex;
  }

  &__item-date {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

@include respond-up('large') {
  .articles {
    &__item {
      &:nth-child(1),
      &:nth-child(10) {
        .articles {
          &__item-title-wrap {
            width: var(--grid-column5);
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .articles {
    &__item-title-wrap {
      width: var(--grid-column3);
    }

    &__list {
      grid-template-columns: repeat(4, var(--grid-column3));
      row-gap: 100px;
      padding-top: 20px;
      padding-bottom: 150px;
    }

    &__picture {
      height: calc(var(--grid-column3) * 240 / 375);
    }

    &__item {
      &:nth-child(9n+1) {
        grid-column: span 2;

        .articles__picture {
          height: calc(var(--grid-column6) * 504 / 790);
        }
      }

      &:nth-child(1) {
        .articles__text{
          width: calc(var(--grid-column4));
        }
        .articles__item-title-wrap{
          width: calc(var(--grid-column5));
        }

        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          right: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2) {
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          right: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(1n + 5) {
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          left: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(4n + 4) {
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column12);
          height: 1px;
          left: 0;
          top: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(10) {
        .articles__text{
          width: calc(var(--grid-column4));
        }
        .articles__item-title-wrap{
          width: calc(var(--grid-column5));
        }
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          left: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(11) {
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column12);
          height: 1px;
          left: 0;
          top: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(1n + 12) {
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          left: -20px;
          background: var(--line-on-white);
        }
      }
    }

    &__item-link {
      &:hover {
        .articles {
          &__item-date-arrow {
            opacity: 1;
          }

          &__item-title {
            color: var(--accent-brown);

            span {
              &::after {
                transform-origin: left;
                transition: transform 0.3s var(--teaser-transition-func);
                transform: scaleX(1);
              }
            }

          }
        }
      }
    }

    &__item-date {
      margin-top: 50px;
    }

    &__item-date-arrow {
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@include respond('s-large') {
  .articles {
    &__item {
      &:nth-child(1),
      &:nth-child(10) {
        .articles {
          &__item-title-wrap {
            width: var(--grid-column6);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .articles {
    &__tabs {
      margin-top: 40px;
    }

    &__wrap {
      margin-top: 40px;
    }

    &__text-opener {
      margin-top: 10px;
    }

    &__wrap-image {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .articles {
    &__item-date {
      margin-top: 40px;
    }

    &__list {
      grid-template-columns: 1fr 1fr;
      row-gap: 65px;
      padding-top: 15px;
      padding-bottom: 100px;
    }

    &__item {
      &:nth-child(10),
      &:nth-child(1) {
        .articles__text{
          width: calc(var(--grid-column5));
        }
        .articles__item-title-wrap{
          width: calc(var(--grid-column5));
        }
        .articles__picture {
          width: var(--grid-column6);
          height: calc(var(--grid-column6) * 494 / 774);
        }
      }

      &:nth-child(1) {
        grid-column: 1/3;
        width: var(--grid-column6);
      }

      &:nth-child(2n +2) {
        grid-column: 1/2;
        width: var(--grid-column3);

        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column6);
          height: 1px;
          left: 0;
          top: -15px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2n +3) {
        grid-column: 2/3;
        width: var(--grid-column3);

        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          left: -15px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(10) {
        grid-column: 1/3;
        width: var(--grid-column6);
      }

      &:nth-child(2n + 11) {
        grid-column: 1/2;
        width: var(--grid-column3);

        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column6);
          height: 1px;
          left: 0;
          top: -15px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2n + 12) {
        grid-column: 2/3;
        width: var(--grid-column3);

        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          left: -15px;
          background: var(--line-on-white);
        }
      }
    }

    &__picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 238 / 372);
    }
  }
}

@include respond-down('medium') {
  .articles {
    &__item-date-arrow {
      display: none;
    }
  }
}

@include respond-down('small') {
  .articles {
    &__tabs {
      margin-top: 20px;
    }

    &__item-date {
      margin-top: 20px;
    }

    &__wrap {
      margin-top: 20px;
    }

    &__text-opener {
      margin-top: 6px;
    }

    &__list {
      padding-bottom: 50px;
      padding-top: 9px;
      grid-template-columns: 1fr;
      row-gap: 39px;
    }

    &__item {
      width: var(--grid-column4);

      &:nth-child(1n + 2) {
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column4);
          height: 1px;
          left: 0;
          top: -9px;
          background: var(--line-on-white);
        }
      }
    }

    &__wrap-image {
      margin-top: 18px;
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 216 / 338);
    }
  }
}