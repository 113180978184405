.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@include respond-up('s-large') {
  .error-page {
    padding-top: 100px;
    margin-top: 120px;
    padding-bottom: 150px;
  }
}

@include respond-up('medium') {
  .error-page {
    &__description {
      margin-top: 50px;
      gap: 20px;
    }

    &__button {
      margin-top: 40px;
      width: calc(var(--grid-column2) + var(--grid-gap) * 2);
    }
  }
}

@include respond('medium') {
  .error-page {
    padding-top: 100px;
    margin-top: 120px;
    padding-bottom: 150px;
  }
}

@include respond-down('small') {
  .error-page {
    padding-top: 54px;
    margin-top: 60px;
    padding-bottom: 100px;

    &__code{
      svg{
        width: 222px;
        height: 74px;
      }
    }
    &__description {
      gap: 10px;
      margin-top: 40px;
    }
    &__button {
      width: var(--grid-column4);
      margin-top: 30px;
    }
  }
}