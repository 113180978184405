.breadcrumbs {
  --breadcrumbs-link-color: var(--primary-color);
  --breadcrumbs-item-color: var(--base-black-50);

  &_white {
    --breadcrumbs-link-color: var(--base-white-100);
    --breadcrumbs-item-color: var(--base-white-50);
  }

  &__item,
  &__list {
    display: flex;
    align-items: center;

  }

  &__item {
    justify-content: center;
    color: var(--breadcrumbs-item-color);

    &:last-child {
      display: block;
    }

    &_delimiter {
      background: var(--breadcrumbs-link-color);
      padding: 2px;
      margin-left: var(--link-padding);
      margin-right: var(--link-padding);
    }
  }

  &__link {
    display: block;
    color: var(--breadcrumbs-link-color);
    padding: var(--link-padding);
    margin: calc(-1 * var(--link-padding));
  }

  //&__link,
  &__item_delimiter-icon {
    display: flex;
  }

  &__item_delimiter {
    margin-top: 4px;
  }
}

@include respond-up("s-large") {
  .breadcrumbs {
    &__link {
      position: relative;

      &:before {
        content: "";
        height: 1px;
        width: calc(100% - 2 * var(--link-padding));
        position: absolute;
        background: var(--breadcrumbs-link-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform;
        transition-duration: var(--default-timing);
        transition-timing-function: var(--teaser-transition-func);
        bottom: 10px;
      }

      &:hover {
        &:before {
          transform-origin: left;
          transition: transform var(--default-timing) var(--teaser-transition-func);
          transform: scaleX(1);
        }
      }
    }
  }
}

@include respond-up("medium") {
  .breadcrumbs {
    --link-padding: 10px;
    margin-top: 30px;
  }
}

@include respond-down("medium") {
  .breadcrumbs {
    &__item {
      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //display: flex;
        //justify-content: flex-start;
      }
    }
  }
}

@include respond-down("small") {
  .breadcrumbs {
    margin-top: 18px;
    --link-padding: 8px;
  }
}