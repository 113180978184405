.production-type {
  &__list {
    display: grid;
    column-gap: var(--grid-gap);
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include respond-up('large') {
  .production-type {
    &__text,
    &__title {
      width: var(--grid-column3);
    }
  }
}

@include respond('s-large') {
  .production-type {
    &__text,
    &__title {
      width: var(--grid-column4);
    }
  }
}

@include respond-up('s-large') {
  .production-type {
    margin-top: 150px;

    &__list {
      margin-top: 30px;
      grid-template-columns: repeat(3, var(--grid-column4));
      row-gap: 80px;
    }

    &__item {
      padding-top: 20px;

      &:nth-child(3n + 1) {
        &:after {
          width: var(--grid-column12);
          content: " ";
          height: 1px;
          left: 0;
          top: 0;
          background: var(--line-on-white);
          position: absolute;
        }
      }

      &:nth-child(3n + 2),
      &:nth-child(3n + 3) {
        &:after {
          width: 1px;
          content: " ";
          height: calc(100% - 20px);
          left: -20px;
          top: 20px;
          background: var(--line-on-white);
          position: absolute;
        }
      }
    }

    &__text {
      margin-top: 20px;
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 362 / 513);
    }
  }
}

@include respond-up('medium') {
  .production-type {
    &__title {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .production-type {
    margin-top: 100px;

    &__list {
      margin-top: 20px;
      grid-template-columns: repeat(2, var(--grid-column3));
      row-gap: 40px;
    }

    &__item {
      padding-top: 15px;

      &:nth-child(2n + 1) {
        &:after {
          width: var(--grid-column6);
          content: " ";
          height: 1px;
          left: 0;
          top: 0;
          background: var(--line-on-white);
          position: absolute;
        }
      }

      &:nth-child(2n + 2) {
        &:after {
          width: 1px;
          content: " ";
          height: calc(100% - 15px);
          left: -15px;
          top: 15px;
          background: var(--line-on-white);
          position: absolute;
        }
      }
    }

    &__text {
      margin-top: 15px;
    }

    &__picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 263 / 372);
    }
  }
}

@include respond-down('medium') {
  .production-type {
  }
}

@include respond-down('small') {
  .production-type {
    margin-top: 50px;

    &__list {
      margin-top: 12px;
      grid-template-columns: repeat(1, var(--grid-column4));
      row-gap: 30px;
    }

    &__item {
      padding-top: 9px;

      &:after {
        width: var(--grid-column4);
        content: " ";
        height: 1px;
        left: 0;
        top: 0;
        background: var(--line-on-white);
        position: absolute;
      }
    }

    &__title {
      margin-top: 12px;
    }

    &__text {
      margin-top: 8px;
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 239 / 338);
    }
  }
}