.modal-form {
  position: absolute;
  top: 0;
  left: 0;
  height: max-content;
  min-height: 100%;
  transition: opacity .5s var(--default-transition-function);
  padding: var(--modal-content-padding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;

  &_center {
    justify-content: center;
  }

  &._closing {
    opacity: 0;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__buttons,
  input[type="hidden"] {
    + div {
      button[type="submit"] {
        display: none;
      }
    }
  }

  &__bottom-text {
    width: var(--grid-column4);
  }
}

@include respond-up('s-large') {
  .modal-form {
    width: var(--modal-content-width);
  }
}

@include respond-up('medium') {
  .modal-form {
    &__subtitle {
      width: var(--grid-column4);
      margin-top: 20px;
    }

    &__fields-wrap {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      grid-gap: 20px;

      &_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__field-wrap {
      &_half,
      &_name,
      &_phone {
        grid-column: span 1;
      }

      &_wide,
      &_text,
      &_files {
        grid-column: span 2;
      }
    }

    &__buttons {
      margin-top: 20px;
      row-gap: 30px;
    }

    &__submit {
      &.button {
        width: 295px;
      }
    }
  }
}

@include respond('medium') {
  .modal-form {

  }
}

@include respond-down('medium') {
  .modal-form {
    width: 100%;
  }
}

@include respond-down('small') {
  .modal-form {
    &__subtitle {
      margin-top: 15px;
    }

    &__fields-wrap {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &__buttons {
      margin-top: 10px;
      row-gap: 20px;
    }
  }
}