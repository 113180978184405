.link-button {
  &._brown {
    --base-color: var(--base-black-100);
    --hover-color: var(--accent-brown);
  }

  &._light {
    --base-color: var(--neutral-biege);
    --hover-color: var(--neutral-biege-50);
  }

  &._blue {
    --base-color: var(--accent-blue);
    --hover-color: var(--accent-blue-light);
  }

  &__wrap {
    display: inline-flex;
    gap: 6px;
    align-items: center;
    position: relative;
  }

  &__text {
    font-family: var(--font);
    font-weight: 600;
    line-height: 140%;
    text-align: left;
    transition: color;
    color: var(--base-color);
    transition-duration: var(--default-timing);
    transition-timing-function: var(--teaser-transition-func);
  }

  &._triangle {
    .link-button {

      &__text {
        position: relative;
        padding-right: 14px;

        //треугольник
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 0;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 4px 4px 0 4px;
          border-color: var(--base-color) transparent transparent transparent;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .link-button {
    &__text {
      display: block;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        background: var(--hover-color);
        bottom: 0;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-out;
      }
    }

    //
    &.underline {
      .link-button {
        &__text {
          color: var(--hover-color);
          &::after {
            transform-origin: left;
            transition: transform 0.3s var(--teaser-transition-func);
            transform: scaleX(1);
          }
          &:before {
            border-color: var(--hover-color) transparent transparent transparent;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .link-button {
    &__text {
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .link-button {
    &__text {
      font-size: 15px;
    }
  }
}