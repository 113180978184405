@font-face {
    font-family: 'Wix Madefor';
    src: url('WixMadeforDisplay-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

/*@font-face {*/
/*    font-family: 'Wix Madefor';*/
/*    src: url('WixMadeforDisplay-ExtraBold.ttf');*/
/*    font-weight: 800;*/
/*    font-style: normal;*/
/*}*/

@font-face {
    font-family: 'Wix Madefor';
    src: url('WixMadeforDisplay-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

/*@font-face {*/
/*    font-family: 'Wix Madefor';*/
/*    src: url('WixMadeforDisplay-Regular.ttf');*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/

@font-face {
    font-family: 'Wix Madefor';
    src: url('WixMadeforDisplay-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}