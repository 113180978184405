.project-filter {
  &.article{
    .filter-fieldset__clear-wrap{
      display: none;
    }
  }
}

@include respond-up('s-large') {
  .project-filter {
    .filter-fieldset {
      margin-top: 70px;
    }
  }
}

@include respond-up('medium') {
  .project-filter {
    &__link {
      display: none;
    }

    .filter-fieldset {

      &__clear-wrap {
        display: flex;
        flex: auto;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
}

@include respond('medium') {
  .project-filter {
    .filter-fieldset {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .project-filter {

  }
}

@include respond-down('small') {
  .project-filter {
    &__container {
      margin-top: 40px;
    }

    &__element {
      &._opened {
        .project-filter {
          &__link-icon {
            &::before,
            &::after {
              opacity: 1;
            }
            svg {
              opacity: 0;
            }
          }
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      column-gap: 14px;
      width: fit-content;
    }

    &__link-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 22px;
        border-bottom: 2px solid var(--accent-brown);
        top: 50%;
        left: 50%;
        transition: opacity var(--default-timing) var(--default-transition-function);
        opacity: 0;
      }

      &::before {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }

      &::after {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      }

      svg {
        width: 100%;
        height: 100%;
        transition: opacity var(--default-timing) var(--default-transition-function);
      }
    }

    &__link-title-wrap {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    &__link-counter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      padding: 2px 5px 4px;
      background-color: var(--neutral-biege);
      color: var(--accent-brown);
      border-radius: 100px;

      font-family: var(--font);
      font-size: 11px;
      font-weight: 700;
      line-height: 80%;
    }

    &__block {
      height: 0;
      transition: height var(--default-timing) var(--default-transition-function);
      overflow: hidden;
    }

    &__content {
      padding-top: 20px;
    }

    .filter-fieldset {
      &__fields {
        position: relative;
      }

      &__clear-wrap {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}