.board-info {
  display: flex;
  flex-direction: row;
  padding: 40px;

  &._brown {
    --background: var(--base-black-100);
    --color: var(--neutral-biege);
  }

  &._black {
    --background: var(--accent-brown);
    --color: var(--neutral-biege);
  }

  &._gray {
    --background: var(--neutral-biege);
    --color: var(--accent-brown);
  }

  &._white {
    --background: var(--neutral-biege);
    --color: var(--accent-brown);
  }

  background: var(--background);

  &__text {
    color: var(--color);
    padding-right: var(--grid-gap);
  }
}

@include respond-up('s-large') {
  .board-info {
    .button {
      width: 325px;
    }
  }
}

@include respond-up('medium') {
  .board-info {
    align-items: center;
    justify-content: space-between;
  }
}

@include respond('medium') {
  .board-info {
    padding: 30px;

    .button {
      width: 280px;
      flex: 1 0 280px;
    }
  }
}

@include respond-down('medium') {
  .board-info {

  }
}

@include respond-down('small') {
  .board-info {
    flex-direction: column;
    justify-content: flex-start;
    gap: 18px;
    padding: 18px;

    .button {
      width: 100%;
    }
  }
}