.tabs {
  list-style: none;
  display: flex;
  justify-content: flex-start;

  li {
    a {
      display: block;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__text {
    white-space: nowrap;
  }

  &__item {

    &:hover{
      .tabs{
        &__link {
          background-color: var(--accent-brown);
          color: var(--base-white-100);
        }
      }
    }

    &:not(:last-child) {
      margin-right: 4px;
    }

    &._active {
      .tabs {
        &__link {
          background-color: var(--accent-brown);
          color: var(--base-white-100);
        }
      }
    }
  }

  &__list {
    display: flex;
  }

  &__link {
    background-color: var(--neutral-grey);
    color: var(--primary-color);
    transition: color, background-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--teaser-transition-func);
  }

  &__first {
    //Табы 1
    .tabs {
    }
  }
}

@include respond-up('s-large') {
  .tabs {
    &__item {
      &:hover {
        .tabs {
          &__link {
            background-color: var(--accent-brown);
            color: var(--base-white-100);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .tabs {
    &__link {
      padding: 12px 18px;
    }
  }
}

@include respond-down('small') {
  .tabs {
    &__link {
      padding: 8px 14px;
    }
  }
}