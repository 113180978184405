article {
  color: var(--primary-color);

  table {
    margin-top: var(--grid-gap);
    border-collapse: collapse;
    color: var(--primary-color);
    min-width: 100%;

    tr {
      td {
        font-family: var(--font);
        font-style: normal;
        line-height: 140%;

        //&:first-child {
        //  padding-right: clamp(20px, calc(1vw * 30 / 17), 40px);
        //}
      }

      &:first-child {
        td {
          font-weight: 600;
        }
      }

      &:not(:first-child) {
        td {
          font-weight: 500;
        }
      }
    }

    th,
    td {
      text-wrap: nowrap;
      padding: 10px 20px;
      border: 1px solid var(--line-on-white);
    }

    th {
    }
  }

  ol {
    counter-reset: counter;

    li {
      counter-increment: counter;
      position: relative;

      &:before {
        content: counter(counter) '.';
        position: absolute;
        left: 0;
        top: 4px;
        font-family: var(--font);
        font-style: normal;
        font-weight: 700;
        color: var(--accent-brown);
        line-height: 100%;
      }
    }
  }

  ul {
    li {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 6px;
        height: 6px;
        background: var(--accent-brown);
      }
    }
  }

  //__body-big
  &, p, li {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
  }

  //__subheader-middle
  strong {
    font-family: var(--font);
    font-size: var(--h-size);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
  }

  em {
    font-style: italic;
  }

  a {
    color: var(--accent-blue);
    //color: var(--primary-color);
    text-decoration: none;
    //&:hover {
    //  text-decoration: underline;
    //}
  }
}

@include respond-up("large") {
  article {
    strong {
      --h-size: clamp(24px, calc(0.01538 * 100vw + 1.8461px), 28px);
    }
  }
}

@include respond-up("s-large") {
  article {
    a {
      transition: text-decoration var(--default-timing) var(--teaser-transition-func);

      &:hover {
        text-decoration: underline;
      }
    }

    h1, h2, h3 {
      //&:not(:first-child) {
      margin-top: 100px;
      //}
    }

    ul, ol, p {
      //&:not(:first-child) {
      margin-top: 30px;
      //}
    }
  }
}

@include respond("s-large") {
  article {
    strong {
      --h-size: 24px;
    }
  }
}

@include respond-up("medium") {
  article {
    tr {
      td {
        font-size: 16px;
      }
    }

    ul {
      li {
        &:before {
          top: 10px;
        }
      }
    }

    li {
      padding-left: 33px;

      //&:not(:first-child) {
      margin-top: 10px;
      //}
    }

    &, p, li {
      font-size: 18px;

      &:before {
        font-size: 18px;
      }
    }
  }
}

@include respond("medium") {
  article {
    strong {
      --h-size: clamp(24px, calc(0.0303 * 100vw + 0.7272px), 26px);
    }

    h1, h2, h3 {
      //&:not(:first-child) {
      margin-top: 70px;
      //}
    }

    ul, ol, p {
      //&:not(:first-child) {
      margin-top: 20px;
      //}
    }
  }
}

@include respond-down("medium") {
  article {
  }
}

@include respond-down("small") {
  article {
    tr {
      td {
        font-size: 15px;
      }
    }

    ul {
      li {
        &:before {
          top: 8px;
        }
      }
    }

    li {
      padding-left: 25px;

      //&:not(:first-child) {
      margin-top: 8px;
      //}
    }

    strong {
      --h-size: clamp(19px, calc(0.019 * 100vw + 13.1818px), 20px);
    }

    h1, h2, h3 {
      //&:not(:first-child) {
      margin-top: 40px;
      //}
    }

    ul, ol, p {
      //&:not(:first-child) {
      margin-top: 10px;
      //}
    }

    &, p, li {
      //&:not(:first-child) {
      font-size: 16px;

      &:before {
        font-size: 16px;
      }

      //}
    }
  }
}