.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100); //true-vh
  background: var(--base-white-100);
  color: var(--primary-color);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(64, 64, 61, 0.4);
    opacity: 0;
    z-index: 900;
    pointer-events: none;
    transition: opacity var(--transition);
  }

  &._dark {
    &::after {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@include respond-up('medium') {
  :root{
    --header-height: 74px;
  }
  .layout {
      --header-height: 74px;
  }
}

@include respond('small') {
  :root{
    --header-height: 50px;
  }
  .layout {
      --header-height: 50px;
  }
}