.slider-nav {
  &__wrapper{
    display: flex;
    gap: 4px;
  }
}

@include respond-up('large') {
  .slider-nav {

  }
}

@include respond-up('medium') {
  .slider-nav {

  }
}

@include respond('medium') {
  .slider-nav {

  }
}

@include respond-down('medium') {
  .slider-nav {

  }
}

@include respond-down('small') {
  .slider-nav {

  }
}