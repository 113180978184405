.block-form {
  border-top: 1px solid var(--line-on-white);

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
  }

  &__wrap-title {
    flex-direction: column;
  }

  &__right {
    display: flex;
    flex-direction: column;
  }

  &__title-over {
    color: var(--base-black-50);
  }
}

@include respond-up('s-large') {
  .block-form {
    margin-top: 150px;
    margin-bottom: 150px;

    &_close {
      margin-top: 0;
    }

    &.grid-block {
      grid-row-gap: 50px;
    }

    &__wrap-info {
      grid-column: 1/5;
      grid-row: 1/3;
    }

    &__wrap-title {
      grid-row: 1;
      padding-top: 10px;
    }

    &__wrap-title,
    &__form-wrap {
      grid-column: 7/13;
    }

    &__form-wrap {
      grid-row: 2;
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 358 / 513);
    }

    &__left-text {
      display: block;
      margin-top: 20px;
      width: var(--grid-column3);
    }

    &__wrap-title {
      display: flex;
    }

    &__title {
      margin-top: 50px;
      width: var(--grid-column5);
    }

    &:has(.request-form.success) {
      .block-form {
        &__title {
          display: none;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .block-form {
    padding-top: 20px;
  }
}

@include respond('medium') {
  .block-form {
    margin-top: 100px;
    margin-bottom: 100px;

    &__title {
      margin-top: 30px;
    }

    &__wrap-info {
      gap: var(--grid-gap);
      flex-direction: row;
      margin-top: 40px;
    }

    &__picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 260 / 372);
    }

    &__left-text {
      width: var(--grid-column3);
    }

    &__wrap-title,
    &__title {
      width: var(--grid-column5);
    }

    &__form-wrap {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .block-form {
    flex-direction: column;

    &.grid-block {
      display: flex;
      grid-gap: 0;
    }

    &__wrap-title,
    &__wrap-info {
      display: flex;
    }
  }
}

@include respond-down('small') {
  .block-form {
    margin-top: 50px;
    padding-top: 10px;
    margin-bottom: 50px;

    &__title {
      margin-top: 20px;
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 236 / 338);
    }

    &__wrap-info {
      flex-direction: column;
      margin-top: 20px;
    }

    &__left-text {
      margin-top: 20px;
    }

    &__form-wrap {
      margin-top: 40px;
    }
  }
}