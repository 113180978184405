.partners-products {
  &__accordion-wrap {
    border-top: 1px solid var(--line-on-white);

    &:last-child {
      border-bottom: 1px solid var(--line-on-white);
    }
  }
}

@include respond-up('s-large') {
  .partners-products {
    margin-top: 150px;

    &__container {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .partners-products {
    margin-top: 100px;

    &__container {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .partners-products {
    margin-top: 50px;

    &__container {
      margin-top: 12px;
    }
  }
}