.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 12000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--base-black-100);
  pointer-events: none;

  &.animate {
    opacity: 0;
    transition: opacity 1s var(--default-transition-function) 3s;
  }

  &__upper-wrap {
    height: 60px;
    display: flex;
    align-items: flex-end;
  }

  &__inner {
    display: flex;
    align-items: flex-end;
    position: relative;
  }

  &__logo-icon-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: flex-end;
    bottom: 0;
    left: 0;
    transform-origin: bottom left;
    scale: .53;
  }

  &__logo-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;

    svg {
      transform: translate3d(0, 0, 0);
    }

    &_upper {
      svg {
        position: absolute;
        bottom: 0;
        #upper-dark {
          transform-origin: top center;

          scale: 1 2.9;
        }
      }
    }

    &_lower {
      svg {
        #lower-dark {
          transform-origin: bottom center;
        }
      }
    }
  }

  &__logo-icon-name {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.animate {
    .preloader {
      &__logo-icon-wrap {
        transition: transform .6s var(--default-transition-function) 2.4s,
                    scale .6s var(--default-transition-function) 2.4s,
                    left .6s var(--default-transition-function) 2.4s;
      }

      &__logo-icon {
        transition: opacity 1s var(--default-transition-function) .6s,
                    transform 1s var(--default-transition-function) .6s,
                    height 1s var(--default-transition-function) 1.6s;

        svg {
          #upper-dark, #lower-dark {
            transition: {
              property: scale;
              duration: .6s;
              timing-function: var(--default-transition-function);
              delay: 1.6s;
            };
          }
        }
      }

      &__logo-icon-name {
        transition: opacity 1s var(--default-transition-function) 2.6s;
      }
    }
  }

  &.need-animation {
    .preloader {
      &__logo-icon-wrap {
        scale: 1;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }

      &__logo-icon {
        opacity: 0;

        svg {
          #upper-dark, #lower-dark {
            scale: 1 0;
          }
        }

        &_upper {
          height: 60px;
          transform: translate3d(0, -35px, 0);
        }

        &_lower {
          transform: translate3d(0, 35px, 0);
        }
      }

      &__logo-icon-name {
        opacity: 0;
      }
    }
  }
}

@include respond-up('medium') {
  .preloader {
    &__inner {
      column-gap: 15px;
    }

    &__logo-icon-name {
      width: 275px;
      height: 32px;
    }

    &__icon-wrap {
      width: 42px;
      height: 42px;
    }

    &__logo-icon {
      svg {
        width: 80px;
        height: 60px;
      }

      &_upper {
        height: 21px;
      }
    }
  }
}

@include respond-down('small') {
  .preloader {
    &__inner {
      column-gap: 10px;
    }

    &__logo-icon-name {
      width: 193px;
      height: 23px;
    }

    &__icon-wrap {
      width: 30px;
      height: 30px;
    }

    &__logo-icon {
      svg {
        width: 56px;
        height: 42px;
      }

      &_upper {
        height: 14px;
      }
    }
  }
}