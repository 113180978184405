.arrow-right{
  display: inline-flex;
  position: relative;

  &._white{
    --background-color: transparent;
    --line-color: var(--line-on-white);
    --arrow-color-line: var(--base-black-100);
  }

  &._dark{
    --background-color: transparent;
    --line-color: var(--base-white-100);
    --arrow-color-line: var(--base-white-100);
  }

  &._dark-light{
    --background-color: transparent;
    --line-color: var(--line-on-brown);
    --arrow-color-line: var(--neutral-biege);
  }

  &:hover{
    &._white{
      --background-color: var(--accent-brown);
      --line-color: var(--accent-brown);
      --arrow-color-line: var(--base-white-100);
    }

    &._dark{
      --background-color: var(--base-white-100);
      --arrow-color-line: var(--base-black-100);
    }

    &._dark-light{
      --background-color: var(--neutral-biege);
      --line-color: var(--neutral-biege);
      --arrow-color-line: var(--base-black-100);
    }
  }

  &__arrow{
    transition: stroke var(--default-timing) var(--default-transition-function);
      line{
        stroke: var(--arrow-color-line);
        transition: stroke var(--default-timing) var(--default-transition-function);

      }
      path{
        stroke: var(--arrow-color-line);
        transition: stroke var(--default-timing) var(--default-transition-function);
      }
  }

  &__text{
    display: flex;
    //flex: 0 0 53px;
    align-items: center;
    justify-content: center;
    background: var(--background-color);
    border-top: 1px solid var(--line-color);
    border-left: 1px solid var(--line-color);
    border-bottom: 1px solid var(--line-color);
    transition: background var(--default-timing) var(--default-transition-function);
  }

  &__corner {
    display: block;
    position: relative;
    width: 12px;

    &:before {
      width: 11px;
      content: "";
      position: absolute;
      bottom: 0;
      background: var(--background-color);
      transition: background var(--default-timing) var(--default-transition-function);
      border-bottom: 1px solid var(--line-color);
      border-right: 1px solid var(--line-color);
    }

    &:after {
      border-width: 12px 0 0 12px;
      content: "";
      position: absolute;
      top: 0;
      border-style: solid;

      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent transparent var(--background-color);
      transform: rotate(0deg);
      transition: border-color var(--default-timing) var(--default-transition-function);
    }
  }
  &__border-inner{
    background: var(--line-color);
    height: 1px;
    right: -4px;
    top: 0;
    position: absolute;
    transform: rotate(42deg) translate(0);
    transform-origin: left;
    width: 16px;
    z-index: 100;
    //transition: background var(--default-timing) var(--default-transition-function);
  }
}

@include respond-up('medium') {
  .arrow-right{
    //width: 66px;
    height: 40px;
    &__text{
      padding: 18px 7px  18px 16px;
    }

    &__corner {
      &:before {
        height: 27px;
      }
    }
  }
}

@include respond('small') {
  .arrow-right{
    height: 34px;

    &__text{
      padding: 9px 4px 9px 11px;
    }

    &__corner {
      width: 10px;
      &:before {
        height: 23px;
        width: 9px;
      }
      &:after {
        border-width: 10px 0 0 10px;
      }
    }
    &__border-inner{
      right: -4px;
      width: 14px;
      transform: rotate(45deg) translate(0);
    }
  }
}