/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
        small: (
                from: 0,
                to: 767
        ),
        medium: (
                from: 768,
                to: 1179
        ),
        s-large: (
                from: 1180,
                to: 1440
        ),
        large: (
                from: 1441,
                to: 1920
        ),
        x-large: (
                from: 1921
        )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Wix Madefor", sans-serif;
  --h-font: "Actay Wide", sans-serif;

  --t: transparent;

  --primary-color: var(--base-black-100);
  --background-color: var(--accent-silver); // серая подлога
  --alert-color: #FF6969;

  --base-black-100: rgba(64, 64, 61, 1);
  --base-black-50: rgba(64, 64, 61, 0.5);
  --base-black-30: rgba(64, 64, 61, 0.3);
  --base-black-5: rgba(64, 64, 61, 0.05);
  --base-white-100: rgb(231, 228, 224);
  --base-white-80: rgb(231, 228, 224, .8);
  --base-white-50: rgb(231, 228, 224, .5);
  --base-white-30: rgb(231, 228, 224, .3);
  --base-white-20: rgb(231, 228, 224, .2);
  --neutral-grey: rgba(219, 215, 210, 1);
  --neutral-biege: rgba(207, 201, 185, 1);
  --neutral-biege-50: rgba(207, 201, 185, 0.5);
  --accent-silver: rgba(197, 193, 192, 1);
  --accent-blue: rgba(92, 130, 147, 1);
  --accent-blue-light: rgba(116, 157, 176, 1);
  --accent-brown: rgba(120, 115, 103, 1);
  --accent-dark: rgba(50, 48, 44, 1);
  --line-on-white: #ADA5A3;
  --line-on-biege: rgba(186, 180, 166, 1);
  --line-on-grey: rgba(206, 199, 191, 1);
  --line-on-brown: rgba(144, 139, 125, 1);
  --line-on-black: rgba(99, 99, 94, 1);

  //фоны для анимаций изображений
  --image-background: #DBD7D2;
  --image-hero-background: #40403D;

  --default-transition: all var(--default-timing) var(--default-transition-function);
  --transition: var(--default-timing) var(--default-transition-function);
  --default-timing: .3s;
  --picture-timing: .4s;
  --text-timing: .7s;
  --text-line-delay: .1s;
  --long-timing: 1s;
  --teaser-transition-func: cubic-bezier(.25, .1, .25, 1);
  --default-transition-function: cubic-bezier(.25, .1, .25, 1);
}
