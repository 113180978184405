.about-content {
  &__list {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('large') {
  .about-content {
    grid-template-columns: var(--grid-column3) var(--grid-column8);
    grid-column-gap: calc(var(--grid-column) + (var(--grid-gap) * 2));
  }
}

@include respond('s-large') {
  .about-content {
    grid-template-columns: var(--grid-column4) var(--grid-column8);
    grid-column-gap: var(--grid-gap);
  }
}

@include respond-up('s-large') {
  .about-content {
    margin-top: 100px;
    position: relative;
    display: grid;

    &__sidebar-wrap {
      position: sticky;
      top: calc(var(--header-height) + 20px);
      height: fit-content;
    }

    &__list {
      row-gap: 100px;
    }
  }
}

@include respond-up('medium') {
  .about-content {

  }
}

@include respond('medium') {
  .about-content {
    margin-top: 70px;

    &__list {
      row-gap: 70px;
    }
  }
}

@include respond-down('medium') {
  .about-content {
    &__sidebar-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .about-content {
    margin-top: 40px;

    &__list {
      row-gap: 40px;
    }
  }
}