%text-medium {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 500;
  font-stretch: 110%;
  line-height: 110%;
}

@include respond-up("s-large") {
  %text-medium {
    --font-size: 30px;
  }
}

@include respond("medium") {
  %text-medium {
    --font-size: 25px;
  }
}

@include respond-down("small") {
  %text-medium {
    --font-size: 20px;
  }
}