.ya-share {
}

.ya-share2__badge {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  overflow: visible !important;
}

.ya-share2__item:not(.ya-share2__item_more):hover {
  opacity: 1 !important;
}

.ya-share2 {
  &__list.ya-share2__list_direction_horizontal {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--line-on-white);
  }

  &__link {
    display: flex !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
    justify-content: space-between !important;
    gap: 10px;
    padding: 9px 0 8px;
  }

  &__item {
    margin: 0;
    position: relative;
    border-bottom: 1px solid var(--line-on-white);
  }

  &__title {
    display: block;
  }
}

.ya-share2__container.ya-share2__container_color-scheme_whiteblack .ya-share2__badge, .ya-share2__container.ya-share2__container_color-scheme_whiteblack .ya-share2__mobile-popup-badge {
  background-color: transparent !important;
}

.ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
  margin: 0 !important;
}

.ya-share2__container_size_l .ya-share2__badge .ya-share2__icon {
  content: url('../../templates/_parts/icons/arrow-social.svg');
  background-image: unset !important;
}

.ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item > .ya-share2__link > .ya-share2__title {
  display: block !important;
  margin: 0 !important;
  font-weight: 700;
  font-family: var(--font);
  line-height: 140%;
  text-transform: uppercase;
}

.ya-share2__container_size_l {
  font-weight: 700;
  font-family: var(--font);
  line-height: 140%;
  text-transform: uppercase;
}

@include respond-up('s-large') {
  .ya-share {
    margin-top: 20px;
  }
  .ya-share2 {
    &__item {
      margin: 0;
      position: relative;
      border-bottom: 1px solid var(--line-on-white);

      &:after {
        content: " ";
        width: 100%;
        display: block;
        height: 1px;
        position: absolute;
        bottom: -1px;
        background: var(--base-black-100);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-out;
      }

      &:hover::after {
        background: var(--base-black-100);
        transform-origin: left;
        transition: transform 0.3s var(--teaser-transition-func);
        transform: scaleX(1);

      }
    }
  }
  .ya-share2__badge {
    width: 20px;
    height: 20px;
  }
}

@include respond-up('medium') {
  .ya-share {

  }
  .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item > .ya-share2__link > .ya-share2__title {
    font-size: 14px;
  }

  .ya-share2__container_size_l .ya-share2__badge .ya-share2__icon {
    width: 10px !important;
    height: 10px !important;
  }
}

@include respond('medium') {
  .ya-share {

  }

  .ya-share.ya-share2.ya-share2_inited {
    width: var(--grid-column2);
  }
}

@include respond-down('medium') {
  .ya-share {

  }
  .ya-share2__container_size_l .ya-share2__badge .ya-share2__icon {
    width: 10px !important;
    height: 10px !important;
  }
  .ya-share2__badge {
    width: 10px;
    height: 10px;
  }
}

@include respond-down('small') {
  .ya-share {
    margin-top: 10px;
  }

  .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item > .ya-share2__link > .ya-share2__title {
    font-size: 13px;
  }
}