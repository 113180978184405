.materials {
  &__list {
    display: flex;
    border-top: 1px solid var(--line-on-white);
  }

  &__item {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--accent-dark);
      z-index: 10;
      pointer-events: none;
      transition: opacity var(--transition);
      opacity: .5;
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.6) 100%);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__description {
    transition: height var(--transition);
  }

  &__description-text {
    transition: {
      property: height, opacity;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
    };
  }
}

@include respond-up('large') {
  .materials {
    &__description-text-content {
      padding-right: calc(var(--grid-column1) + var(--grid-gap));
    }
  }
}

@include respond-up('s-large') {
  .materials {
    &__list {
      margin-top: 30px;
      padding-top: 20px;
      column-gap: var(--grid-gap);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 20px;
        bottom: 0;
        left: calc(var(--grid-column6) + var(--grid-gap) / 2);
        border-left: 1px solid var(--line-on-white);
      }
    }

    &__item {
      flex: 0 0 var(--grid-column6);

      &::before {
        opacity: 0;
      }

      &:hover {
        &::before {
          opacity: .5;
        }
      }
    }

    &__picture {
      height: calc(var(--grid-column6) * 502 / 790);

      &::before {
        height: 209px;
      }
    }

    &__description-text-content {
      padding-top: 20px;
      display: flex;
    }
  }
}

@include respond-up('medium') {
  .materials {
    &__text-wrap {
      padding: 30px;
    }

    &__help-text {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .materials {
    &__list {
      margin-top: 20px;
      padding-top: 15px;
      row-gap: 30px;
    }

    &__item {
      &:not(:first-child) {
        &::after {
          top: -15px;
        }
      }
    }

    &__picture {
      height: calc(var(--grid-column6) * 492 / 774);

      &::before {
        height: 219px;
      }
    }

    &__description-text {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .materials {
    &__list {
      flex-direction: column;
    }

    &__item {
      &:not(:first-child) {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          border-bottom: 1px solid var(--line-on-white);
        }
      }
    }

    &__text-wrap {
      pointer-events: none;
    }
  }
}

@include respond-down('small') {
  .materials {
    &__list {
      margin-top: 12px;
      padding-top: 9px;
      row-gap: 18px;
    }

    &__item {
      &:not(:first-child) {
        &::after {
          top: -9px;
        }
      }
    }

    &__picture {
      height: calc(var(--grid-column4) * 364 / 338);

      &__before {
        top: 90px;
      }
    }

    &__text-wrap {
      padding: 18px;
    }

    &__description-text {
      margin-top: 10px;
    }

    &__help-text {
      margin-top: 10px;
    }
  }
}