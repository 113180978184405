.small-block-form {
  display: flex;
  background: var(--neutral-biege);

  &__title-wrap {
    display: flex;
    flex-direction: column;
    transition: opacity var(--transition);
  }

  &.request-form {
    &.success {
      .small-recall-form {
        &__success {
          &.request-form__success {
            height: 100%;
          }
        }

        &__policy {
          opacity: 0;
        }
      }

      .small-block-form {
        &__title-wrap {
          opacity: 0;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .small-block-form {
    margin-top: 100px;
    padding: 50px 40px;
    justify-content: space-between;

    &__title-wrap {
      row-gap: 20px;
      flex: 0 0 calc(var(--grid-column5) - 40px);
    }

    &__form-wrap {
      flex: 0 0 calc(var(--grid-column4) - 40px);
    }
  }
}

@include respond('medium') {
  .small-block-form {
    margin-top: 70px;
    padding: 40px 30px;
    row-gap: 40px;

    &__title-wrap {
      row-gap: 15px;
      width: calc(var(--grid-column5) - 30px);
    }
  }
}

@include respond-down('medium') {
  .small-block-form {
    flex-direction: column;
  }
}

@include respond-down('small') {
  .small-block-form {
    margin-top: 50px;
    padding: 20px 18px;
    row-gap: 30px;

    &__title-wrap {
      row-gap: 10px;
    }
  }
}