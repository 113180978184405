.project-preview {
  position: relative;

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  &__text {
    position: relative;
    display: inline;
    transition: color;
    transition-duration: var(--picture-timing);
    transition-timing-function: var(--default-transition-function);

    &.t-wrapper__inner {
      display: inline;
    }
  }

  &__wrapper-text {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__text-sub {
    color: var(--base-black-50);
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

@include respond-up('s-large') {
  .project-preview {
    &__picture {
      height: calc(var(--grid-column5) * 460 / 652);
      width: var(--grid-column5);
    }

    &._other {
      .project-preview {
        &__picture {
          height: calc(var(--grid-column4) * 362 / 513);
          width: var(--grid-column4);
        }
      }
    }

    &:hover {
      .project-preview {
        &__text {
          color: var(--accent-brown);
          //span {
          //  &:after {
          //    opacity: 1;
          //  }
          //}
        }

        &__wrapper-icon {
          opacity: 1;
        }
      }
    }

    &__wrap-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__wrapper-text {
      flex: 0 0 var(--grid-column3);
      margin-right: var(--grid-gap);
    }

    &__text {
      position: relative;

      span {
        &:after {
          position: absolute;
          content: '';
          height: 1px;
          width: 100%;
          background: var(--accent-brown);
          left: 0;
          bottom: 0;
          opacity: 0;
          transition: opacity;
          transition-duration: var(--default-timing);
          transition-timing-function: var(--default-transition-function);
        }
      }
    }

    &__wrapper-icon {
      transition: opacity;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &__wrapper-icon {
      opacity: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@include respond-up('medium') {
  .project-preview {
    &__wrap-bottom {
      margin-top: 15px;
    }
    &__text-sub{
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .project-preview {
    &__picture {
      height: calc(var(--grid-column4) * 357 / 506);
      width: var(--grid-column4);
    }

    &._other {
      .project-preview {
        &__picture {
          height: calc(var(--grid-column3) * 262 / 372);
          width: var(--grid-column3);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .project-preview {
    &__wrap-bottom{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
    }
  }
}

@include respond-down('small') {
  .project-preview {
    padding-bottom: 50px;

    &__picture {
      height: calc((var(--grid-column4) - var(--grid-gap)) * 357 / 506);
      width: calc(var(--grid-column4) - var(--grid-gap));
    }

    &._other {
      .project-preview {
        &__picture {
          height: calc(var(--grid-column2) * 113 / 160);
          width: var(--grid-column2);
        }
      }
    }

    &__wrap-bottom {
      margin-top: 8px;
    }
    &__text-sub{
      font-size: 13px;
    }

    &__wrapper-icon {
      display: none;
    }
  }
}