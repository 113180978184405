.recall-form {
  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__buttons,
  input[type="hidden"] {
    + div {
      button[type="submit"] {
        display: none;
      }
    }
  }

  &__success {
    display: flex;
    align-items: center;
    flex-direction: column;

    .t-wrapper__inner span {
      transform: translate3d(0, 110%, 0);
      transition-delay: .4s !important;
    }
  }

  &.success {
    .recall-form {
      &__success {
        .t-wrapper__inner span {
          transform: translate3d(0, 0, 0);
          transition-delay: 0s !important;
        }
      }
    }
  }

  &__success-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__success-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__success-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__success-title,
  &__success-subtitle {
    text-align: center;
  }
}

@include respond-up('s-large') {
  .recall-form {
    &__success-title-wrapper {
      width: var(--grid-column5);
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    &__fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }

    &__field {
      &_text {
        grid-column: span 2;
      }
    }

    &__buttons {
      margin-top: 20px;
      row-gap: 30px;
    }

    &__submit {
      width: 295px;
    }

    &__success-icon {
      width: 70px;
      height: 70px;
    }

    &__success-title-wrapper {
      margin-top: 50px;
      row-gap: 20px;
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    &__success-title-wrapper {
      width: var(--grid-column4);
    }
  }
}

@include respond-down('small') {
  .recall-form {
    &__fields {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &__buttons {
      margin-top: 10px;
      row-gap: 20px;
    }

    &__success-icon {
      width: 50px;
      height: 50px;
    }

    &__success-title-wrapper {
      margin-top: 40px;
      row-gap: 15px;
    }
  }
}