.content-header {
  overflow: hidden;

  //TODO: переписать. но пока так
  .content-header__pagination {
    --swiper-pagination-bullet-horizontal-gap: 8px;
  }

  .swiper-slide {
    position: relative;
    overflow: hidden;

    .content-header__picture {
      transition: transform var(--default-timing) var(--default-transition-function);
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 20;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--t);
      transition: background var(--default-timing) var(--default-transition-function);
    }

    &.swiper-slide-prev {
      &:before {
        background: var(--base-black-30);
      }
    }

    &.swiper-slide-active {
      .content-header__picture {
        transform: scale(1);
      }
    }

    &.swiper-slide-next {
      .content-header__picture {
        transform: scale(1.2);
      }
    }
  }

  &__date {
    display: flex;
  }

  &__pagination-wrapper {
    position: absolute;
    z-index: 80;
  }

  &.animate-out {
    .content-header {
      &__project-inscription {
        opacity: 1;
      }
    }
  }

  &__arrow-down {
    position: absolute;
  }

  &__project-inscription {
    opacity: 0;
    transition: opacity;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__labels {
    width: max-content;
    display: flex;
    flex-wrap: nowrap;
  }

  .label-button {
    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  &__hero-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__project-wrapper {
    margin-top: auto;
  }

  //TODO: переписать. но пока так
  &__hero-picture-wrapper {
    background: var(--image-hero-background) !important;
    overflow: hidden;
  }

  &__picture-wrapper {
    background: var(--image-background);
    overflow: hidden;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: unset;
    height: auto;
    min-width: 100%;
    min-height: 100%;
  }

  &__wrapper {
    position: relative;

    &:before {
      background: linear-gradient(0deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.6) 100%);
      top: 0;
    }

    &:after {
      background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.8) 100%);
      bottom: 0;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 10;
      height: clamp(calc(50vh - 37px), calc(50vh - 37px), 563px);
    }
  }

  &__wrapper-inner {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }

  &__slider-nav {
    position: absolute;
    z-index: 60;
  }

  &__slider-wrap {
    z-index: 50;
  }

  &__wrapper-inner {
    z-index: 40;
  }

  &__description {
    margin-top: auto;
  }

  &__about-logo-wrap {
    &.t-wrapper__inner {
      span {
        padding-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &__about-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .content-header {
    &__project-title,
    &__heading {
      width: var(--grid-column6);
    }
  }
}

@include respond-up('s-large') {
  .content-header {
    &__article-title {
      padding-top: 30px;
      width: var(--grid-column10);
    }

    &__date {
      margin-top: 60px;
    }

    &__image-wrap {
      margin-top: 40px;
    }

    &__slider-nav {
      bottom: 60px;
      right: var(--grid-spacer-and-indent);
    }

    &__pagination-wrapper {
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
    }

    &__arrow-down {
      bottom: 70px;
    }

    &__labels {
      margin-top: 30px;
    }

    &__hero-wrapper {
      padding-bottom: 70px;

      &_project {
        padding-bottom: 60px;
      }
    }

    &__description {
      margin-left: calc(var(--grid-column6) + var(--grid-gap));
      margin-right: calc(var(--grid-column1) + var(--grid-gap));
      width: var(--grid-column5);
    }

    &__wrapper,
    &__picture {
      height: clamp(10vh, calc(100vh - var(--header-height)), 1200px);
    }

    &__about-logo {
      width: var(--grid-column6);
      height: calc(var(--grid-column6) * 93 / 794);
    }
  }
}

@include respond('s-large') {
  .content-header {
    &__project-title,
    &__heading {
      width: var(--grid-column8);
    }
  }
}

@include respond-up('medium') {
  .content-header {
    &__project-title {
      margin-top: 20px;
    }

    &__pagination-wrapper {
      height: 8px;
    }

    &__description {
      padding-top: 30px;
    }

    &__heading {
      padding-top: 30px;
    }

    &__picture {
      &_mob {
        display: none;
      }
    }

    &__slider-nav {
      &_mob {
        display: none;
      }
    }

    &__slider {
      &_mob {
        display: none;
      }
    }

    &__pagination {
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .content-header {
    &__project-wrapper {
      &_slider-tab {
        padding-bottom: 60px;
      }
    }

    &__article-title {
      padding-top: 30px;
    }

    &__date {
      margin-top: 50px;
    }

    &__image-wrap {
      margin-top: 30px;
    }

    &__pagination-wrapper,
    &__slider-nav {
      bottom: 40px;
    }

    &__labels {
      margin-top: 20px;
    }

    &__wrapper,
    &__picture {
      height: clamp(10vh, calc(100vh - var(--header-height)), 1200px);
    }

    &__hero-wrapper {
      padding-bottom: 60px;
    }

    &__description,
    &__heading {
      padding-right: calc(var(--grid-column1) + var(--grid-gap));
    }

    &__about-logo {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 60 / 506);
    }
  }
}

@include respond-down('medium') {
  .content-header {
    &__slider-nav {
      left: var(--grid-spacer-and-indent);
    }

    &__pagination-wrapper {
      right: var(--grid-spacer-and-indent);
    }

    &__arrow-down {
      display: none;
    }
  }
}

@include respond-down('small') {
  .content-header {
    // когда есть обычный слайдер, а на мобилке только одна картинка
    &:has(.content-header__project-wrapper_slider-tab):not(:has(.content-header__project-wrapper_slider-mob)):not(:has(.content-header__project-wrapper_one-image)) {
      .content-header {
        &__project-wrapper {
          padding-bottom: 60px;
        }
      }
    }

    &__project-wrapper {
      &_slider-mob {
        padding-bottom: 60px;
      }
    }

    &__article-title {
      padding-top: 20px;
    }

    &__image-wrap {
      margin-top: 20px;
    }

    &__date {
      margin-top: 30px;
    }

    &__pagination-wrapper,
    &__slider-nav {
      bottom: 30px;
    }

    &__labels {
      margin-top: 15px;
    }

    &__project-title {
      margin-top: 15px;
    }

    &__wrapper,
    &__picture {
      //height: clamp(10dvh, calc(100dvh - var(--header-height)), 1920px);
      height: calc(100vw * 578/374);
    }

    &__hero-wrapper {
      padding-bottom: 30px;
    }

    &__description {
      padding-top: 20px;
    }

    &__heading {
      padding-top: 20px;
    }

    &__about-logo {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 40 / 338);
    }

    &__picture {
      &_desk {
        display: none;
      }
    }

    &__slider-nav {
      &_desk {
        display: none;
      }
    }

    &__slider {
      &_desk {
        display: none;
      }
    }

    &__pagination {
      &_desk {
        display: none;
      }
    }
  }
}