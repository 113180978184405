.contacts-city-card {
  &__head {
    width: 100%;
    border-bottom: 1px solid var(--line-on-white);
  }

  &__contacts-list {
    display: flex;
    flex-direction: column;
  }

  &__map-wrap {
    filter: grayscale(100%);
  }
}

@include respond-up('s-large') {
  .contacts-city-card {
    &__head {
      padding-bottom: 30px;
    }

    &__contacts-list {
      margin-top: 20px;
    }

    &__contacts-list-item {
      column-gap: var(--grid-gap);
    }

    &__contacts {
      padding-top: 20px;
    }

    &__map-wrap {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(-1 * (var(--grid-gap) / 2) );
        bottom: 0;
        border-left: 1px solid var(--line-on-white);
      }
    }

    &__contacts-list-item {
      &.smart-content {
        &._active {
          display: flex;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-city-card {
    &__map {
      height: 514px;
      width: var(--grid-column6);
    }

    &__contacts-list {
      row-gap: 40px;
    }
  }
}

@include respond('medium') {
  .contacts-city-card {
    &__head {
      padding-bottom: 20px;
    }

    &__content-wrap {
      margin-top: 20px;
    }

    &__map-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-down('small') {
  .contacts-city-card {
    &__head {
      padding-bottom: 12px;
    }
    &__map {
      height: 309px;
      width: var(--grid-column4);
    }

    &__content-wrap {
      margin-top: 10px;
    }

    &__map-wrap {
      margin-top: 30px;
    }

    &__contacts-list {
      row-gap: 20px;
    }
  }
}