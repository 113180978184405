.production-file {
  display: grid;
  gap: var(--grid-gap);

  &__link {
    border: 1px solid var(--line-on-white);
    display: flex;
    flex-direction: column;
    background: var(--base-white-100);
    transition: background, border;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-timing-function);
  }

  &__info {
    display: flex;
    width: 100%;
  }

  &__name{
    position: relative;
    >span{
      display: inline;
    }
  }

  &__file {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--neutral-grey);
    transition: background;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-timing-function);
    flex-shrink: 0;
  }

  &__wrap-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2px;
    width: 100%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__wrap-bottom-info {
    display: flex;
    gap: 10px;
  }
}

@include respond-up('s-large') {
  .production-file {
    margin-top: 60px;
    grid-template-columns: 1fr 1fr;

    &__link {
      &:hover {
        background: var(--neutral-grey);
        border: 1px solid var(--neutral-grey);

        .production-file {
          &__file {
            background: var(--base-white-100);
          }

          &__name {
            color: var(--accent-brown);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .production-file {
    &__link {
      width: var(--grid-column6);
      padding: 30px;
      gap: 26px;
      justify-content: space-between;
    }

    &__info {
      gap: 20px;
    }

    &__date {
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .production-file {
    margin-top: 50px;
  }
}

@include respond-down('medium') {
  .production-file {
    grid-template-columns: 1fr;
  }
}

@include respond-down('small') {
  .production-file {
    margin-top: 30px;

    &__link {
      width: var(--grid-column4);
      padding: 18px;
      gap: 29px;
    }

    &__info {
      gap: 14px;
    }

    &__date {
      &_mob {
        display: block;
      }

      &_des {
        display: none;
      }
    }
  }
}