.equipment-slider {
  &__slider {
    &.swiper {
      padding: 0 var(--grid-spacer-and-indent);
    }
  }

  &__item {
    &:not(:last-child) {
      &:before {
        width: calc(100% + var(--grid-gap));
      }
    }

    &:last-child {
      &:before {
        width: 100%;
      }
    }

    &::before {
      position: absolute;
      content: '';
      top: calc(-1 * var(--grid-gap) / 2);
      height: 1px;
      background: var(--line-on-black);
    }

    &:not(:first-child) {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        top: 0;
        height: 100%;
        width: 1px;
        background: var(--line-on-black);
        left: calc(-1 * var(--grid-gap) / 2);
      }
    }
  }

  &__image-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('s-large') {
  .equipment-slider {
    &__list {
      &.swiper-wrapper {
        padding-top: 20px;
      }
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column8);
      }
    }

    &__image-wrap {
      &::after {
        top: calc(66/1700 * 100vw);
        background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.6) 100%);
      }
    }

    &__picture {
      height: calc(var(--grid-column8) * 607/1067);
    }

    &__text-wrap {
      bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .equipment-slider {
    &__text-wrap {
      position: absolute;
      left: 30px;
      row-gap: 20px;
      width: calc(var(--grid-column4) - 30px);
    }

    &__title,
    &__description {
      color: var(--base-white-100);
    }
  }
}

@include respond('medium') {
  .equipment-slider {
    &__list {
      &.swiper-wrapper {
        padding-top: 15px;
      }
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column5);
      }
    }

    &__image-wrap {
      &::after {
        top: calc(79/834 * 100vw);
        background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.8) 100%);
      }
    }

    &__picture {
      height: calc(var(--grid-column5) * 414/640);
    }

    &__text-wrap {
      bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .equipment-slider {

  }
}

@include respond-down('small') {
  .equipment-slider {
    &__list {
      &.swiper-wrapper {
        padding-top: 9px;
      }
    }

    &__item {
      &.swiper-slide {
        width: calc(var(--grid-column4) - 18px);
      }
    }

    &__image-wrap {
      &::after {
        top: calc(99/374 * 100vw);
        background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.3) 100%);
      }
    }

    &__picture {
      height: calc((var(--grid-column4) - 18px) * 220/320);
    }

    &__text-wrap {
      row-gap: 10px;
      margin-top: 12px;
    }

    &__title,
    &__description {
      color: var(--neutral-biege);
    }
  }
}