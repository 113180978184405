.about-link-catalog {
  &__wrap {
    display: flex;
  }

  &__wrap-top {
    display: flex;
    flex-direction: column;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__wrap-description {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  &__wrap-bottom {
    display: flex;
    flex-direction: column;
  }

  &__arrow {
    display: inline-table;
    overflow: hidden;
  }
}

@include respond-up('s-large') {
  .about-link-catalog {
    margin-top: 150px;

    &__title {
      width: var(--grid-column8);
    }

    &__wrap {
      gap: var(--grid-gap);
      margin-top: 40px;
      padding-top: 20px;
      border-top: 1px solid var(--line-on-white);

      > :last-child {
        position: relative;

        &:after {
          position: absolute;
          top: 0;
          left: -20px;
          content: " ";
          width: 1px;
          height: 100%;
          background: var(--line-on-white);
        }
      }
    }

    &__link {
      //height: 873px;

      &:hover {
        .about-link-catalog {
          &__arrow {
            svg {
              animation: push-arrow-diagonal .55s;
            }
          }

          &__link-title-text {
            color: var(--accent-brown);
          }
        }

      }
    }

    &__link-title {
      width: var(--grid-column4);

      .link-underline__text {
        &:after {
          height: 2px;
          background: var(--accent-brown);
          bottom: 5px;
        }
      }
    }

    &__link-title-text {
      transition: color;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &__picture {
      height: calc(var(--grid-column6) * 501 / 790);
    }

    &__arrow {
      svg {
        width: 54px;
        height: 54px;
      }
    }

    &__wrap-bottom {
      margin-top: 100px;
    }
  }
}

@include respond-up('medium') {
  .about-link-catalog {
    &__link-title {
      margin-top: 30px;
    }

    &__link {
      width: var(--grid-column6);
    }

    &__description {
      width: var(--grid-column4);
    }

    &__picture {
      width: var(--grid-column6);
    }

    &__wrap-bottom {
      gap: 40px;
    }
  }
}

@include respond('medium') {
  .about-link-catalog {
    margin-top: 100px;

    &__wrap {
      margin-top: 30px;
      gap: 30px;
    }


    &__link {
      padding-top: 20px;
      //height: 759px;
    }


    &__picture {
      height: calc(var(--grid-column6) * 491 / 774);
    }

    &__link-title {
      width: var(--grid-column5);
    }

    &__arrow {
      svg {
        width: 42px;
        height: 42px;
      }
    }

    &__wrap-bottom {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .about-link-catalog {
    &__title {
      width: 100%;
    }

    &__wrap {
      flex-direction: column;
    }

    &__link {
      border-top: 1px solid var(--line-on-white);
    }

    &__link-title-text {
      color: var(--accent-brown);

      span {
        position: relative;

        &:after {
          background: var(--accent-brown);
          bottom: 5px;
          content: "";
          display: block;
          height: 2px;
          position: absolute;
          //transform: scaleX(1);
          //transform-origin: right;
          //transition: transform var(--default-timing) var(--teaser-transition-func);
          width: 100%;
        }
      }
    }
  }
}

@include respond-down('small') {
  .about-link-catalog {
    margin-top: 50px;

    &__link-title {
      margin-top: 20px;
    }

    &__wrap {
      margin-top: 20px;
      gap: 18px;
    }

    &__link {
      padding-top: 10px;
      //height: 475px;
      width: 100%;
      //width: var(--grid-column4);
    }

    &__link-title {
      width: 100%;
    }

    &__picture {
      height: calc(var(--grid-column4) * 214 / 338);
    }

    &__wrap-bottom {
      gap: 30px;
      margin-top: 30px;
    }

    &__wrap-description {
      gap: 30px;
    }

    &__arrow {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}