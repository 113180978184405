.contacts-item {
  &__email {
    color: var(--accent-blue);
    width: fit-content;
  }

  &__phone {
    width: var(--grid-column3);
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__social-items {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--line-on-white);
  }
}

@include respond-up('s-large') {
  .contacts-item {
    grid-row-gap: 70px;

    &__item {
      row-gap: 30px;
    }
  }
}

@include respond-up('medium') {
  .contacts-item {
    display: grid;
    grid-template-areas: 'address contacts'
                         'socials schedule';
    grid-template-columns: repeat(2, var(--grid-column3));
    grid-column-gap: var(--grid-gap);

    &__item {
      &__address {
        grid-area: address;
      }

      &__contacts {
        grid-area: contacts;
      }

      &__socials {
        grid-area: socials;
      }

      &__schedule {
        grid-area: schedule;
      }
    }

    &__social-items {
      width: var(--grid-column2);
    }

    &__city,
    &__address {
      width: var(--grid-column3);
    }
  }
}

@include respond('medium') {
  .contacts-item {
    grid-row-gap: 50px;

    &__item {
      row-gap: 20px;
    }
  }
}

@include respond-down('small') {
  .contacts-item {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    &__item {
      row-gap: 10px;
    }

    &__city,
    &__address {
      width: var(--grid-column4);
    }
  }
}