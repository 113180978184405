.t-opacity {
  opacity: 0;
}

.t-wrapper {
  &__inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: fit-content;
    margin-top: -0.12em;

    span {
      display: block;
      padding-top: 0.12em;
      margin-bottom: -0.12em;
      transition: transform;
      transition-duration: var(--text-timing);
      transition-timing-function: var(--default-transition-function);
    }

    @for $i from 2 through 50 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc(#{$i} * 0.1s);
        }
      }
    }
  }
}

.animate {
  .t-opacity {
    opacity: 1;
    transition: opacity;
    transition-duration: var(--text-timing);
    transition-timing-function: var(--default-transition-function);
  }

  .t-wrapper {
    &__inner {
      //opacity: 1;

      span {
        //opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.need-animation {
  .t-opacity {
    opacity: 0;
  }

  .t-wrapper {
    &__inner {
      opacity: 0;

      span {
        opacity: 0;
        transform: translateY(100%);
      }
    }
  }
}

.animate-out {
  .t-opacity {
    //opacity: 1;
  }

  .t-wrapper {
    &__inner {
      span {
        //opacity: 0.5;
      }
    }
  }
}