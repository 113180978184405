
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  text-transform: uppercase;
  font-stretch: 151%;
  font-style: normal;
  color: var(--primary-color);

  &_beige {
    color: var(--neutral-biege);
  }

  &_white {
    color: var(--base-white-100);
  }
}

h1, .h1 {
  font-weight: 700;
  line-height: 110%;
}

h2, .h2, h3, .h3 {
  font-weight: 700;
  line-height: 120%;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 700;
  line-height: 130%;
}

@include respond-up("large") {
  h1, .h1 {
    --h-size: clamp(53px, calc(0.0269 * 100vw + 14.2308px), 80px);
  }
  h2, .h2 {
    --h-size: clamp(35px, calc(0.0192 * 100vw + 7.3077px), 55px);
  }
  h3, .h3 {
    --h-size: clamp(27px, calc(0.0115 * 100vw + 10.3846px), 37px);
  }
  h4, .h4 {
    --h-size: clamp(22px, calc(0.0077 * 100vw + 10.9231px), 30px);
  }
  h5, .h5 {
    --h-size: 16px;
  }
  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond("s-large") {
  h1, .h1 {
    --h-size: 53px;
  }

  h2, .h2 {
    --h-size: 35px;
  }

  h3, .h3 {
    --h-size: 27px;
  }

  h4, .h4 {
    --h-size: 22px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond("medium") {
  h1, .h1 {
    --h-size: clamp(46px, calc(6.061vw - 0.5455px), 60px);
  }
  h2, .h2 {
    --h-size: clamp(33px, calc(3.030vw + 9.7273px), 42px);
  }
  h3, .h3 {
    --h-size: clamp(26px, calc(0.01739 * 100vw + 13.5px), 42px);
  }
  h4, .h4 {
    --h-size: 23px;
  }
  h5, .h5 {
    --h-size: 18px;
  }
  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down("small") {
  h1, .h1 {
    --h-size: clamp(27px, calc(5.455vw + 9.5455px), 40px);
  }
  h2, .h2 {
    --h-size: clamp(22px, calc(3.636vw +  10.3636px), 30px);
  }
  h3, .h3 {
    --h-size: clamp(19px, calc(3.636vw + 7.3636px), 25px);
  }
  h4, .h4 {
    --h-size: 17px;
  }

  h5, .h5 {
    --h-size: 15px;
  }

  h6, .h6 {
    --h-size: 14px;
  }
}
