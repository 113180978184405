.multi-uploadable-field {
  &__input-wrap {
    position: relative;
  }

  input[type='file'] {
    display: none;
  }

  label {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: center;
    position: relative;
    z-index: 10;
    cursor: pointer;
    width: 100%;
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: var(--accent-brown);
    border: 1px dashed var(--line-on-white);

    &::after {
      content: 'До 5 МБ. Не больше трёх изображений';
      font-family: var(--font);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      color: var(--base-black-50);
    }
  }
}

@include respond-up('s-large') {
  .multi-uploadable-field {

  }
}

@include respond-up('medium') {
  .multi-uploadable-field {
    label {
      font-size: 16px;
      padding: 30px calc(var(--grid-gap) + var(--grid-column)) 35px;
    }
  }
}

@include respond('medium') {
  .multi-uploadable-field {

  }
}

@include respond-down('medium') {
  .multi-uploadable-field {

  }
}

@include respond-down('small') {
  .multi-uploadable-field {
    label {
      font-size: 15px;
      padding: 20px 18px;
    }
  }
}