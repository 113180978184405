button {
  border: unset;
  padding: 0;
}

.button, button {
  --corner-size: 15px;
  --button-padding: 18px;
  --button-height: 56px;

  margin: 0;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  background: transparent;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  &__corner-border-inner {
    --line-color: var(--line-on-white);
    transition: background var(--transition);

    &_right {
      right: -4px;
      top: 0;
      left: unset;
      bottom: unset;
    }
  }

  &._brown {
    --color-text: var(--base-white-100);
    --background: var(--accent-brown);
    --background-hover: rgba(130, 124, 112, 1);
  }
  //
  //&._lined {
  //  --color-text: var(--base-black-100);
  //  --color-text-hover: (--base-white-100);
  //  --background: var(--base-white-100);
  //  --background-hover: var(--accent-brown);
  //}

  &._black {
    --color-text: var(--neutral-biege);
    --background: var(--base-black-100);
    --background-hover: rgba(58, 58, 55, 1);
  }

  &._gray {
    --color-text: var(--accent-brown);
    --background: var(--neutral-grey);
    --background-hover: rgba(214, 210, 206, 1);
  }

  &._white {
    --color-text: var(--accent-brown);
    --background: var(--base-white-100);
    --background-hover: rgba(235, 231, 226, 1);
  }

  &_small {
    --corner-size: 12px;
    --button-padding: 13px;
    --button-height: 46px;
  }

  &_lined {
    --corner-size: 12px;
    --button-padding: 0;

    .button {
      &__text-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid var(--line-on-white);
        border-bottom: 1px solid var(--line-on-white);
        background: var(--base-white-100);
        transition: {
          property: border-color, background;
          duration: var(--default-timing);
          timing-function: var(--default-transition-function);
        };
      }

      &__small-text {
        transition: color var(--transition);
      }

      &__left-corner {
        &::before {
          border-top: 1px solid var(--line-on-white);
          border-left: 1px solid var(--line-on-white);
          background: var(--base-white-100);
        }

        &::after {
          background: var(--base-white-100);
        }
      }

      &__right-corner {
        &::before {
          border-right: 1px solid var(--line-on-white);
          border-bottom: 1px solid var(--line-on-white);
          background: var(--base-white-100);
        }

        &::after {
          background: var(--base-white-100);
        }
      }

      &__left-corner,
      &__right-corner {
        &::after {
          border-color: var(--t);
        }
      }
    }
  }

  &__text {
    font-family: var(--font);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    transition: transform;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
    position: absolute;
    width: 100%;
    left: 0;
    color: var(--color-text);

    &_clone {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
    }
  }

  &__wrap-hidden {
    height: 17px;
    width: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
  }

  &__text-wrap {
    pointer-events: none;
    padding: var(--button-padding) 0;
    width: calc(100% - var(--corner-size) * 2);
    position: relative;
    height: var(--button-height);
    background: var(--background);
    transition: background-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__right-corner,
  &__left-corner {
    pointer-events: none;
    &::after,
    &::before {
      transition: background-color, border-color;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &_fill {
      &::after {
        background: var(--background);
      }
    }
  }

  &__right-corner,
  &__left-corner {
    &::before,
    &::after {
      content: "";
      position: absolute;
    }

    &::before {
      width: var(--corner-size);
      height: calc(100% - var(--corner-size));
      background-color: var(--background);
    }

    &::after {
      border-color: #fff #fff transparent;
      border-style: solid;
      border-bottom-color: transparent;
      border-left-color: var(--background);
      border-right-color: var(--background);
      border-top-color: var(--background);
    }
  }

  &__right-corner {
    &:before {
      bottom: 0;
      right: 0;
    }

    &:after {
      transform: rotate(-90deg);
      top: 0;
      right: 0;
      border-width: 0 0 var(--corner-size) var(--corner-size);
    }
  }

  &__left-corner {
    &:before {
      top: 0;
      left: 0;
    }

    &:after {
      bottom: 0;
      left: 0;
      border-width: 0 var(--corner-size) var(--corner-size) 0;
    }
  }

  &_border {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    padding: 0 18px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 66px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 12px);
      border-top: 1px solid black;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 27px;
      border-right: 1px solid black;
    }
  }

  &__border-inner {
    position: absolute;
    width: calc(12px * 1.414);
    height: 1px;
    transform: rotate(45deg) translate(3px, -3px);
    transform-origin: left;
    background: red;
    top: 0;
    right: 0;
  }

  &_header-recall {
    border-left: 1px solid var(--line-on-white);

    .button__text-wrap {
      width: 100%;
      padding: 27px 40px;
      height: 74px;
    }
  }
}

@include respond-up('s-large') {
  .button, button {
    &_header-recall {
      .button__text-wrap {
        padding: 27px 30px;
      }
    }

    &:hover {
      &:not(.button_lined) {
        .button {
          &__text {
            transform: translateY(20px);
          }

          &__text-wrap {
            background: var(--background-hover);
          }

          &__right-corner,
          &__left-corner {

            &::before {
              background-color: var(--background-hover);
            }

            &::after {
              border-left-color: var(--background-hover);
              border-right-color: var(--background-hover);
              border-top-color: var(--background-hover);
            }
          }
        }
      }

      .button {
        &__text {
          transform: translateY(20px);
        }

        &__text-wrap {
          background: var(--background-hover);
        }

        &__right-corner,
        &__left-corner {

          &::before {
            background-color: var(--background-hover);
          }

          &::after {
            border-left-color: var(--background-hover);
            border-right-color: var(--background-hover);
            border-top-color: var(--background-hover);
          }

          &_fill {
            &::after {
              background: var(--background-hover);
            }
          }
        }
      }
    }

    &_lined {
      &:hover {
        .button {
          &__text-wrap {
            border-color: var(--accent-brown);
            background: var(--accent-brown);
          }

          &__small-text {
            color: var(--base-white-100);
          }

          &__corner-border-inner {
            background: var(--accent-brown);
          }

          &__left-corner,
          &__right-corner {
            &::before {
              background: var(--accent-brown);
              border-color: var(--accent-brown);
            }

            &::after {
              border-left-color: var(--accent-brown);
              border-right-color: var(--accent-brown);
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .button, button {
    &_lined {
      --button-height: 40px;

      .button {
        &__text-wrap {
          padding: 8px 0 10px;
        }
      }
    }
  }
}

@include respond("small") {
  .button, button {
    --button-padding: 16px;
    --button-height: 50px;

    &_small {
      --button-padding: 14px;
    }

    &__text {
      font-size: 13px;
    }

    &__text-wrap {
      padding: 16px 0;
      height: 50px;
    }

    &_lined {
      --button-height: 34px;
      width: 189px;

      .button {
        &__text-wrap {
          padding: 6px 0 7px;
          height: var(--button-height);
        }

        &__corner-border-inner {
          transform: rotate(43deg) translate(0);
          width: 17px;

          &_left {
            bottom: 11px;
          }

          &_right {
            top: -1px;
          }
        }
      }
    }
  }
}