.index-projects {
  .picture-wrapper {
    display: flex;
    width: 100%;
  }

  &__wrapper-title {
    display: block;
    width: 100%;
  }

  &__top-list {
    display: grid;
    gap: var(--grid-gap);
  }

  &__top-list-item {
    display: block;
    position: relative;
  }

  &__list-link {
    display: flex;
    overflow: hidden;
    height: 100%;
    position: relative;

    &:after {
      background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.6) 100%);
      bottom: 0;
      content: "";
      //height: 50%;
      //height: clamp(calc(50vh - 37px), calc(50vh - 37px), 563px);
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 1;
    }
  }
}

@include respond-up('large') {
  .index-projects {
    &__top-list {
      .catalog-page {
        &__text,
        &__description-text {
          width: var(--grid-column4);
        }
      }
    }
  }
}

@include respond('s-large') {
  .index-projects {
    &__top-list {
      .catalog-page {
        &__text,
        &__description-text {
          width: var(--grid-column4);
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .index-projects {
    margin-top: 150px;

    &__top-list {
      display: grid;
      padding-top: 20px;
      margin-top: 30px;
      grid-template-columns: repeat(2, 1fr);
      border-top: 1px solid var(--line-on-white);
    }

    &__head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__wrap-button {
      &_des {
        display: block;
      }

      &_mob {
        display: none;
      }
    }

    &__list-link {
      &:after {
        height: 50%;
      }
    }

    &__title-first {
      --h-size: clamp(35px, calc(0.019 * 100vw + 7.308px), 55px);
      line-height: 110%;
    }

    &__top-list-item {
      height: calc(var(--grid-column6) * 386 / 790);

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        inset: auto;
        opacity: 0;
        background: rgba(50, 48, 44, 0.5);
        z-index: 30;
        transition: opacity var(--default-timing);
      }

      &:nth-child(1) {
        grid-row: 1/3;
        //height: calc(var(--grid-column6) * 926 / 900);
        height: 100%;

        &:after {
          position: absolute;
          content: '';
          //height: calc(var(--grid-column6) * 926 / 900);
          height: 100%;
          width: 1px;
          background: var(--line-on-white);
          right: -20px;
          top: 0;
        }
      }

      &:nth-child(3) {
        &:after {
          position: absolute;
          content: '';
          height: 1px;
          width: 100%;
          background: var(--line-on-white);
          top: -20px;
        }
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    &__title-next {
      --h-size: clamp(22px, calc(0.0076 * 100vw + 10.9230px), 30px);
      line-height: 130%;
    }
  }
}

@include respond-up('medium') {
  .index-projects {
    &__wrap-button {
      width: 189px;
    }
  }
}

@include respond('medium') {
  .index-projects {
    margin-top: 100px;

    &__top-list {
      padding-top: 15px;
      margin-top: 15px;
    }

    &__top-list-item {
      width: var(--grid-column6);
      height: calc(var(--grid-column6) * 712 / 1119);

      &:before {
        top: -15px;
      }
    }

    &__wrap-button {
      margin-top: 30px;
    }

    &__list-link {
      &:after {
        height: 40%;
      }
    }

    &__title-first,
    &__title-next {
      line-height: 120%;
      --h-size: clamp(26px, calc(0.0303 * 100vw + 2.7272px), 28px);
    }
  }
}

@include respond-down('medium') {
  .index-projects {
    &__top-list {
      grid-template-columns: 1fr;
    }

    &__wrap-button {
      &_des {
        display: none;
      }

      &_mob {
        display: block;
      }
    }

    &__top-list-item {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        background: var(--line-on-white);
      }
    }
  }
}

@include respond-down('small') {
  .index-projects {
    margin-top: 50px;

    &__top-list {
      padding-top: 9px;
      margin-top: 12px;
    }

    &__top-list-item {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 465 / 731);

      &:before {
        top: -9px;
      }
    }

    &__list-link {
      &:after {
        height: 80%;
      }
    }


    &__title-first,
    &__title-next {
      line-height: 120%;
      --h-size: clamp(19px, calc(0.0545 * 100vw + 0.5454px), 21px);
    }

    &__wrap-button {
      margin-top: 20px;
      width: 100%;

      .button_lined {
        width: 100%;
      }
    }
  }
}