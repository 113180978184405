.index-reviews {

}

@include respond-up('s-large') {
  .index-reviews {
    margin-bottom: 20px;
  }
}

@include respond-up('medium') {
  .index-reviews {

  }
}

@include respond('medium') {
  .index-reviews {
    margin-bottom: 100px;
  }
}

@include respond-down('medium') {
  .index-reviews {

  }
}

@include respond-down('small') {
  .index-reviews {
    margin-bottom: 50px;
  }
}