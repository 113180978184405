.about-employees {
  background: var(--base-black-100);
  &__list{
    display: grid;
    column-gap: var(--grid-gap);
    border-top: 1px solid var(--line-on-black);
  }

  &__item{
    display: flex;
    position: relative;
  }

  &__wrap-content{
    display: flex;
    flex-direction: column;
  }

  &__text-top{
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture{
    display: flex;
  }

  &__wrap-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__caption-middle,
  &__body-little{
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

@include respond-up('s-large') {
  .about-employees {
    margin-top: 150px;
    &__wrap{
      padding-bottom: 150px;
    }

    &__title{
      margin-top: 70px;
    }

    &__list{
      grid-template-columns: repeat(4, var(--grid-column3));
      row-gap: 80px;
      padding-top: 20px;
      margin-top: 30px;
    }

    &__item{
      &:nth-child(1){
        grid-column: 1/3;
      }
      &:nth-child(2){
        grid-column: 3/5;
        &:after{
          position: absolute;
          content: ' ';
          height: 100%;
          width: 1px;
          left: -20px;
          top: 0;
          background: var(--line-on-black);
        }
      }
      &:nth-child(1n + 4){
        &:after{
          position: absolute;
          content: ' ';
          height: 100%;
          width: 1px;
          left: -20px;
          top: 0;
          background: var(--line-on-black);
        }
      }
      &:nth-child(4n + 3){
        &:after{
          position: absolute;
          content: ' ';
          height: 1px;
          width: var(--grid-column12);
          top: -20px;
          left: 0;
          background: var(--line-on-black);
        }
      }
    }


  }
}

@include respond-up('medium') {
  .about-employees {
    &__wrap{
      padding-top: 30px;
    }
    &__item{
      &:nth-child(1),
      &:nth-child(2){
        .about-employees__wrap-content {
          flex-direction: row;
          column-gap: var(--grid-gap);
        }
        .about-employees__text-top{
          gap: 8px;
        }
      }
    }
    &__wrap-content{
      row-gap: 15px;
    }
    &__description{
      &_des{
        display: block;
      }
      &_mob{
        display: none;
      }
    }
    &__picture{
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 484 / 372);
    }

    &__caption-middle,
    &__body-little{
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .about-employees {
    margin-top: 100px;
    &__wrap{
      padding-bottom: 100px;
    }
    &__title{
      margin-top: 40px;
    }
    &__list{
      grid-template-columns: repeat(2, 1fr);
      row-gap: 55px;
      padding-top: 15px;
    }
    &__item{
      &:nth-child(1){
        grid-column: 1/3;
      }
      &:nth-child(2){
        grid-column: 1/3;
        &:after{
          position: absolute;
          content: ' ';
          height: 1px;
          width: var(--grid-column6);
          top: -15px;
          left: 0;
          background: var(--line-on-black);
        }
      }
      &:nth-child(2n + 4){
        &:after{
          position: absolute;
          content: ' ';
          height: 100%;
          width: 1px;
          left: -15px;
          top: 0;
          background: var(--line-on-black);
        }
      }
      &:nth-child(2n + 3){
        &:after{
          position: absolute;
          content: ' ';
          height: 1px;
          width: var(--grid-column6);
          top: -15px;
          left: 0;
          background: var(--line-on-black);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .about-employees {
    &__list{
      margin-top: 12px;
    }
  }
}

@include respond-down('small') {
  .about-employees {
    margin-top: 50px;

    &__wrap{
      padding-top: 18px;
      padding-bottom: 50px;
    }

    &__list{
      padding-top: 9px;
      grid-template-columns: 1fr 1fr;
      row-gap: 39px;
    }


    &__item{

      &:nth-child(1),
      &:nth-child(2){
        flex-direction: column;
        grid-column: 1/3;
        row-gap: 20px;
        .about-employees__text-top{
          gap: 4px;
        }
        .about-employees__wrap-content{
          flex-direction: row;
          gap: 18px;
        }
      }
      &:nth-child(2){
        &:after{
          position: absolute;
          content: ' ';
          height: 1px;
          width: var(--grid-column4);
          top: -9px;
          left: 0;
          background: var(--line-on-black);
        }
      }
      &:nth-child(2n + 4){
        &:after{
          position: absolute;
          content: ' ';
          height: 100%;
          width: 1px;
          left: -9px;
          top: 0;
          background: var(--line-on-black);
        }
      }
      &:nth-child(2n + 3){
        &:after{
          position: absolute;
          content: ' ';
          height: 1px;
          width: var(--grid-column4);
          top: -9px;
          left: 0;
          background: var(--line-on-black);
        }
      }
    }
    &__wrap-content{
      row-gap: 8px;
    }

    &__title{
      margin-top: 30px;
    }
    &__description{
      &_des{
        display: none;
      }
      &_mob{
        display: block;
      }
    }

    &__picture{
      width: var(--grid-column2);
      height: calc(var(--grid-column2) * 208 / 160);
    }

    &__caption-middle{
      font-size: 13px;
    }

    &__body-little{
      font-size: 15px;
    }
  }
}