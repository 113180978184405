.text {
  color: var(--primary-color);

  &_white {
    color: var(--base-white-100);
  }

  &_white-50 {
    color: var(--base-white-50);
  }

  &_base-black-50 {
    color: var(--base-black-50);
  }

  &_neutral-biege-50 {
    color: var(--neutral-biege-50);
  }

  &_neutral-biege {
    color: var(--neutral-biege);
  }

  &_blue {
    color: var(--accent-blue);
  }

  &_brown {
    color: var(--accent-brown);
  }

  &__main {
    @extend %text-main;
  }

  &__medium {
    @extend %text-medium;
  }

  &__large-phone {
    font-family: var(--font);
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  &__body,
  &__mini {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: 500;
    font-stretch: 110%;
  }

  &__error-page {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    font-stretch: 151%;
    font-weight: 500;
    line-height: 120%;
  }

  &__body,
  &__mini {
    line-height: 140%;
  }

  &__display {
    font-family: var(--h-font);
    font-size: var(--h-size);
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
  }

  &__subheader-big {
    font-family: var(--font);
    font-size: var(--h-size);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
  }

  &__subheader-middle {
    font-family: var(--font);
    font-size: var(--h-size);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
  }

  &__subheader-little {
    font-family: var(--font);
    font-size: var(--h-size);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.02em;
  }

  &__body-big {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
  }

  &__body-little {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  &__caption-big {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  &__caption-middle {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  &__caption-middle-thin {
    font-family: var(--font);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  &__caption-little {
    font-family: var(--font);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__question,
  &__caption-little-link {
    font-family: var(--font);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-underline-position: under;
    color: var(--accent-brown);
    text-underline-offset: -0.1em;
    position: relative;
    --underline-link-color: var(--accent-brown);
    --underline-link-offset: 0px;

    &:before {
      transform-origin: left;
      transition: transform var(--default-timing) var(--teaser-transition-func);
      transform: scaleX(1);
      content: '';
      height: 1px;
      display: block;
      position: absolute;
      background: var(--underline-link-color);
      bottom: var(--underline-link-offset);
    }
  }

  &__label {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
  }

  &__question {
    position: relative;
    display: flex;
    width: fit-content;
    height: fit-content;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      height: var(--icon-size);
      width: var(--icon-size);
      background-image: url('../images/svg/icon-i.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__house-feature {
    //styleName: house feature/mob;
    font-family: var(--font);
    font-weight: 600;
    line-height: 140%;
  }
}

@include respond-up("large") {
  .text {
    &__display {
      --h-size: clamp(68px, calc(0.0462 * 100vw + 1.5385px), 118px);
    }

    &__subheader-big {
      --h-size: clamp(32px, calc(1.538vw + 9.8462px), 50px);
    }

    &__subheader-middle {
      --h-size: clamp(24px, calc(1.538vw + 1.8462px), 35px);
    }

    &__subheader-little {
      --h-size: clamp(22px, calc(0.769vw + 10.9231px), 30px);
    }
  }
}

@include respond-up("s-large") {
  .text {
    &__large-phone {
      font-size: 50px;
    }

    &__question,
    &__caption-little-link {
      &:hover {
        &:before {
          transform: scaleX(0);
          transform-origin: right;
          transition: transform var(--default-timing) var(--teaser-transition-func);
        }
      }
    }
  }
}

@include respond("s-large") {
  .text {
    &__display {
      --h-size: 68px;
    }

    &__subheader-big {
      --h-size: 32px;
    }

    &__subheader-middle {
      --h-size: 24px;
    }

    &__subheader-little {
      --h-size: 22px;
    }
  }
}

@include respond-up("medium") {
  .text {
    &__label {
      font-size: 14px;
    }

    &__body-little {
      font-size: 16px;
    }

    &__caption-big {
      font-size: 16px;
    }

    &__caption-middle {
      font-size: 14px;
    }

    &__body-big {
      font-size: 18px;
    }

    &__caption-little-link {
      &:before {
        width: 100%;
      }
    }

    &__question {
      &:before {
        width: calc(100% - 36px);
      }

      --icon-size: 22px;
      padding-right: 36px;
    }

    &__body {
      --font-size: 18px;
    }

    &__mini {
      --font-size: 14px;
    }

    &__error-page {
      --font-size: 200px;
    }

    &__house-feature {
      font-size: 16px;
    }
  }
}

@include respond("medium") {
  .text {
    &__large-phone {
      font-size: 35px;
    }

    &__display {
      --h-size: clamp(56px, calc(6.061vw + 9.4545px), 70px);
    }

    &__subheader-big {
      --h-size: clamp(31px, calc(4.545vw - 3.9091px), 40px);
    }

    &__subheader-middle {
      --h-size: clamp(24px, calc(3.030vw + 0.7273px), 31px);
    }

    &__subheader-little {
      --h-size: 22px;
    }
  }
}

@include respond-down("medium") {
  .text {
  }
}

@include respond-down("small") {
  .text {
    &__large-phone {
      font-size: 27px;
    }

    &__question {
      --icon-size: 18px;
      padding-right: 28px;
      font-size: 12px;

      &:before {
        width: calc(100% - 28px);
      }
    }

    &__caption-little-link {
      &:before {
        width: 100%;
      }

      font-size: 12px;
    }

    &__body {
      --font-size: 16px;
    }

    &__mini {
      --font-size: 12px;
    }

    &__error-page {
      --font-size: 80px;
    }

    &__body-big {
      font-size: 16px;
    }

    &__body-little {
      font-size: 15px;
    }

    &__caption-big {
      font-size: 15px;
    }

    &__caption-middle {
      font-size: 13px;
    }

    &__label {
      font-size: 13px;
    }

    &__display {
      --h-size: clamp(35px, calc(9.091vw + 5.9091px), 60px);
    }

    &__subheader-big {
      --h-size: clamp(22px, calc(3.636vw + 10.3636px), 30px);
    }

    &__subheader-middle {
      --h-size: clamp(19px, calc(1.818vw + 13.1818px), 24px);
    }

    &__subheader-little {
      --h-size: 18px;
    }

    &__house-feature {
      font-size: 13px;
    }
  }
}
