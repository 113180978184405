.complete-projects {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
  }

  &__lists {
    display: grid;
    column-gap: var(--grid-gap);
    border-top: 1px solid var(--line-on-white);
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__lists-item {
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__wrapper-text {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__text-sub {
    color: var(--base-black-50);

    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

@include respond-up('s-large') {
  .complete-projects {
    &__wrapper {
      margin-top: 40px;
      margin-bottom: 150px;
    }

    &__lists {
      grid-template-columns: repeat(3, 1fr);
      padding-top: 20px;
      row-gap: 80px;
    }

    &__wrap-bottom {
      display: flex;
      justify-content: space-between;
    }

    &__wrapper-text {
      flex: 0 0 var(--grid-column3);
      margin-right: var(--grid-gap);
    }

    &__text {
      display: block;
      --underline-link-color: var(--accent-brown);
      --underline-link-offset: 0px;
      transition: color var(--default-timing) var(--teaser-transition-func);

      position: relative;
      width: fit-content;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        background: var(--underline-link-color);
        bottom: var(--underline-link-offset);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform var(--default-timing) var(--teaser-transition-func);
      }
    }

    &__wrapper-icon {
      transition: opacity;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &__lists-item {
      &:hover {
        .complete-projects {
          &__text {
            color: var(--accent-brown);

            &:after {
              transform-origin: left;
              transition: transform var(--default-timing) var(--teaser-transition-func);
              transform: scaleX(1);
            }
          }

          &__wrapper-icon {
            opacity: 1;
          }
        }
      }

      .complete-projects {
        &__picture {
          height: calc(var(--grid-column4) * 361 / 512);
          width: var(--grid-column4);
        }
      }

      &:nth-child(1) {
        grid-column: 1/3;
        grid-row: 1/3;

        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          right: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2) {
        grid-column: 3/4;
        grid-row: 1/3;
      }

      &:nth-child(3n + 3) {
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column12);
          height: 1px;
          left: 0;
          top: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(3n + 4),
      &:nth-child(3n + 5) {
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          left: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(12) {
        grid-column: 1/2;
        grid-row: 6/8;
      }

      &:nth-child(12n + 1) {
        .complete-projects {
          &__picture {
            height: calc(var(--grid-column8) * 574 / 894);
            width: var(--grid-column8);
          }
        }
      }

      &:nth-child(13) {
        grid-column: 2/4;
        grid-row: 6/8;
      }

      &:nth-child(14) {
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column12);
          height: 1px;
          left: 0;
          top: -20px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(1n + 15) {
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          left: -20px;
          background: var(--line-on-white);
        }
      }
    }

    &__wrapper-icon {
      opacity: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@include respond-up('medium') {
  .complete-projects {
    &__wrap-bottom {
      margin-top: 15px;
    }

    &__text-sub{
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .complete-projects {
    &__wrapper {
      margin-top: 30px;
      margin-bottom: 100px;
    }

    &__lists {
      padding-top: 15px;
      row-gap: 55px;
    }

    &__lists-item {
      &:nth-child(22n + 2),
      &:nth-child(22n + 4),
      &:nth-child(22n + 6),
      &:nth-child(22n + 8),
      &:nth-child(22n + 10),
      &:nth-child(22n + 12),
      &:nth-child(22n + 13),
      &:nth-child(22n + 15),
      &:nth-child(22n + 17),
      &:nth-child(22n + 19),
      &:nth-child(22n + 21),
      &:nth-child(22n + 23) {
        &:after {
          position: absolute;
          content: " ";
          height: 1px;
          left: 0;
          background: var(--line-on-white);
        }
      }

      &:nth-child(22n + 3),
      &:nth-child(22n + 5),
      &:nth-child(22n + 7),
      &:nth-child(22n + 9),
      &:nth-child(22n + 11),
      &:nth-child(22n + 14),
      &:nth-child(22n + 16),
      &:nth-child(22n + 18),
      &:nth-child(22n + 20),
      &:nth-child(22n + 22) {
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          top: 0;
          background: var(--line-on-white);
        }
      }
    }

    &__lists-item {
      &:nth-child(11n + 1) {
        grid-column: 1/3
      }

      &:nth-child(11n + 1) {
        .complete-projects {
          &__picture {
            height: calc(var(--grid-column6) * 496 / 774);
          }
        }
      }

      &:nth-child(22n + 2),
      &:nth-child(22n + 4),
      &:nth-child(22n + 6),
      &:nth-child(22n + 8),
      &:nth-child(22n + 10),
      &:nth-child(22n + 12),
      &:nth-child(22n + 13),
      &:nth-child(22n + 15),
      &:nth-child(22n + 17),
      &:nth-child(22n + 19),
      &:nth-child(22n + 21),
      &:nth-child(22n + 23) {
        &:after {
          width: var(--grid-column6);
          top: -15px;
        }
      }

      &:nth-child(22n + 3),
      &:nth-child(22n + 5),
      &:nth-child(22n + 7),
      &:nth-child(22n + 9),
      &:nth-child(22n + 11),
      &:nth-child(22n + 14),
      &:nth-child(22n + 16),
      &:nth-child(22n + 18),
      &:nth-child(22n + 20),
      &:nth-child(22n + 22) {
        &:after {
          left: -15px;
        }
      }
    }
    &__picture {
      height: calc(var(--grid-column3) * 262 / 372);
    }
  }
}

@include respond-down('medium') {
  .complete-projects {

    &__wrapper-icon {
      display: none;
    }

    &__lists {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include respond-down('small') {
  .complete-projects {
    &__wrapper {
      margin-top: 20px;
      margin-bottom: 50px;
    }

    &__wrap-bottom {
      margin-top: 8px;
    }

    &__lists {
      padding-top: 9px;
      row-gap: 29px;
    }

    &__lists-item {

      &:nth-child(11n + 1){
        grid-column: 1/3;
      }

      &:nth-child(2n + 2){
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column4);
          height: 1px;
          left: 0;
          top: -9px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2n + 3){
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          left: -9px;
          top: 0;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2n + 13){
        &:after {
          position: absolute;
          content: " ";
          width: var(--grid-column4);
          height: 1px;
          left: 0;
          top: -9px;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2n + 14){
        &:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 100%;
          left: -9px;
          top: 0;
          background: var(--line-on-white);
        }
      }

      &:nth-child(1n + 2) {
        .complete-projects {
          &__picture {
            width: var(--grid-column2);
            height: calc(var(--grid-column2) * 113 / 160);
          }
        }
      }

      &:nth-child(11n + 1) {
        .complete-projects {
          &__picture {
            width: var(--grid-column4);
            height: calc(var(--grid-column4) * 239 / 338);
          }
        }
      }

    }

    &__text-sub{
      font-size: 13px;
    }
  }
}