.project-card {
  display: flex;
  flex-direction: column;

  &__picture-wrapper {
    display: flex;
    width: 100%;
    background: var(--image-background);
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &__wrapper {
    //display: grid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }

  &__wrapper-left {
    //display: grid;
    //grid-template-columns: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 1 80%;
  }

  &__wrapper-right {
    flex: 1 0 auto;
    text-align: right;
  }

  &__wrapper-info {
    display: flex;
    flex-direction: row;

    :first-child {
      display: flex;
      padding-right: var(--link-padding);
    }

    :nth-child(2) {
      display: flex;
      position: relative;
      padding-left: calc(var(--link-padding) + 4px); // 4px - black square between space and storeys info

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        background: var(--primary-color);
        padding: 2px;
      }
    }
  }
}

@include respond-up('s-large') {
  .project-card {
    &__wrapper-left {
      margin-right: 40px;
    }

    &__project-name {
      transition: color;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
      position: relative;
    }

    &:hover {
      .project-card {
        &__project-name {
          color: var(--accent-brown);
        }
      }
    }

    &__picture-wrapper {
      height: calc(var(--grid-column6) * 502 / 790);
    }
  }

  .projects-page {
    &__item {
      &:nth-child(3n) {
        .project-card {
          &__picture-wrapper {
            height: calc(var(--grid-column12) * 746 / 1620);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .project-card {
    --link-padding: 10px;

    &__wrapper {
      margin-top: 20px;
      //grid-template-columns: var(--grid-column4) var(--grid-column2);
    }


    //&__wrapper-left {
    //  width: var(--grid-column4);
    //
    //  .t-wrapper{
    //    width: var(--grid-column4);
    //  }
    //}

    &__wrapper-info {
      margin-top: 4px;
    }
  }
}

@include respond('medium') {
  .project-card {
    &__wrapper-left {
      margin-right: 20px;
    }

    &__picture-wrapper {
      height: calc(var(--grid-column6) * 492 / 774);
    }
  }
}

@include respond-down('small') {
  .project-card {
    --link-padding: 8px;

    &__wrapper-left {
      margin-right: 60px;
    }

    &__picture-wrapper {
      height: calc(var(--grid-column4) * 215 / 338);
    }

    &__wrapper {
      margin-top: 12px;
    }

    &__wrapper-info {
      margin-top: 2px;

      .text__caption-big {
        //styleName: house feature/mob;
        font-size: 13px;
        font-weight: 600;
        line-height: 140%;

        &::before {
          top: unset;
          bottom: 5px;
          transform: unset;
        }
      }
    }
  }
}
