.input-file-list {
  display: flex;
  flex-wrap: wrap;

  .input-file-list-img {
    object-fit: cover;
  }

  .input-file-name {
    display: none;
  }

  .input-file-item {
    position: relative;
    display: flex;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(360deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.4) 100%);
    }
  }

  .input-file-list-remove {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 4px;
    right: 4px;
    z-index: 10;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 2px;
      background-color: var(--base-white-100);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@include respond-up('s-large') {
  .input-file-list {

  }
}

@include respond-up('medium') {
  .input-file-list {
    gap: 6px;

    .input-file-list-img {
      width: 137px;
      height: 90px;


    }

    .input-file-item {
      &::after {
        height: 47px;
      }
    }

    &:has(.input-file-item) {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

@include respond('medium') {
  .input-file-list {

  }
}

@include respond-down('medium') {
  .input-file-list {

  }
}

@include respond-down('small') {
  .input-file-list {
    gap: 4px;

    .input-file-list-img {
      width: 110px;
      height: 72px;
    }

    .input-file-item {
      &::after {
        height: 38px;
      }
    }

    &:has(.input-file-item) {
      margin-top: 10px;
    }
  }
}