.about-content-item {
  .images-block-small {
    &__two-images {
      display: flex;
      padding: 0;
      border: unset;

      .image_block_big {
        width: 100%;
        &::after {
          content: unset;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .about-content-item {
    &__image-wrap {
      width: var(--grid-column8);
    }

    &__text-wrap {
      margin-top: 70px;
      display: grid;
      grid-template-columns: var(--grid-column4) var(--grid-column3);
      grid-column-gap: calc(var(--grid-column) + (var(--grid-gap) * 2));
    }

    &__label {
      display: none;
    }

    &__title-link {
      &.images-block-small {
        &__video-link {
          &.link-underline {
            --underline-link-offset: 0;
          }
        }
      }

      &:hover {
        .images-block-small {
          &__vl-button {
            &:before {
              scale: 1.1;
              transform: translate(calc(-50% + 1px), -50%);
            }
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .about-content-item {
    &__text-wrap {
      width: var(--grid-column5);
    }

    &__label {
      margin-top: 20px;
    }

    &__title-wrap {
      margin-top: 30px;
    }

    &__text {
      margin-top: 20px;
    }

    .images-block-small__video-link-title-wrap {
      width: var(--grid-column4);
    }
  }
}

@include respond-down('small') {
  .about-content-item {
    .images-block-small {
      &__two-images {
        .image_block_big {
          .images-block-small__picture {
            height: calc(var(--grid-column4) * 226/338);
          }
        }
      }
    }

    &__label {
      margin-top: 18px;
    }

    &__title-wrap {
      margin-top: 20px;
    }

    &__text {
      margin-top: 10px;
    }

    .images-block-small__video-link-title-wrap {
      width: var(--grid-column3);
    }
  }
}