.policy {
  position: relative;
  display: flex;
  column-gap: 14px;

  input[type="checkbox"] {
    position: relative;
    flex: 0 0 auto;

    appearance: none;
    background-color: transparent;
    margin: 0;

    height: 20px;
    width: 20px;
    border: 2px solid var(--accent-brown);
    transition: var(--default-transition);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      background: var(--accent-brown);
      transition: opacity var(--default-timing) var(--default-transition-function);
      opacity: 0;
    }

    &:checked {
      &::before {
        opacity: 1;
      }
    }
  }

  //&__text{
  //  display: flex;
  //  align-items: center;
  //  flex-wrap: wrap;
  //}

  &__link {
    color: var(--accent-blue);
  }
}

@include respond-up('s-large') {
  .policy {
    &:hover {
      input[type="checkbox"] {
        &::before {
          opacity: .3;
        }
      }
      .policy {
      }
    }

    &__label {
      cursor: pointer;
    }

    &__link {
      display: inline-block;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: var(--accent-blue);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-out;
      }

      &:hover{
            &:after{
              transform-origin: left;
              transition: transform 0.3s var(--teaser-transition-func);
              transform: scaleX(1);
            }
      }
    }
  }
}

@include respond-up('medium') {
  .policy {
    &__label {
      align-items: center;
    }
  }
}

@include respond-down('small') {
  .policy {
    &__label {
      align-items: flex-start;
    }
  }
}