.modal-form-success {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--modal-content-padding);
  height: max-content;
  min-height: 100%;


  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__title,
  &__subtitle {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__subtitle,
  &__icon {
    transition: opacity, transform;
    transition-duration: .4s;
    transition-delay: .3s;
    transition-timing-function: var(--default-transition-function);
  }

  &.need-animation {
    .t-wrapper__inner span {
      transform: translate3d(0, 110%, 0);
    }

    .modal-form-success__icon,
    .modal-form-success__subtitle {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
  }
}

@include respond-up('s-large') {
  .modal-form-success {
    width: var(--modal-content-width);

    &__title-wrap,
    &__subtitle {
      width: var(--grid-column5);
    }
  }
}

@include respond-up('medium') {
  .modal-form-success {
    &__icon {
      width: 70px;
      height: 70px;
    }

    &__title-wrap {
      margin-top: 50px;
    }

    &__subtitle {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .modal-form-success {
    width: 100%;

    &__title-wrap,
    &__subtitle {
      width: var(--grid-column4);
    }
  }
}

@include respond-down('small') {
  .modal-form-success {
    &__icon {
      width: 50px;
      height: 50px;
    }

    &__title-wrap {
      margin-top: 40px;
    }

    &__subtitle {
      margin-top: 15px;
    }
  }
}