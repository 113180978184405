@include respond-up('s-large') {
  .page-block {
    margin-top: 150px;
  }
}

@include respond('medium') {
  .page-block {
    margin-top: 100px;
  }
}

@include respond-down('small') {
  .page-block {
    margin-top: 50px;
  }
}