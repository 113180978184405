@include respond-up('s-large') {
  .link-underline {
    display: flex;
    --underline-link-color: var(--accent-brown);
    --underline-link-offset: 0px;

    &__text-color {
      transition: color var(--default-timing) var(--teaser-transition-func);
    }

    &__text {
      position: relative;
      display: block;
      width: fit-content;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        background: var(--underline-link-color);
        bottom: var(--underline-link-offset);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform var(--default-timing) var(--teaser-transition-func);
      }
    }

    .link-underline {
      &__text {
        &.underline {
          &::after {
            transform-origin: left;
            transition: transform var(--default-timing) var(--teaser-transition-func);
            transform: scaleX(1);
          }
        }
      }
    }


    &.underline {
      .link-underline {
        &__text-color {
          color: var(--underline-link-color);
        }

        &__text {
          &::after {
            transform-origin: left;
            transition: transform var(--default-timing) var(--teaser-transition-func);
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .link-underline {
    &__text {
      &_fat-line {
        &::after {
          height: 3px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .link-underline {
    &__text {
      &_fat-line {
        &::after {
          height: 2px;
        }
      }
    }
  }
}