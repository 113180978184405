// это мелкий блок с двумя картинками и видео
.images-block-small {
  &__wrapper {
    position: relative;
    margin-top: var(--grid-gap);

    &._grad {
      &:before {
        content: '';
        z-index: 70;
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 50%;
        background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.5) 100%);
      }
    }
  }

  &__vl-button {
    background-color: var(--base-white-100);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent transparent var(--accent-brown);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: scale, transform;
      transform-origin: top;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-timing-function);
    }
  }

  &__two-images {
    .image_block_big {
      position: relative;

      &._grad {
        &:before {
          content: '';
          z-index: 70;
          position: absolute;
          width: 100%;
          bottom: 0;
          height: 50%;
          background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.5) 100%);
        }
      }
    }
  }

  &__video-link {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
  }

  &__link {
    display: flex;
    background-color: var(--neutral-grey);
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    transition: transform;
    transition-duration: var(--picture-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__two-images {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  .image_block_big {
    position: relative;
  }

  &__video-link-title-wrap {
    flex-wrap: wrap;
    width: var(--grid-column4);
  }

  &__video-link-title {
    &.link-underline__text {
      &::after {
        background: var(--base-white-100);
      }
    }
  }
}

@include respond-up('large') {
  .images-block-small {
    &__lower-description {
      width: var(--grid-column3);
    }
  }
}

@include respond-up('s-large') {
  .images-block-small {
    &__video-link {
      &.link-underline {
        --underline-link-color: var(--base-white-100);
        --underline-link-offset: -6px;

        .link-underline__text {
          margin-top: var(--underline-link-offset);
        }
      }
    }

    &__images-wrapper {
      margin-top: 30px;
    }

    &__lower-description {
      margin-top: calc(var(--grid-gap) / 2);
    }

    &__two-images {
      padding-top: 20px;
      border-top: 1px solid var(--line-on-white);
      grid-template-rows: auto 1fr;

      &._left {
        grid-template-columns: var(--grid-column4) var(--grid-column8);
        grid-template-areas:
              'small-pict big'
              'description big';

        .image_block_big {
          &:after {
            left: calc(-1 * var(--grid-gap) / 2);
          }
        }
      }

      &._right {
        grid-template-columns: var(--grid-column8) var(--grid-column4);
        grid-template-areas:
              'big small-pict'
              'big description';

        .image_block_big {
          &:after {
            right: calc(-1 * var(--grid-gap) / 2);
          }
        }
      }

      .image_block_small {
        grid-area: small-pict;

        .images-block-small__picture {
          height: calc(409 / 513 * var(--grid-column4));
        }
      }

      .image_block_big {
        grid-area: big;

        .images-block-small__picture {
          height: calc(714 / 1067 * var(--grid-column8));
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          width: 1px;
          height: 100%;
          background: var(--line-on-white);
        }
      }
    }

    &__image-link {
      &:hover {
        .images-block-small {
          &__image {
            transform: scale(1.05);
          }
        }
      }
    }

    &__video-link {
      &:hover {
        .images-block-small {
          &__vl-button {
            &:before {
              scale: 1.1;
              transform: translate(calc(-50% + 1px), -50%);
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .images-block-small {
    &__video-link {
      left: 30px;
      bottom: 30px;
    }

    &__vl-button {
      flex: 0 0 60px;
      height: 60px;
      margin-right: 25px;

      &:before {
        margin-left: 2px;
        border-width: 10.5px 0 10.5px 18px;
      }
    }
  }
}

@include respond('medium') {
  .images-block-small {
    &__images-wrapper {
      margin-top: 20px;
    }

    &__two-images {
      padding-top: 15px;
      border-top: 1px solid var(--line-on-white);
      grid-template-columns: var(--grid-column3) var(--grid-column3);

      &._left {
        grid-template-areas:
              'big big'
              'small-pict description';
      }

      &._right {
        grid-template-areas:
              'big big'
              'description small-pict';

        .image_block_big {
          &:after {
            //right: calc(-1 * var(--grid-gap) / 2);
          }
        }

        .image_block_small {

          &:after {
            content: '';
            position: absolute;
            top: 0;
            width: 1px;
            height: 100%;
            background: var(--line-on-white);
            left: calc(-1 * var(--grid-gap) / 2);
          }

        }
      }

      .image_block_small {
        grid-area: small-pict;
        position: relative;

        .images-block-small__picture {
          height: calc(409 / 513 * var(--grid-column3));
        }
      }

      .image_block_big {
        grid-area: big;

        .images-block-small__picture {
          height: calc(714 / 1067 * var(--grid-column6));
        }

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: var(--line-on-white);
          bottom: calc(-1 * var(--grid-gap) / 2);
          left: 0;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .images-block-small {
    &__two-images {
      grid-row-gap: var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .images-block-small {
    &__vl-button {
      flex: 0 0 46px;
      height: 46px;
      margin-right: 14px;

      &:before {
        margin-left: 1px;
        border-width: 8px 0 8px 14px;
      }
    }

    &__video-link {
      left: 18px;
      bottom: 16px;
    }

    &__images-wrapper {
      margin-top: 12px;
    }

    &__two-images {
      padding-top: 9px;
      grid-template-areas:
              'big'
              'small-pict'
              'description';

      .image_block_small {
        grid-area: small-pict;
        position: relative;

        .images-block-small__picture {
          height: calc(489 / 731 * var(--grid-column4));
        }

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: var(--line-on-white);
          top: calc(-1 * var(--grid-gap) / 2);
        }
      }

      .image_block_big {
        grid-area: big;

        .images-block-small__picture {
          height: calc(584 / 731 * var(--grid-column4));
        }

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: var(--line-on-white);
          top: calc(-1 * var(--grid-gap) / 2);
        }
      }
    }

    &__video-link-title-wrap {
      width: calc(var(--grid-column3) - 7px);
    }
  }
}