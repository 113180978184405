.reviews-slider {
  border-top: 1px solid var(--line-on-white);

  &__item {
    &.swiper-slide {
      height: auto;
      display: grid;
      grid-auto-rows: max-content;

      .reviews-slider {
        &__text-inner {
          @for $i from 1 through 30 {
            &:nth-child(#{$i}) {
              span {
                transition-delay: calc(.3s + #{$i} * .05s);
              }
            }
          }
        }
      }
    }

    &.swiper-slide-active {
      .reviews-slider {
        &__person,
        &__images-container,
        &__original,
        &__full-link,
        &__text-wrap {
          opacity: 1;
        }

        &__text-inner {
          span {
            transform: translate3d(0, 0, 0);
          }

          @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
              span {
                transition-delay: calc((#{$i} * .05s));
              }
            }
          }
        }
      }
    }

    &:nth-child(4n + 1) {
      .reviews__title-square-name {
        background: var(--base-black-100);

        &::first-letter {
          color: var(--neutral-biege);
        }
      }
    }

    &:nth-child(4n + 2) {
      .reviews__title-square-name {
        background: var(--accent-blue);

        &::first-letter {
          color: var(--base-white-100);
        }
      }
    }

    &:nth-child(4n + 3) {
      .reviews__title-square-name {
        background: var(--neutral-biege);

        &::first-letter {
          color: var(--accent-brown);
        }
      }
    }

    &:nth-child(4n + 4) {
      .reviews__title-square-name {
        background: var(--accent-brown);

        &::first-letter {
          color: var(--base-white-100);
        }
      }
    }
  }

  &__images-container {
    position: relative;
    width: var(--grid-column2);

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(50, 48, 44, 0.2);
      pointer-events: none;
    }
  }

  &__image-wrap {
    &:not(:first-child) {
      display: none;
    }
  }

  &__images-count {
    position: absolute;
    z-index: 2;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__pagination {
    &.swiper-pagination-fraction {
      position: absolute;
      width: fit-content;
      right: 0;
      bottom: 0;
      left: unset;
    }
  }

  &__item-title {
    grid-area: person;
  }

  &__images-container {
    grid-area: image;
  }

  &__text-wrap {
    grid-area: text;
  }

  &__original {
    grid-area: original;

    .reviews__file {
      margin-top: 0;
    }
  }

  &__full-link {
    display: flex;
  }

  &__person,
  &__images-container,
  &__original,
  &__full-link,
  &__text-wrap {
    opacity: 0;
    transition: opacity var(--transition);
  }

  &__text-inner {
    span {
      transform: translate3d(0, 110%, 0);
    }
  }

  &__file-name-wrap {
    display: block;
  }
}

@include respond-up('s-large') {
  .reviews-slider {
    margin-top: 30px;
    padding: 20px 0 0;

    &__item {
      &.swiper-slide {
        grid-template-areas: 'person text .'
                             'image text .'
                             'image . .'
                             'image . .'
                             'original . .';
        grid-template-columns: var(--grid-column6) var(--grid-column5) var(--grid-column);
        grid-column-gap: var(--grid-gap);
      }
    }

    &__images-container {
      margin-top: 70px;
    }

    &__original {
      margin-top: 100px;
    }

    &__full-link {
      margin-top: 20px;
    }

    &__file-name-wrap {
      width: var(--grid-column5);
    }
  }
}

@include respond-up('medium') {
  .reviews-slider {

    &__images-count {
      left: 14px;
      bottom: 14px;
    }

    &__images-container {
      height: calc(var(--grid-column2) * 156 / 237);
    }
  }
}

@include respond('medium') {
  .reviews-slider {
    margin-top: 20px;
    padding: 20px 0;

    &__item {
      &.swiper-slide {
        grid-template-columns: var(--grid-column5);
      }
    }

    &__text-wrap {
      margin-top: 40px;
    }

    &__original {
      margin-top: 30px;
    }

    &__images-container {
      margin-top: 50px;
    }

    &__full-link {
      margin-top: 15px;
    }

    &__file-name-wrap {
      width: var(--grid-column4);
    }
  }
}

@include respond-down('medium') {
  .reviews-slider {
    border-bottom: 1px solid var(--line-on-white);
    &__item {
      &.swiper-slide {
        grid-template-areas: 'person'
                             'text'
                             'original'
                             'image';
      }
    }
  }
}

@include respond-down('small') {
  .reviews-slider {
    margin-top: 12px;
    padding: 10px 0;

    &__images-count {
      left: 10px;
      bottom: 10px;
    }

    &__images-container {
      height: calc(var(--grid-column2) * 105 / 160);
    }

    &__text-wrap {
      margin-top: 20px;
    }

    &__original {
      margin-top: 20px;
    }

    &__images-container {
      margin-top: 30px;
    }

    &__full-link {
      margin-top: 10px;
    }

    &__file-name-wrap {
      width: var(--grid-column3);
    }
  }
}