.burger {
  display: flex;

  &._opened {
    .burger {
      &__rows {
        &::before,
        &::after {
          left: 50%;
        }

        &::before {
          top: 50%;
          transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, 45deg);
        }

        &::after {
          bottom: 50%;
          transform: translate3d(-50%, 50%, 0) rotate3d(0, 0, 1, -45deg);
        }
      }
    }
  }

  &__rows {
    display: flex;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      border-bottom: 2px solid var(--base-black-100);
      transform-origin: center;
      left: 0;
      transition: {
        property: top, bottom, left, transform, width;
        duration: .3s;
        timing-function: var(--default-transition-function);
      };
    }

    &::before {
      top: 13px;
    }

    &::after {
      bottom: 11px;
    }
  }
}

@include respond-up('s-large') {
  .burger {
    transition: background-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);

    &:hover {
      background-color: var(--image-background);
    }
  }
}

@include respond-up('medium') {
  .burger {
    padding: 20px 25px;
    border-right: 1px solid var(--line-on-white);

    &._opened {
      .burger {
        &__rows {
          &::before,
          &::after {
            width: 26px;
          }
        }
      }
    }

    &__rows {
      width: 34px;
      height: 34px;

      &::before {
        top: 12px;
      }

      &::after {
        bottom: 10px;
      }
    }
  }
}

@include respond('medium') {
  .burger {

  }
}

@include respond-down('medium') {
  .burger {

  }
}

@include respond-down('small') {
  .burger {
    padding: 14px 18px;
    background: var(--accent-brown);
    margin-bottom: -1px;
    border-bottom: 1px solid var(--accent-brown);

    &._opened {
      .burger {
        &__rows {
          &::before,
          &::after {
            width: 22px;
          }
        }
      }
    }

    &__rows {
      width: 22px;
      height: 22px;

      &::before,
      &::after {
        border-color: var(--neutral-biege);
      }

      &::before {
        top: 7px;
      }

      &::after {
        bottom: 5px;
      }
    }
  }
}