.video-block {
  &__picture {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    transition: transform;
    transition-duration: var(--picture-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__wrapper {
    position: relative;
    margin-top: var(--grid-gap);
    display: flex;

    &._grad {
      &:before {
        content: '';
        z-index: 70;
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 50%;
        background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.5) 100%);
      }
    }
  }

  &__video-link {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
  }

  &__vl-button {
    background-color: var(--base-white-100);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent transparent var(--accent-brown);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: scale, transform;
      transform-origin: top;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-timing-function);
    }
  }

  &__link {
    display: flex;
    background-color: var(--neutral-grey);
  }
}

@include respond-up('s-large') {
  .video-block {
    &__picture {
      height: calc(747 / 1620 * var(--grid-column12));
    }

    &__video-link {
      &.link-underline {
        --underline-link-color: var(--base-white-100);
        --underline-link-offset: -6px;

        .link-underline__text {
          margin-top: var(--underline-link-offset);
        }
      }

      &:hover {
        .video-block {
          &__vl-button {
            &:before {
              scale: 1.1;
              transform: translate(calc(-50% + 1px), -50%);
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .video-block {
    &__vl-button {
      flex: 0 0 60px;
      height: 60px;
      margin-right: 25px;

      &:before {
        margin-left: 2px;
        border-width: 10.5px 0 10.5px 18px;
      }
    }

    &__video-link {
      left: 30px;
      bottom: 30px;
    }
  }
}

@include respond('medium') {
  .video-block {
    &__picture {
      height: calc(407 / 774 * var(--grid-column6));
    }
  }
}

@include respond-down('small') {
  .video-block {
    &__picture {
      height: calc(216 / 338 * var(--grid-column4));
    }

    &__vl-button {
      flex: 0 0 46px;
      height: 46px;
      margin-right: 14px;

      &:before {
        margin-left: 1px;
        border-width: 8px 0 8px 14px;
      }
    }

    &__video-link {
      left: 18px;
      bottom: 16px;
    }
  }
}