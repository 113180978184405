.partners-numbers {
  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__index {
    color: var(--accent-brown);
  }
}

@include respond-up('s-large') {
  .partners-numbers {
    padding-top: 20px;

    &__list {
      grid-gap: var(--grid-gap);

      &_small-grid {
        grid-template-columns: repeat(4, var(--grid-column3));
      }

      &_wide-grid {
        grid-template-columns: repeat(3, var(--grid-column4));
      }
    }

    &__item {
      &:not(:first-child) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: calc(-1 * (var(--grid-gap) / 2));
          border-left: 1px solid var(--line-on-white);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .partners-numbers {
    &__list {
      display: grid;
    }

    &__item {
      row-gap: 32px;
      grid-column: span 1;
    }
  }
}

@include respond('medium') {
  .partners-numbers {
    padding-top: 15px;

    &__list {
      grid-column-gap: var(--grid-gap);
      grid-row-gap: 50px;
      grid-template-columns: repeat(2, var(--grid-column3));
    }

    &__item {
      padding-top: 15px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        border-bottom: 1px solid var(--line-on-white);
        width: calc(100% + (var(--grid-gap) / 2));
      }

      &:nth-child(even) {
        &::before {
          left: calc(-1 * (var(--grid-gap) / 2));
          right: 0;
        }

        &::after {
          content: '';
          position: absolute;
          top: 15px;
          bottom: 0;
          left: calc(-1 * (var(--grid-gap) / 2));
          border-left: 1px solid var(--line-on-white);
        }
      }

      &:nth-child(odd) {
        &::before {
          right: calc(-1 * (var(--grid-gap) / 2));
          left: 0;
        }
      }

      &:first-child,
      &:nth-child(2) {
        padding-top: 0;
        &::before {
          content: unset;
        }
      }

      &:nth-child(2) {
        &::after {
          top: 0;
        }
      }
    }
  }
}

@include respond-down('small') {
  .partners-numbers {
    padding-top: 10px;

    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    &__item {
      row-gap: 20px;

      &:not(:first-child) {
        padding-top: 10px;
        border-top: 1px solid var(--line-on-white);
      }
    }
  }
}
