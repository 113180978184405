.project-payment {
  display: flex;

  &__list-wrap-text {
    display: flex;
    flex-direction: column;
  }

  &__list-item {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--line-on-white);
    counter-increment: linksCounter;

    &:before {
      width: 60px;
      height: 60px;
      background: var(--neutral-grey);
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      position: absolute;
      content: counter(linksCounter, decimal-leading-zero);
      font-family: var(--font);
      font-style: normal;
      font-weight: 700;
      color: var(--accent-brown);
      text-transform: uppercase;
    }
  }

  &__list {
    border-top: 1px solid var(--line-on-white);
  }

  &__list-text-info {
    color: var(--base-black-50);
  }
}

@include respond-up('s-large') {
  .project-payment {
    flex-direction: row;
    margin-top: 150px;
    gap: var(--grid-gap);

    &__list-item {
      &:before {
        top: 35px;
      }
    }

    &__title {
      width: var(--grid-column6);
    }

    &__list {
      width: var(--grid-column6);
    }
  }
}

@include respond-up('medium') {
  .project-payment {
    &__list-item {
      padding: 30px calc(var(--grid-gap) + var(--grid-column1)) 35px 90px;

      &:before {
        width: 60px;
        height: 60px;
        font-size: 14px;
      }
    }

    &__list-text {
      &:before {
        font-size: 14px;
      }
    }

    &__list-text-description {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .project-payment {
    margin-top: 100px;

    &__list-item {
      &:before {
        top: 32px;
      }
    }

    &__list {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .project-payment {
    flex-direction: column;

  }
}

@include respond-down('small') {
  .project-payment {
    margin-top: 50px;

    &__list-item {
      padding: 18px 0 20px var(--grid-column1);

      &:before {
        width: 52px;
        height: 52px;
        font-size: 13px;
        top: 18px;
      }
    }

    &__list-text {
      &:before {
        font-size: 13px;
      }
    }

    &__list-text-description {
      margin-top: 8px;
    }

    &__list {
      margin-top: 12px;
    }
  }
}