.projects-page {
  &__description-block {
    border-top: 1px solid var(--line-on-white);
    display: grid;
  }

  &__list {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__item {
    //overflow: hidden;
  }
}

@include respond-up('s-large') {
  .projects-page {
    &__description-block {
      padding-top: 70px;
      padding-bottom: 150px;
      grid-template-columns: repeat(2, var(--grid-column5));
      grid-column-gap: calc(2 * var(--grid-gap) + var(--grid-column1));
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 80px;
      margin-top: 40px;
      padding-bottom: 150px;
    }

    &__item {
      width: var(--grid-column6);

      &:nth-child(3n) {
        width: var(--grid-column12);
        grid-column: 1/3;
      }

      &:nth-child(3n+1) {
        padding-top: 20px;
        position: relative;

        &:before {
          content: '';
          height: 1px;
          position: absolute;
          width: var(--grid-column12);
          background-color: var(--line-on-white);
          left: 0;
          top: 0;
        }
      }

      &:nth-child(3n+2) {
        padding-top: 20px;
        position: relative;

        &:before {
          content: '';
          height: calc(100% - 20px);
          position: absolute;
          width: 1px;
          background-color: var(--line-on-white);
          left: -20px;
          top: 20px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .projects-page {
  }
}

@include respond('medium') {
  .projects-page {
    &__description-block {
      padding-top: 40px;
      padding-bottom: 100px;
      padding-right: calc(var(--grid-gap) + var(--grid-column1));
      grid-row-gap: 25px;
    }

    &__description {
      margin-top: 25px;
    }

    &__list {
      padding-bottom: 100px;
      margin-top: 30px;
      grid-row-gap: 40px;
    }

    &__item {
      padding-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .projects-page {
    &__item {
      border-top: 1px solid var(--line-on-white);
    }
  }
}

@include respond-down('small') {
  .projects-page {
    &__description-block {
      padding-top: 30px;
      padding-bottom: 50px;
      grid-row-gap: 15px;
      width: var(--grid-column4);
      grid-template-columns: 100%;
    }

    &__description {
      margin-top: 15px;
    }

    &__list {
      padding-bottom: 50px;
      margin-top: 20px;
      grid-row-gap: 20px;
    }

    &__item {
      padding-top: 10px;
    }
  }
}
