.filter-fieldset {
  &__fields {
    display: flex;
    flex-wrap: wrap;
  }
}

@include respond-up('large') {
  .filter-fieldset {

  }
}

@include respond-up('medium') {
  .filter-fieldset {
    &__fields {
      column-gap: 30px;
      row-gap: 20px;
    }
  }
}

@include respond('medium') {
  .filter-fieldset {
    &__fields {
      flex-wrap: wrap;
    }
  }
}

@include respond-down('medium') {
  .filter-fieldset {
    &__fields {
      row-gap: 14px;
    }
  }
}

@include respond-down('small') {
  .filter-fieldset {
    &__fields {
      flex-direction: column;
    }
  }
}