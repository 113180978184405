.look-more {
  display: grid;
  background-color: var(--neutral-grey);

  &__link-text-last {
    color: var(--accent-brown);
  }

  &__link-text {
    display: flex;
    flex-direction: column;

    > :first-child {
      color: var(--base-black-50);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    scale: 1.1;
  }

  &__link-wrap {
    flex: 1 0 auto;
    display: grid;
  }

  &__link-text {
    grid-area: link-text;
  }

  &__arrow {
    grid-area: arrow;
    display: flex;
    align-items: flex-end;
  }

  &__wrap-arrow {
    overflow: hidden;
  }

  &__wrap-image {
    grid-area: wrap-image;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
  }
}

@include respond-up('s-large') {
  .look-more {
    grid-template-columns: 1fr 1fr;

    &__index {
      margin-top: 150px;
    }

    &__arrow {
      svg {
        width: 54px;
        height: 54px;
      }
    }

    &__wrap-arrow {
      width: 54px;
      height: 54px;
    }

    &__link-text-last {
      &.link-underline__text {
        display: inline-flex;
        width: unset;

        &:after {
          height: 3px;
          bottom: 0;
        }
      }
    }

    &__link {
      &:hover {
        .look-more {
          &__picture {
            opacity: 1;
            scale: 1;
          }

          &__arrow {
            svg {
              animation: push-arrow-diagonal .55s;
            }
          }
        }
      }
    }

    &__picture {
      opacity: 0;
      transition: opacity, scale;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &__link-wrap {
      grid-row-gap: 93px;
      grid-template-areas:
      'link-text link-text'
      'arrow wrap-image';
    }

    &__link {
      padding-top: var(--grid-gap);
      padding-bottom: var(--grid-gap);
    }

    > :first-child {
      padding-left: var(--grid-spacer-and-indent);
      padding-right: calc(var(--grid-gap) / 2);
    }

    > :last-child {
      padding-right: var(--grid-spacer-and-indent);
      padding-left: calc(var(--grid-gap) / 2);
      border-left: 1px solid var(--line-on-grey);
    }
  }
}

@include respond-up('medium') {
  .look-more {
    &__wrap-link-text-last {
      margin-top: 30px;
    }

    &__picture {
      width: var(--grid-column2);
      height: calc(var(--grid-column2) * 223 / 237);
    }
  }
}

@include respond('medium') {
  .look-more {
    &__index {
      margin-top: 100px;
    }

    &__wrap-arrow {
      width: 42px;
      height: 42px;
    }

    &__arrow {
      svg {
        width: 42px;
        height: 42px;
      }
    }

    &__link-wrap {
      grid-template-areas:
      'link-text wrap-image'
      'arrow wrap-image';
    }

    &__wrap-link-text-last {
      width: var(--grid-column4);
    }
  }
}

@include respond-down('medium') {
  .look-more {
    grid-template-rows: 1fr 1fr;

    &__link {
      padding: var(--grid-gap) var(--grid-spacer-and-indent);
    }

    > :last-child {
      border-top: 1px solid var(--line-on-grey);
    }

    &__link-text-last {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 3px;
        background: var(--accent-brown);
        left: 0;
        bottom: 0;
      }
    }

    &__link-wrap {
      height: 100%;
    }
  }
}

@include respond-down('small') {
  .look-more {
    &__index {
      margin-top: 50px;
    }

    &__picture {
      width: var(--grid-column2);
      height: calc(var(--grid-column2) * 151 / 160);
    }

    &__wrap-arrow {
      width: 22px;
      height: 22px;
    }

    &__arrow {
      svg {
        width: 22px;
        height: 22px;
      }
    }

    &__wrap-link-text-last {
      margin-top: 20px;
    }

    &__link-wrap {
      grid-row-gap: 40px;
      grid-template-areas:
      'link-text link-text'
      'arrow wrap-image';
    }
  }
}
