.index-custom {
  &__text-block,
  &__picture-block{
    &.animate {
      .index-custom {
        &__button {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }


  &__text-block,
  &__picture-block{
    &.need-animation {
      .index-custom{
        &__button{
          transform: translate3d(0, 20px, 0);
          opacity: 0;
        }
      }
    }
  }


  &__button {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: {
      property: opacity, transform;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
      delay: .7s;
    };
  }

  &__picture-block{
    position: relative;
    display: flex;

  }
  &__picture-wrap {
    position: relative;
    z-index: 5;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &:before {
      background: linear-gradient(0deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.6) 100%);
      top: 0;
    }

    &:after {
      background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.8) 100%);
      bottom: 0;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 1;
      height: clamp(calc(50vh - 37px), calc(50vh - 37px), 563px);
    }
  }

  &__description{
    display: grid;
  }

  &__wrap-top-text{
    display: flex;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(50, 48, 44, 0.1);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__wrap-text-bottom{
    display: flex;
    position: absolute;
    z-index: 50;
    width: 100%;
  }

  &__description-bottom{
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('s-large') {
  .index-custom {
    margin-top: 150px;
    &__picture-block {
      margin-top: 70px;
    }

    &__wrap-top-text{
      gap: calc(var(--grid-column) + var(--grid-gap) * 2);
    }

    &__title{
      width: var(--grid-column5);
    }

    &__description{
      width: var(--grid-column6);
      gap: var(--grid-gap);
    }

    &__wrap-link-top{
      margin-top: 50px;
      margin-left: calc(var(--grid-column6) + var(--grid-gap));
    }
    &__wrap-text-bottom{
      bottom: 70px;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__picture {
      height: calc(100vw * 819/1700);
    }
  }
}

@include respond-up('medium') {
  .index-custom {
    &__description{
      grid-template-columns: repeat(2, var(--grid-column3));
      gap: var(--grid-gap);
    }
    &__wrap-link-top{
      width: 255px;
    }
    &__description-bottom{
      gap: 20px;
      width: var(--grid-column5);
    }
    &__wrap-link-bottom{
      width: 295px;
    }
  }
}

@include respond('medium') {
  .index-custom {
    margin-top: 100px;

    &__wrap-link-top{
      margin-top: 40px;
    }

    &__description{
      margin-top: 30px;
    }
    &__wrap-link-bottom{
      margin-top: 40px;
    }
    &__picture-block{
      margin-top: 50px;
    }

    &__picture {
      height: calc(100vw * 582/834);
    }
  }
}

@include respond-down('medium') {
  .index-custom {
    &__wrap-top-text{
      flex-direction: column;
    }

    &__wrap-text-bottom{
      bottom: 30px;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .index-custom {
    margin-top: 50px;

    &__picture-wrap {
      &::after {
        height: 417px;
      }
    }

    &__wrap-link-top{
      margin-top: 20px;
    }

    &__description{
      margin-top: 15px;
      gap: 20px;
    }

    &__description-bottom{
      gap: 15px;
      width: var(--grid-column4);
    }

    &__wrap-link-bottom{
      width: 100%;
      margin-top: 30px;
    }
    &__picture-block{
      margin-top: 30px;
    }

    &__picture {
      height: calc(100vw * 578/374);
    }
  }
}