.advantages {
  &__list {
    display: grid;
    grid-auto-rows: 1fr;
    position: relative;
  }

  &__item-wrap {
    position: sticky;
    background: var(--base-white-100);
  }
}

@include respond-up('s-large') {
  .advantages {
    &__list {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .advantages {
    &__item-wrap {
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          top: calc(var(--header-height) + (#{$i} * 50px));
        }
      }
    }
  }
}

@include respond('medium') {
  .advantages {
    &__list {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .advantages {

  }
}

@include respond-down('small') {
  .advantages {
    &__list {
      margin-top: 12px;
    }

    &__item-wrap {
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          top: calc(var(--header-height) + (#{$i} * 38px));
        }
      }
    }
  }
}