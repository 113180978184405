.contacts-block {
  display: grid;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@include respond-up('s-large') {
  .contacts-block {
    margin-top: 150px;
    padding-bottom: 40px;
    grid-template-columns: var(--grid-column9) var(--grid-column3);
    grid-gap: var(--grid-gap);

    &__schedule {
      margin-top: 95px;
    }

    &__button {
      margin-top: 80px;
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__right {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        left: calc(-1 * var(--grid-gap) / 2);
        width: 1px;
        height: 100%;
        background-color: var(--line-on-white);
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-block {
    &__button {
      width: var(--grid-column2);
    }
  }
}

@include respond('medium') {
  .contacts-block {
    margin-top: 100px;
    padding-bottom: 100px;
    grid-gap: 40px;

    &__right {
      display: grid;
      gap: var(--grid-gap);
      grid-template-columns: repeat(2, var(--grid-column3));
    }

    &__schedule {
      height: fit-content;

      &:after {
        left: calc(-1 * var(--grid-gap) / 2);
      }
    }

    &__button {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .contacts-block {
    &__schedule {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;

        width: 1px;
        height: 100%;
        background-color: var(--line-on-white);
      }
    }
  }
}

@include respond-down('small') {
  .contacts-block {
    padding-bottom: 50px;
    margin-top: 50px;
    grid-gap: 30px;

    &__schedule {
      padding-left: var(--grid-gap);

      &:after {
        left: 0;
      }

      margin-top: 20px;
    }

    &__button {
      margin-top: 20px;
    }

    &__button {
      width: var(--grid-column4);
    }
  }
}