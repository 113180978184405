.about-page {

}

@include respond-up('s-large') {
  .about-page {
    padding-top: 30px;
  }
}

@include respond-up('medium') {
  .about-page {

  }
}

@include respond('medium') {
  .about-page {
    padding-top: 20px;
  }
}

@include respond-down('medium') {
  .about-page {

  }
}

@include respond-down('small') {
  .about-page {
    padding-top: 18px;
  }
}