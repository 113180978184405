.project-info {
  &__button-wrapper {
    width: 100%;
  }

  &__button {
    display: none;

    &._active {
      display: flex;
    }
  }

  &__price {
    span {
      display: none;

      &._active {
        display: flex;
      }
    }
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
  }

  &__value {
    padding-left: var(--grid-gap);
  }

  &__info-wrapper {
    display: grid;
  }

  &__property-table {
    border-top: 1px solid var(--line-on-white);
    border-left: 1px solid var(--line-on-white);
    border-right: 1px solid var(--line-on-white);
  }

  &__property-row {
    border-bottom: 1px solid var(--line-on-white);
    display: flex;
    align-items: center;

    &_last {
      flex-direction: column;
    }
  }
}

@include respond-up('s-large') {
  .project-info {
    &__property {
      flex: 0 0 var(--grid-column2);
    }

    &__description {
      margin-top: 70px;
      width: var(--grid-column5);
    }

    &__properties {
      margin-top: 76px;
    }

    &__description-wrapper {
      padding-top: 30px;
    }

    &__info-wrapper {
      grid-template-columns: var(--grid-column4) var(--grid-column6);
      grid-column-gap: calc(var(--grid-column2) + 2 * var(--grid-gap));
    }

    &__built {
      margin-top: 70px;
    }
  }
}

@include respond-up('medium') {
  .project-info {
    &__price {
      margin-top: 6px;
    }

    &__info {
      padding-bottom: 24px;
    }

    &__property-row {
      padding-bottom: 12px;
      padding-top: 10px;

      &_last {
        padding: 30px;
      }
    }

    &__value {
      padding-right: 30px;
    }

    &__property {
      padding-left: 30px;
    }
  }
}

@include respond('medium') {
  .project-info {
    &__description {
      margin-top: 20px;
      width: var(--grid-column5);
    }

    &__description-wrapper {
      margin-top: 70px;
    }

    &__properties {
      margin-top: 50px;
    }

    &__property {
      flex: 0 0 var(--grid-column3);
    }

    &__built {
      margin-top: 60px;
    }
  }
}

@include respond-down('small') {
  .project-info {
    &__price {
      margin-top: 4px;
    }

    &__info {
      padding-bottom: 16px;
    }

    &__description {
      margin-top: 15px;
    }

    &__description-wrapper {
      margin-top: 40px;
    }

    &__properties {
      margin-top: 30px;
    }

    &__property-row {
      padding-bottom: 10px;
      padding-top: 8px;
      justify-content: space-between;

      &_last {
        padding: 18px;

        .text__question {
          padding-top: 1px;
        }
      }
    }

    &__value {
      text-align: left;
      padding-right: 18px;
    }

    &__property {
      padding-left: 18px;
    }

    &__built {
      margin-top: 30px;
    }
  }
}
