.partners-slider {
  position: relative;

  &__slider {
    &::before,
    &::after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      z-index: 10;
      pointer-events: none;
    }

    &::before {
      top: 0;
      background: linear-gradient(0deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.6) 100%);
    }

    &::after {
      bottom: 0;
      background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.5) 100%);
    }
  }

  &__item {
    &.swiper-slide {
      width: 100vw;
    }
  }

  &__image-wrap {
    width: 100%;
    height: 100%;
  }

  &__text-wrap {
    position: absolute;
    z-index: 100;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-slider-wrap {
    position: absolute;
    z-index: 50;
  }

  &__text-slider {
    position: relative;
  }

  &__text-slide {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity var(--transition);

    &._active {
      opacity: 1;
    }
  }

  &__pagination-list {
    display: flex;
    flex-direction: column;
  }

  &__pagination-item {
    border-bottom: 1px solid var(--base-white-50);

    &:first-child {
      border-top: 1px solid var(--base-white-50);
    }
  }

  &__pagination-link {
    padding: 9px 0 8px;
    display: flex;
    color: var(--base-white-50);
    transition: color var(--transition);
    position: relative;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      bottom: -1px;
      border-bottom: 2px solid var(--base-white-100);
    }

    &._active {
      color: var(--base-white-100);

      &::before {
        animation: progressbar linear 5s forwards;
      }
    }
  }
}

@include respond-up('s-large') {
  .partners-slider {
    &__slider {
      &::before {
        height: calc(100vw * 396 / 1700);
      }

      &::after {
        height: calc(100vw * 349 / 1700);
      }
    }

    &__item {
      &.swiper-slide {
        height: calc(100vw * 819 / 1700);
      }
    }

    &__text-wrap {
      top: 30px;
      bottom: 75px;
      left: var(--grid-spacer-and-indent);
      width: var(--grid-column6);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__text {
      margin-top: 40px;
    }

    &__text-slider-wrap {
      bottom: 70px;
      right: calc(var(--grid-gap) + var(--grid-column) + var(--grid-spacer-and-indent));
    }

    &__pagination-wrap {
      width: var(--grid-column5);
    }

    &__pagination-link {
      &:hover {
        color: var(--base-white-100);
      }
    }

    &__picture {
      &::after {
        background: rgba(50, 48, 44, 0.2);
      }
    }
  }
}

@include respond-up('medium') {
  .partners-slider {
    &__text-slider-wrap {
      width: var(--grid-column5);
    }
  }
}

@include respond('medium') {
  .partners-slider {
    &__slider {
      &::before {
        height: calc(100vw * 240 / 834);
      }

      &::after {
        height: calc(100vw * 272 / 834);
      }
    }

    &__item {
      &.swiper-slide {
        height: calc(100vw * 760 / 834);
      }
    }

    &__text {
      margin-top: 30px;
    }

    &__text-slider-wrap {
      bottom: 60px;
      left: var(--grid-spacer-and-indent);
    }

    &__pagination-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .partners-slider {
    &__text-wrap {
      top: var(--grid-spacer-and-indent);
      left: var(--grid-spacer-and-indent);
      right: var(--grid-spacer-and-indent);
    }

    &__pagination-wrap {
      width: var(--grid-column4);
    }

    &__picture {
      &::after {
        background: rgba(50, 48, 44, 0.4);
      }
    }
  }
}

@include respond-down('small') {
  .partners-slider {
    &__slider {
      &::before {
        height: calc(100vw * 230 / 374);
      }

      &::after {
        height: calc(100vw * 290 / 374);
      }
    }

    &__item {
      &.swiper-slide {
        height: calc(100vw * 639 / 374);
      }
    }

    &__text {
      margin-top: 20px;
    }

    &__text-slider-wrap {
      bottom: 30px;
      left: var(--grid-spacer-and-indent);
      right: var(--grid-spacer-and-indent);
    }

    &__pagination-wrap {
      margin-top: 30px;
    }
  }
}

@keyframes progressbar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}