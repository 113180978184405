.page-tabs {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    border-top: 1px solid var(--line-on-white);

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      bottom: 0;
      border-left: 1px solid var(--line-on-white);
      z-index: 10;
    }
  }

  &__item {
    position: relative;
    border-bottom: 1px solid var(--line-on-white);

    &::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: solid var(--accent-brown);
      opacity: 0;
      transition: opacity var(--transition);
      z-index: 20;
    }

    &:first-child {
      &::before {
        right: -1px;
      }
    }

    &._active {
      border-bottom: 1px solid var(--accent-brown);
      &::before {
        opacity: 1;
      }

      .page-tabs {
        &__icon {
          svg path {
            fill: var(--accent-brown);
          }
        }

        &__title {
          color: var(--accent-brown);
        }
      }
    }
  }

  &__link-content {
    display: flex;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__link,
  &__link-content {
    height: 100%;
  }
}

@include respond-up('s-large') {
  .page-tabs {
    &__link {
      padding: 33px var(--grid-spacer-and-indent) 38px;
      transition: background var(--transition);

      &:hover {
        background: var(--neutral-grey);

        .page-tabs {
          &__icon {
            svg path {
              fill: var(--accent-brown);
            }
          }

          &__title {
            color: var(--accent-brown);
          }
        }
      }
    }

    &__icon {
      svg path {
        transition: fill var(--transition);
      }
    }

    &__title {
      transition: color var(--transition);
    }
  }
}

@include respond-up('medium') {
  .page-tabs {
    &__item {
      &::before {
        border-bottom-width: 8px;
      }
    }

    &__link-content {
      align-items: center;
      column-gap: 20px;
    }
  }
}

@include respond('medium') {
  .page-tabs {
    &__link {
      padding: 36px var(--grid-spacer-and-indent) 38px;
    }
  }
}

@include respond-down('small') {
  .page-tabs {
    &__item {
      &::before {
        border-bottom-width: 4px;
      }
    }

    &__link-content {
      flex-direction: column;
      row-gap: 22px;
      justify-content: space-between;
    }

    &__link {
      padding: var(--grid-spacer-and-indent);
    }
  }
}