.catalog-page {
  .picture-wrapper {
    display: flex;
    width: 100%;
  }

  &__list-link {
    display: flex;
    overflow: hidden;
    height: 100%;
    position: relative;

    &:after {
      background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.6) 100%);
      bottom: 0;
      content: "";
      //height: 50%;
      //height: clamp(calc(50vh - 37px), calc(50vh - 37px), 563px);
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &__top-list-link {
    display: flex;
  }

  &__top-list {
    display: grid;
    gap: var(--grid-gap);
  }

  &__top-list-item {
    display: block;
    position: relative;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    color: var(--base-white-100);
    font-family: var(--h-font);
    font-weight: 700;
    text-transform: uppercase;
    font-size: var(--h-size);
  }

  &__description-icon {
    svg {
      line {
        stroke: #CFC9B9;
      }
    }
  }

  &__title,
  &__description-icon,
  &__description {
    transition: all var(--default-timing) var(--default-transition-function);
  }

  &__description {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__bottom-list {
    position: relative;
    display: grid;
    gap: var(--grid-gap);
  }

  &__bottom-list-item {
    display: block;
    position: relative;
  }

  &__wrapper {
    position: absolute;
    z-index: 40;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

@include respond-up('large') {
  .catalog-page {
    &__title {
      padding-right: calc(var(--grid-gap) + var(--grid-column2));
    }
  }
}

@include respond-up('large') {
  .catalog-page {
    &__top-list {
      .catalog-page {
        &__text,
        &__description-text {
          width: var(--grid-column4);
        }
      }
    }
  }
}

@include respond('s-large') {
  .catalog-page {
    &__top-list {
      .catalog-page {
        &__text,
        &__description-text {
          width: var(--grid-column4);
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .catalog-page {
    margin-bottom: 150px;

    &__description-text-content {
      display: flex;
      padding-top: 20px;
    }

    &__list-link {
      &:after {
        height: 50%;
      }
    }

    &__top {
      margin-top: 70px;
    }

    &__top-list {
      display: grid;
      padding-top: 20px;
      margin-top: 30px;
      grid-template-columns: repeat(2, 1fr);
      border-top: 1px solid var(--line-on-white);
    }

    &__wrapper {
      padding: 0 30px 30px 30px;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__description {
      position: relative;
    }

    &__description-text {
      transition: height, opacity;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &__description-icon {
      opacity: 0;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__top-list-item {
      height: calc(var(--grid-column6) * 386 / 790);

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        inset: auto;
        opacity: 0;
        background: rgba(50, 48, 44, 0.5);
        z-index: 30;
        transition: opacity var(--default-timing);
      }

      &:nth-child(1) {
        grid-row: 1/3;
        //height: calc(var(--grid-column6) * 926 / 900);
        height: 100%;

        &:after {
          position: absolute;
          content: '';
          //height: calc(var(--grid-column6) * 926 / 900);
          height: 100%;
          width: 1px;
          background: var(--line-on-white);
          right: -20px;
          top: 0;
        }
      }

      &:nth-child(3) {
        &:after {
          position: absolute;
          content: '';
          height: 1px;
          width: 100%;
          background: var(--line-on-white);
          top: -20px;
        }
      }

      &:nth-child(2n+4) {
        &:not(:first-child) {
          &:after {
            background-color: var(--line-on-white);
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            top: -20px;
            width: var(--grid-column12);
          }
        }
      }

      &:nth-child(2n+5) {
        &:after {
          position: absolute;
          content: '';
          height: calc(var(--grid-column6) * 440 / 900);
          width: 1px;
          background: var(--line-on-white);
          left: -20px;
          top: 0;
        }
      }
    }

    &__title-first {
      --h-size: clamp(35px, calc(0.019 * 100vw + 7.308px), 55px);
      line-height: 110%;
    }

    &__title-next {
      --h-size: clamp(22px, calc(0.0076 * 100vw + 10.9230px), 30px);
      line-height: 130%;
    }

    &__bottom {
      margin-top: 150px;
    }

    &__bottom-list {
      padding-top: 20px;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 30px;

      .catalog-page {
        &__text,
        &__description-text {
          width: var(--grid-column3);
        }
      }
    }

    &__top-list-item,
    &__bottom-list-item {
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    &__wrapper {
      &:hover {
        .catalog-page {
          &__description-icon {
            opacity: 1;
          }
        }
      }
    }

    &__bottom-list-item {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 644 / 587);

      &:nth-child(3n + 2) {
        &:after {
          position: absolute;
          content: '';
          height: 100%;
          width: 1px;
          left: -20px;
          background: var(--line-on-white);
          top: 0;
        }
      }

      &:nth-child(3n + 3) {
        &:after {
          position: absolute;
          content: '';
          height: 100%;
          width: 1px;
          left: -20px;
          background: var(--line-on-white);
          top: 0;
        }
      }

      &:nth-child(3n + 1) {
        &:after {
          position: absolute;
          content: '';
          height: 1px;
          width: var(--grid-column12);
          top: -20px;
          left: 0;
          background: var(--line-on-white);
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        inset: auto;
        opacity: 0;
        background: rgba(50, 48, 44, 0.5);
        z-index: 30;
        transition: opacity var(--default-timing) var(--default-transition-function);
      }
    }

    &__card-title {
      width: var(--grid-column3);
      padding: 0;
    }
  }
}

@include respond-down('s-large') {
  .catalog-page {
    &__description-text {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .catalog-page {
    &__description-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@include respond('medium') {
  .catalog-page {
    margin-bottom: 100px;

    &__list-link {
      &:after {
        height: 40%;
      }
    }

    &__top {
      margin-top: 50px;
    }

    &__top-list {
      padding-top: 15px;
      margin-top: 15px;
    }

    &__top-list-item {
      width: var(--grid-column6);
      height: calc(var(--grid-column6) * 712 / 1119);

      &:before {
        top: -15px;
      }
    }

    &__title-first,
    &__title-next {
      line-height: 120%;
      --h-size: clamp(26px, calc(0.0303 * 100vw + 2.7272px), 28px);
    }

    &__block-title-bottom {
      margin-top: 100px;
    }

    &__bottom {
      margin-top: 100px;
    }

    &__bottom-list {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 15px;
      padding-top: 15px;
    }

    &__bottom-list-item {
      height: calc(var(--grid-column3) * 598 / 545);
      width: var(--grid-column3);

      &:nth-child(1) {
        height: calc(var(--grid-column6) * 712 / 1119);
        width: var(--grid-column6);
        grid-column: 1/3;
        grid-row: 1/2;

        &:before {
          top: -15px;
          position: absolute;
          content: '';
          height: 1px;
          width: var(--grid-column6);
          left: 0;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2n + 3) {
        &:before {
          left: -15px;
          position: absolute;
          content: '';
          height: 100%;
          width: 1px;
          top: 0;
          background: var(--line-on-white);
        }
      }

      &:nth-child(2n + 2) {
        &:before {
          top: -15px;
          position: absolute;
          content: '';
          height: 1px;
          width: var(--grid-column6);
          left: 0;
          background: var(--line-on-white);
        }
      }

    }

    &__wrapper {
      padding: 0 35px 30px 30px;
    }
  }
}

@include respond-down('medium') {
  .catalog-page {
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__top-list {
      grid-template-columns: 1fr;
    }

    &__top-list-item {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        background: var(--line-on-white);
      }
    }

    &__bottom-list {
      grid-template-columns: 1fr;
    }
  }
}

@include respond-down('small') {
  .catalog-page {
    margin-bottom: 50px;

    &__title {
      padding-right: var(--grid-gap);
    }

    &__list-link {
      &:after {
        height: 80%;
      }
    }

    &__top {
      margin-top: 20px;
    }

    &__top-list {
      padding-top: 9px;
      margin-top: 12px;
    }

    &__top-list-item {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 465 / 731);

      &:before {
        top: -9px;
      }
    }

    &__title-first,
    &__title-next {
      line-height: 120%;
      --h-size: clamp(19px, calc(0.0545 * 100vw + 0.5454px), 21px);
    }

    &__bottom {
      margin-top: 50px;
    }

    &__bottom-list {
      grid-template-columns: 1fr;
      padding-top: 9px;
      margin-top: 12px;
    }

    &__bottom-list-item {
      height: calc(var(--grid-column4) * 465 / 731);
      width: var(--grid-column4);

      &:before {
        top: -9px;
        position: absolute;
        content: '';
        height: 1px;
        width: var(--grid-column4);
        left: 0;
        background: var(--line-on-white);
      }
    }

    &__description {
      flex: 0 0 14px;
    }

    &__description-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 14px;
      flex: 0 0 14px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__wrapper {
      padding: 0 18px 18px 18px;
    }
  }
}