@font-face {
    font-family: 'Actay Wide';
    src: url('ActayWide-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Actay Wide Italic';
    src: url('ActayWide-BoldItalic.otf');
    font-weight: 700;
    font-style: normal;
}