.social-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0;
  width: 100%;

  &._light{
    --color: var(--accent-dark);
    --line-arrow: var(--base-black-100);
    --border: var(--line-on-white);
    --border-hover: var(--base-black-100);
  }
  &._dark{
    --color: var(--neutral-biege);
    --line-arrow: var(--neutral-biege);
    --border: var(--line-on-black);
    --border-hover: var(--base-white-100);
  }

  border-bottom: 1px solid var(--border);

  &__text{
    color: var(--color);
  }

  &__arrow{
    svg{
      line{
        stroke: var(--line-arrow);
      }
    }
  }
}

@include respond-up('s-large') {
  .social-link {
    position: relative;
    &__text{
      display: block;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        background: var(--border-hover);
        bottom: -1px;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-out;
      }
    }
    &:hover{
      .social-link{
        &__text{
          &::after {
            transform-origin: left;
            transition: transform 0.3s var(--teaser-transition-func);
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .social-link {

  }
}

@include respond('medium') {
  .social-link {

  }
}

@include respond-down('medium') {
  .social-link {

  }
}

@include respond-down('small') {
  .social-link {

  }
}