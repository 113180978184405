.project-next {
  background: var(--neutral-grey);
  display: block;

  &__wrap {
    display: flex;
    gap: var(--grid-gap);
  }

  &__wrap-text {
    display: flex;
    flex-direction: column;
  }

  &__over-title {
    color: var(--base-black-50);
  }

  &__wrap-title {
    position: relative;
  }

  &__title {
    position: relative;
    color: var(--accent-brown);
  }

  &__size {
    display: flex;
    align-items: center;
  }

  &.animate-out {
    .project-next {
      &__size {
        opacity: 1;
      }
    }
  }

  &__square {
    width: 4px;
    height: 4px;
    background: var(--base-black-100);
  }

  &__wrap-text-arrow {
    display: flex;
    justify-content: space-between;

  }

  &__wrap-image {
    display: flex;
    width: 100%;
    background: var(--image-background);
    transform-origin: right bottom;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  &__arrow {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('s-large') {
  .project-next {
    &:hover {
      .project-next {

        &__wrap-image {
          width: var(--grid-column6);
          height: calc(var(--grid-column6) * 502 / 790);
        }

        &__arrow svg {
          animation: push-arrow-diagonal .55s;
        }
      }
    }

    &__title {
      &.t-wrapper__inner {
        display: inline-flex;
        width: unset;
      }
    }

    &__wrap {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &__wrap-text {
      width: var(--grid-column6);
    }

    &__wrap-title {
      margin-top: 40px;
    }

    &__wrap-text-arrow {
      margin-top: 10px;
      flex-direction: column;
      height: 100%;
    }

    &__wrap-image {
      width: var(--grid-column6);
      height: calc(var(--grid-column6) * 326 / 513);
    }

    &__size {
      opacity: 0;
      transition: opacity;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &__arrow {
      width: 80px;
      height: 80px;
    }
  }
}

@include respond-up('medium') {
  .project-next {
    &__size {
      gap: 10px;
    }
  }
}

@include respond('medium') {
  .project-next {
    &__wrap {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__wrap-title {
      margin-top: 30px;
    }

    &__wrap-text-arrow {
      margin-top: 45px;
    }

    &__picture {
      width: var(--grid-column6);
      height: calc(var(--grid-column6) * 492 / 774);
    }

    &__arrow {
      width: 54px;
      height: 54px;
    }
  }
}

@include respond-down('medium') {
  .project-next {
    &__wrap {
      flex-direction: column;
    }

    &__wrap-text-arrow {
      align-items: flex-end;
    }
  }
}

@include respond-down('small') {
  .project-next {
    &__wrap {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    &__wrap-title {
      margin-top: 20px;
    }

    &__size {
      gap: 8px;
    }

    &__wrap-text-arrow {
      margin-top: 28px;
    }

    &__arrow {
      width: 32px;
      height: 32px;
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 215 / 338);
    }
  }
}