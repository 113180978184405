.about-recall {
  &__contact-block {
    display: flex;
    flex-direction: column;
  }

  &__button {
    &.button {
      width: 237px;
    }
  }
}

@include respond-up('s-large') {
  .about-recall {
    margin-top: 150px;
    display: grid;
    grid-template-columns: var(--grid-column8) var(--grid-column3);
    grid-column-gap: calc(var(--grid-column) + (var(--grid-gap) * 2));

    &__contact-block {
      position: relative;
      height: fit-content;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -21px;
        border-left: 1px solid var(--line-on-white);
      }
    }
  }
}

@include respond-up('medium') {
  .about-recall {
    &__contact-block {
      row-gap: 25px;
    }
  }
}

@include respond('medium') {
  .about-recall {
    margin-top: 100px;
    row-gap: 50px;

    &__contact-block {
      padding-left: 20px;
    }
  }
}

@include respond-down('medium') {
  .about-recall {
    display: flex;
    flex-direction: column;

    &__contact-block {
      border-left: 1px solid var(--line-on-white);
    }
  }
}

@include respond-down('small') {
  .about-recall {
    margin-top: 50px;
    row-gap: 25px;

    &__contact-block {
      row-gap: 20px;
      padding-left: 18px;
    }
  }
}