.checkbox-list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__input {
    display: none;

    &:checked + label {
      background-color: var(--accent-brown);
      color: var(--base-white-100);
    }
  }

  &__input-label {
    display: flex;
  }

  &__values-list {
    flex-wrap: wrap;
    row-gap: 4px;
  }
}

@include respond-up('s-large') {
  .checkbox-list {

  }
}

@include respond-up('medium') {
  .checkbox-list {
    &__input-label {
      &.floor-field {
        &__label {
          padding: 12px 22px;
        }
      }
    }
  }
}

@include respond('medium') {
  .checkbox-list {
  }
}

@include respond-down('medium') {
  .checkbox-list {
  }
}

@include respond-down('small') {
  .checkbox-list {
    &__input-label {
      &.floor-field {
        &__label {
          padding: 8px 20px;
        }
      }
    }
  }
}