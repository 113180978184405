.partners-why-us {
  &__table {
    display: grid;
    gap: var(--grid-gap);
  }

  &__wrap-item-title {
    background: var(--base-black-100);
    position: relative;
    display: flex;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 15px 15px 0;
      border-color: transparent var(--base-white-100) transparent transparent;
      transform: rotate(0deg);
    }
  }

  &__item-title {
    color: var(--neutral-biege);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid var(--line-on-white);
    border-right: 1px solid var(--line-on-white);
    border-bottom: 1px solid var(--line-on-white);
  }

  &__item-last {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--neutral-grey);
  }

  &__item-last-text {
    display: flex;
    gap: 2px;
    flex-direction: column;

    span {
      color: var(--accent-brown);
    }

    > :first-child {
      color: var(--accent-brown)
    }

    > :last-child {
      color: var(--base-black-50);
    }
  }

  &__item-last-bottom {
    display: flex;
  }

  &__item-last-wrap-icon {
    background: var(--base-white-100);
    flex: 0 0 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 24px;
    }
  }

  &__item-last-text-save {
    text-wrap: wrap;
  }

  &__icon {
    svg {
      path{
        fill: var(--accent-brown);
      }
      circle{
        stroke: var(--accent-brown);
      }
      path[id='check']{
        fill: var(--accent-brown);
        stroke: transparent;
      }
    }
  }
}

@include respond-up('s-large') {
  .partners-why-us {
    margin-top: 150px;

    &__table {
      margin-top: 30px;
      grid-template-columns: repeat(3, var(--grid-column4));
    }

    &__content {
      height: 374px;
    }

    &__item-last {
      padding: 40px;
      height: 418px;
    }


  }
}

@include respond-up('medium') {
  .partners-why-us {
    margin-top: 100px;

    &__content {
      padding: 30px;
    }

    &__wrap-item-title {
      height: 44px;
      padding: 0 30px;
    }

    &__item-last-bottom {
      gap: 20px;
    }

    &__icon {
      svg {
        width: 60px;
        height: 60px;
      }
    }
  }
}

@include respond('medium') {
  .partners-why-us {
    &__table {
      margin-top: 20px;
      grid-template-columns: repeat(2, var(--grid-column3));
    }

    &__content {
      height: 354px;
    }

    &__item-last {
      padding: 30px;
      height: 398px;
    }
  }
}

@include respond-down('medium') {
  .partners-why-us {

  }
}

@include respond-down('small') {
  .partners-why-us {
    margin-top: 50px;

    &__table {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
    }

    &__content {
      height: 260px;
      padding: 18px;
    }

    &__icon {
      svg {
        width: 50px;
        height: 50px;
      }
    }

    &__wrap-item-title {
      height: 38px;
      padding: 0 18px;
    }

    &__item-last {
      padding: 18px;
      height: 242px;
    }

    &__item-last-bottom {
      gap: 14px;
    }
  }
}