.partners-accordion {
  &._opened {
    .partners-accordion {
      &__description {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      &__plus {
        &::after {
          transform: translate3d(-50%, 0, 0) rotate(90deg);
        }
      }

      &__block {
        // timing for open accordion with slider
        &:has(.partners-products-slider) {
          transition-duration: .8s;
        }
      }
    }
  }

  &__link {
    position: relative;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__plus {
    display: flex;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: '';
      transition: transform var(--default-timing) var(--default-transition-function);
    }

    &::before {
      width: 100%;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      border-bottom: 2px solid var(--accent-brown);
    }

    &::after {
      height: 100%;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      border-left: 2px solid var(--accent-brown);
    }
  }

  &__block {
    height: 0;
    transition: height var(--transition);
    overflow: hidden;

    &:has(.partners-products-slider) {
      // timing for close accordion with slider
      transition-duration: .4s;
    }
  }

  &__description {
    transition: {
      property: transform, opacity;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
    };

    transform: translate3d(0, -40px, 0);
    opacity: 0;
  }

  &__slider-wrap {
    border-top: 1px solid var(--line-on-white);

    &:last-child {
      padding-bottom: 0;
    }
  }
}

@include respond-up('s-large') {
  .partners-accordion {
    &__link {
      padding: 32px 0 26px;

      &:hover {
        .partners-accordion {
          &__count,
          &__title {
            color: var(--accent-brown);
          }
        }
      }
    }

    &__link,
    &__content {
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column6));
      grid-column-gap: var(--grid-gap);
    }

    &__title {
      column-gap: 10px;
    }

    &__content {
      padding-top: 24px;
      padding-bottom: 80px;
      grid-row-gap: 80px;
    }

    &__description {
      grid-column: 2/3;
    }

    &__sliders-list {
      grid-column: 1/3;
    }

    &__slider-wrap {
      padding: calc(var(--grid-gap) / 2) 0;
    }

    &__count,
    &__title {
      transition: color var(--transition);
    }
  }
}

@include respond-up('medium') {
  .partners-accordion {
    &__plus {
      width: 30px;
      height: 30px;
    }

    &__description {
      width: var(--grid-column5);
    }
  }
}

@include respond('medium') {
  .partners-accordion {
    &__link {
      padding: 25px 0 20px;
      column-gap: 24px;
    }

    &__content {
      padding-top: 20px;
      padding-bottom: 50px;
      row-gap: 50px;
    }

    &__slider-wrap {
      padding: 15px 0;
    }
  }
}

@include respond-down('medium') {
  .partners-accordion {
    &__link {
      display: flex;
    }

    &__title {
      width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .partners-accordion {
    &__link {
      padding: 16px 0 13px;
    }

    &__count {
      display: none;
    }

    &__title {
      column-gap: 20px;
    }

    &__plus {
      flex: 0 0 20px;
      height: 20px;
    }

    &__content {
      padding-top: 4px;
      padding-bottom: 30px;
      row-gap: 30px;
    }

    &__slider-wrap {
      padding: 9px 0;
    }
  }
}