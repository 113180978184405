.requisites {
  background: var(--neutral-grey);
}

@include respond-up('s-large') {
  .requisites {
    padding: 40px 0 120px;

    &__item {
      &:first-child {
        grid-column: 1/4;
      }

      &:nth-child(2) {
        grid-column: 7/10;
      }

      &:last-child {
        grid-column: 10/13;
      }
    }
  }
}

@include respond-up('medium') {
  .requisites {
    &__list {
      margin-top: 50px;
    }
  }
}

@include respond('medium') {
  .requisites {
    padding: 30px 0 70px;

    &__list {
      &.grid-block {
        row-gap: 40px;
      }
    }

    &__item {
      grid-column: span 3;
    }
  }
}

@include respond-down('medium') {
  .requisites {

  }
}

@include respond-down('small') {
  .requisites {
    padding: 18px 0 40px;

    &__list {
      margin-top: 30px;

      &.grid-block {
        row-gap: 20px;
      }
    }

    &__item {
      grid-column: span 4;
    }
  }
}