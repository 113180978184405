.custom-cursor {
  display: block;
  height: 66px;
  width: 66px;
  margin-left: -33px;
  margin-top: -33px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000100;

  &__drag {
    display: flex;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform-origin: center;

    svg {
      transform: scale(.3);
      transition: transform .2s;
    }
  }

  &__drag {
    display: flex;
    transition: opacity .2s 0s, transform .2s 0s;

    svg {
      path {
        transition: fill .2s linear;
      }
    }
  }

  &._drag .custom-cursor__drag {
    opacity: 1;

    svg {
      transform: scale(1);
    }
  }

  &._hide {
    display: none;
  }
}

.no-cursor {
  cursor: none!important;
}