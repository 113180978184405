.more-projects {
  &__other-list {
    display: grid;
    gap: var(--grid-gap);
    position: relative;

    >:nth-child(1){
      &:after{
        position: absolute;
        content: '';
        top: 0;
        height: 1px;
        background: var(--line-on-white);
      }
    }

    >:nth-child(2),
    >:nth-child(3){
      &:after{
        position: absolute;
        content: '';
        top: 0;
        height: 100%;
        width: 1px;
        background: var(--line-on-white);
      }
    }
  }
}

@include respond-up('s-large') {
  .more-projects {
    &__other {
      margin-top: 150px;
      padding-bottom: 150px;
    }

    &__other-list {
      margin-top: 30px;
      padding-top: 20px;
      grid-template-columns: repeat(3, var(--grid-column4));

      >:nth-child(1){
        &:after{
          width: var(--grid-column12);
        }
      }
      >:nth-child(2),
      >:nth-child(3){
        position: relative;
        &:after{
          left: -20px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .more-projects {

  }
}

@include respond('medium') {
  .more-projects {
    &__other {
      margin-top: 100px;
      padding-bottom: 100px;
    }

    &__other-list {
      margin-top: 20px;
      padding-top: 15px;
      grid-template-columns: repeat(2, var(--grid-column3));

      >:nth-child(1){
        &:after{
          width: var(--grid-column6);
        }
      }
      >:nth-child(2),
      >:nth-child(3){
        position: relative;
        &:after{
          left: -15px;
        }
      }
    }

  }
}

@include respond-down('medium') {
  .more-projects {
    &__other-list-item {
      &:nth-child(3) {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .more-projects {
    &__other {
      margin-top: 50px;
      padding-bottom: 50px;
    }

    &__other-list {
      padding-top: 9px;
      margin-top: 12px;
      grid-template-columns: repeat(2, var(--grid-column2));

      >:nth-child(1){
        &:after{
          width: var(--grid-column4);
        }
      }
      >:nth-child(2),
      >:nth-child(3){
        position: relative;
        &:after{
          left: -9px;
        }
      }
    }
  }
}