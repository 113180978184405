// не ходи сюда. оно тебя сожрёт.
// файл имеет смысл только в паре с images-block.js

.images-block {
  display: flex;
  flex-direction: column;

  &__vl-button {
    background-color: var(--base-white-100);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent transparent var(--accent-brown);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: scale, transform;
      transform-origin: top;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-timing-function);
    }
  }

  &__video-link {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
  }

  &__article {
    &._grad {
      &:before {
        content: '';
        z-index: 70;
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 50%;
        background: linear-gradient(180deg, rgba(50, 48, 44, 0) 0%, rgba(50, 48, 44, 0.5) 100%);
      }
    }
  }

  &__article-image {
    position: relative;
  }

  &__article-table {
    article {
      padding-left: var(--grid-spacer-and-indent) !important;
      padding-right: var(--grid-spacer-and-indent) !important;
      margin-left: calc(-1 * var(--grid-spacer-and-indent));
      margin-right: calc(-1 * var(--grid-spacer-and-indent));
      overflow: scroll;

      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */

      &::-webkit-scrollbar {
        display: none;
        width: 0px;
      }
    }
  }

  &__slider-wrapper {
    position: relative;
  }

  &__link-to-form {
    margin-top: calc(var(--grid-gap) / 2);
  }

  &__title-wrapper-grid {
    display: grid;
  }

  &__link {
    display: flex;
    background-color: var(--neutral-grey);
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    transition: transform;
    transition-duration: var(--picture-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &:first-child {
    .plannings {
      .images-block {
        &__list {
          padding-bottom: calc(var(--grid-gap) / 2);
          border-bottom: 1px solid var(--line-on-white);
        }
      }
    }
  }

  &__images-wrapper {
    &_plannings {
      .images-block {
        &__list {
          border-bottom: 1px solid var(--line-on-white);
          padding-bottom: calc(var(--grid-gap) / 2);
        }
      }
    }

    &_no-description {
      .images-block {
        &__list {
          &:not(.swiper-wrapper) {
            border-top: 1px solid var(--line-on-white);
          }
        }
      }
    }

    &:not(.images-block__images-wrapper_1) {
      .images-block {
        &__list {
          &:not(.swiper-wrapper) {
            border-top: 1px solid var(--line-on-white);
          }

          padding-top: calc(var(--grid-gap) / 2);
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .images-block {
    &__video-link {
      &.link-underline {
        --underline-link-color: var(--base-white-100);
        --underline-link-offset: 0;

        .link-underline__text {
          margin-top: var(--underline-link-offset);
        }
      }

      &:hover {
        .images-block {
          &__vl-button {
            &:before {
              scale: 1.1;
              transform: translate(calc(-50% + 1px), -50%);
            }
          }
        }
      }
    }

    &__article-image {
      margin-top: 80px;

      &:hover {
        .images-block {
          &__image {
            transform: scale(1.05);
          }
        }
      }
    }

    &__slider-wrapper {
      margin-top: 150px;
    }

    &__article-table {
      margin-top: 80px;
      margin-left: calc(var(--grid-column1) + var(--grid-gap));
      margin-right: calc(var(--grid-column1) + var(--grid-gap));
    }

    &__article {
      margin-left: calc(var(--grid-column3) + var(--grid-gap));
      margin-right: calc(var(--grid-column3) + var(--grid-gap));
    }

    &__lower-description {
      grid-area: description;
    }

    &__upper-description {
      &_arrows {
        margin-bottom: 70px;
      }
    }

    &__title-wrapper-grid {
      grid-template-columns: repeat(2, var(--grid-column5));
      grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));
    }

    &__item {
      &:hover {
        .images-block {
          &__image {
            transform: scale(1.05);
          }
        }
      }
    }

    &__images-wrapper {
      margin-top: 30px;

      &_description {
        margin-top: 70px;
      }

      &_no-description {
        .images-block {
          &__list {
            padding-top: 20px;
          }
        }
      }

      &_1 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__picture {
                  height: calc(657 / 790 * var(--grid-column6));
                }

                &__item {
                  grid-column: span 6;

                  &:before {
                    width: var(--grid-column12);
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  height: calc(628 / 1360 * var(--grid-column12));
                }

                &__item {
                  grid-column: span 12;
                }
              }
            }
          }
        }
      }

      &_2 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__picture {
                  height: calc(657 / 790 * var(--grid-column6));
                }

                &__item {
                  grid-column: span 6;

                  &:nth-child(1) {
                    &:before {
                      width: var(--grid-column12);
                    }
                  }

                  &:nth-child(2) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      top: 0;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  //height: calc(657 / 790 * var(--grid-column6));
                  height: calc(var(--grid-column6) * 502 / 790);
                }

                &__item {
                  grid-column: span 6;

                  &:nth-child(1) {
                    &:before {
                      width: var(--grid-column12);
                    }
                  }

                  &:nth-child(2) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      top: 0;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_3 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__picture {
                  height: calc(427 / 513 * var(--grid-column4));
                }

                &__item {
                  grid-column: span 4;

                  &:nth-child(1) {
                    &:before {
                      width: var(--grid-column12);
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  height: calc(373 / 587 * var(--grid-column4));
                }

                &__item {
                  grid-column: span 4;

                  &:nth-child(1) {
                    &:before {
                      width: var(--grid-column12);
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_4 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__item {
                  grid-column: span 6;

                  &:nth-child(2),
                  &:nth-child(4) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(1),
                  &:nth-child(2) {
                    .images-block {
                      &__picture {
                        height: calc(657 / 790 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(3) {
                    &:after {
                      position: absolute;
                      content: '';
                      width: var(--grid-column12);
                      height: 1px;
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(4) {
                    .images-block {
                      &__picture {
                        height: calc(506 / 790 * var(--grid-column6));
                      }
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__item {
                  grid-column: span 6;

                  &:nth-child(2),
                  &:nth-child(4) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(3) {
                    &:after {
                      position: absolute;
                      content: '';
                      width: var(--grid-column12);
                      height: 1px;
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }

                &__picture {
                  height: calc(420 / 660 * var(--grid-column6));
                }
              }
            }
          }
        }
      }

      &_5 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__item {
                  &:nth-child(1),
                  &:nth-child(2) {
                    grid-column: span 6;

                    .images-block {
                      &__picture {
                        height: calc(657 / 790 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5) {
                    grid-column: span 4;

                    .images-block {
                      &__picture {
                        height: calc(273 / 427 * var(--grid-column4));
                      }
                    }
                  }

                  &:nth-child(3) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column12);
                      background: var(--line-on-white);
                      top: calc(-1 * var(--grid-gap) / 2);
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(4),
                  &:nth-child(5) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      background: var(--line-on-white);
                      left: calc(-1 * var(--grid-gap) / 2);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__item {
                  &:nth-child(1),
                  &:nth-child(2) {
                    grid-column: span 6;

                    .images-block {
                      &__picture {
                        height: calc(420 / 660 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5) {
                    grid-column: span 4;

                    .images-block {
                      &__picture {
                        height: calc(272 / 427 * var(--grid-column4));
                      }
                    }
                  }

                  &:nth-child(3) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column12);
                      background: var(--line-on-white);
                      top: calc(-1 * var(--grid-gap) / 2);
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(4),
                  &:nth-child(5) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      background: var(--line-on-white);
                      left: calc(-1 * var(--grid-gap) / 2);
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_6 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__item {
                  &:nth-child(1),
                  &:nth-child(2) {
                    grid-column: span 6;

                    .images-block {
                      &__picture {
                        height: calc(657 / 790 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5),
                  &:nth-child(6) {
                    grid-column: span 3;

                    .images-block {
                      &__picture {
                        height: calc(273 / 427 * var(--grid-column3));
                      }
                    }
                  }

                  &:nth-child(3) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column12);
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(4),
                  &:nth-child(5),
                  &:nth-child(6) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      top: 0;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__item {
                  grid-column: span 6;

                  &:nth-child(2),
                  &:nth-child(4),
                  &:nth-child(6) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      top: 0;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(5) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column12);
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }

                &__picture {
                  height: calc(502 / 790 * var(--grid-column6));
                }
              }
            }
          }
        }
      }

      &_7 {
        &.images-block {
          &__images-wrapper {
            .images-block {
              &__picture {
                width: var(--grid-column5);
                height: calc(841 / 1010 * var(--grid-column5));
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .images-block {
    &_bottom-line {
      .images-block {
        &__list {
          padding-bottom: calc(var(--grid-gap) / 2);
          border-bottom: 1px solid var(--line-on-white);
        }
      }
    }

    &__video-link {
      left: 30px;
      bottom: 30px;
    }

    &__vl-button {
      width: 60px;
      height: 60px;
      margin-right: 25px;

      &:before {
        margin-left: 2px;
        border-width: 10.5px 0 10.5px 18px;
      }
    }
  }
}

@include respond('medium') {
  .images-block {
    &__title-wrapper-grid {
      width: var(--grid-column5);
    }

    &__article {
      margin-right: calc(var(--grid-column1) + var(--grid-gap));
    }

    &__article-image {
      margin-top: 60px;
    }

    &__article-table {
      margin-top: 60px;
    }

    &__slider-wrapper {
      margin-top: 100px;
    }

    &__lower-description {
      grid-area: description;
    }

    &__upper-description {
      padding-top: 25px;

      &_arrows {
        margin-bottom: 70px;
      }
    }

    &__images-wrapper {
      margin-top: 20px;

      &_description {
        margin-top: 50px;
      }

      &_no-description {
        .images-block {
          &__list {
            padding-top: 15px;
          }
        }
      }

      &_plannings {
      }

      &_common {
      }

      &_1 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__picture {
                  height: calc(657 / 790 * var(--grid-column6));
                }

                &__item {
                  &:nth-child(1) {
                    grid-column: span 6;

                    &:before {
                      width: var(--grid-column6);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  height: calc(589 / 1119 * var(--grid-column6));
                }

                &__item {
                  grid-column: span 6;

                  &:before {
                    width: var(--grid-column6);
                  }

                }
              }
            }
          }
        }
      }

      &_2 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__picture {
                  height: calc(644 / 774 * var(--grid-column6));
                }

                &__item {
                  &:nth-child(2) {
                    &:after {
                      top: calc(-1 / 2 * var(--grid-gap));
                      bottom: unset;
                    }
                  }

                  &:nth-child(1) {
                    &:before {
                      width: var(--grid-column6);
                    }
                  }

                  &:nth-child(1),
                  &:nth-child(2) {
                    grid-column: span 6;
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  height: calc(348 / 545 * var(--grid-column3));
                }

                &__item {
                  &:nth-child(2) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      top: 0;
                      background: var(--line-on-white);
                      left: calc(-1 * var(--grid-gap) / 2);
                    }
                  }

                  &:nth-child(1) {
                    &:before {
                      width: var(--grid-column6);
                    }
                  }

                  &:nth-child(1),
                  &:nth-child(2) {
                    grid-column: span 3;
                  }
                }
              }
            }
          }
        }
      }

      &_3 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__item {
                  grid-column: span 6;

                  &:nth-child(1),
                  &:nth-child(2) {
                    .images-block {
                      &__picture {
                        height: calc(644 / 774 * var(--grid-column6));
                      }
                    }

                    &:before {
                      width: var(--grid-column6);
                    }
                  }

                  &:nth-child(3) {
                    .images-block {
                      &__picture {
                        height: calc(494 / 774 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: 100%;
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__item {
                  &:nth-child(1) {
                    grid-column: span 6;

                    .images-block {
                      &__picture {
                        height: calc(712 / 1119 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    grid-column: span 3;

                    .images-block {
                      &__picture {
                        height: calc(348 / 545 * var(--grid-column3));
                      }
                    }
                  }

                  &:nth-child(2) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column6);
                      background: var(--line-on-white);
                      top: calc(-1 * var(--grid-gap) / 2);
                    }
                  }

                  &:nth-child(3) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      background: var(--line-on-white);
                      left: calc(-1 * var(--grid-gap) / 2);
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_4 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__item {
                  grid-column: span 3;

                  &:nth-child(2),
                  &:nth-child(4) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(1),
                  &:nth-child(2) {
                    .images-block {
                      &__picture {
                        height: calc(657 / 790 * var(--grid-column3));
                      }
                    }
                  }

                  &:nth-child(3) {
                    &:after {
                      position: absolute;
                      content: '';
                      width: var(--grid-column12);
                      height: 1px;
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(4) {
                    .images-block {
                      &__picture {
                        height: calc(506 / 790 * var(--grid-column3));
                      }
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  height: calc(506 / 790 * var(--grid-column3));
                }

                &__item {
                  grid-column: span 3;

                  &:nth-child(2),
                  &:nth-child(4) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(3) {
                    &:after {
                      position: absolute;
                      content: '';
                      width: var(--grid-column6);
                      height: 1px;
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_5 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__item {
                  &:nth-child(1),
                  &:nth-child(2) {
                    .images-block {
                      &__picture {
                        height: calc(932 / 1119 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(3) {
                    .images-block {
                      &__picture {
                        height: calc(716 / 1119 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(1),
                  &:nth-child(2),
                  &:nth-child(3) {
                    grid-column: span 6;
                  }

                  &:nth-child(4),
                  &:nth-child(5) {
                    grid-column: span 3;
                  }

                  &:nth-child(2),
                  &:nth-child(3),
                  &:nth-child(4) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column6);
                      background: var(--line-on-white);
                      top: calc(-1 * var(--grid-gap) / 2);
                    }
                  }

                  &:nth-child(4),
                  &:nth-child(5) {
                    .images-block {
                      &__picture {
                        height: calc(349 / 545 * var(--grid-column3));
                      }
                    }
                  }

                  &:nth-child(5) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      background: var(--line-on-white);
                      left: calc(-1 * var(--grid-gap) / 2);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__item {
                  &:nth-child(1) {
                    .images-block {
                      &__picture {
                        height: calc(589 / 1119 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(1) {
                    grid-column: span 6;
                  }

                  &:nth-child(2),
                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5) {
                    grid-column: span 3;
                  }

                  &:nth-child(2),
                    //&:nth-child(3),
                  &:nth-child(4) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column6);
                      background: var(--line-on-white);
                      top: calc(-1 * var(--grid-gap) / 2);
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5) {
                    .images-block {
                      &__picture {
                        height: calc(349 / 545 * var(--grid-column3));
                      }
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(5) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      background: var(--line-on-white);
                      left: calc(-1 * var(--grid-gap) / 2);
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_6 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__item {
                  &:nth-child(1),
                  &:nth-child(2) {
                    grid-column: span 6;

                    .images-block {
                      &__picture {
                        height: calc(932 / 1119 * var(--grid-column6));
                      }
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5),
                  &:nth-child(6) {
                    grid-column: span 3;

                    .images-block {
                      &__picture {
                        height: calc(349 / 545 * var(--grid-column3));
                      }
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column6);
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(5),
                  &:nth-child(6) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: 100%;
                      background: var(--line-on-white);
                      top: calc(-1 * var(--grid-gap) / 2);
                    }
                  }

                  &:nth-child(4),
                  &:nth-child(6) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      top: 0;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__item {
                  grid-column: span 3;

                  &:nth-child(2),
                  &:nth-child(4),
                  &:nth-child(6) {
                    &:after {
                      position: absolute;
                      content: '';
                      height: 100%;
                      width: 1px;
                      top: 0;
                      left: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }

                  &:nth-child(3),
                  &:nth-child(5) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: var(--grid-column6);
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }

                &__picture {
                  height: calc(502 / 790 * var(--grid-column3));
                }
              }
            }
          }
        }
      }

      &_7 {
        &.images-block {
          &__images-wrapper {
            .images-block {
              &__picture {
                width: var(--grid-column4);
                height: calc(841 / 1010 * var(--grid-column4));
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .images-block {
    &__images-wrapper {
      &_2 {
        .images-block {
          &__item {
            &:nth-child(2) {
              &:after {
                position: absolute;
                content: '';
                height: 1px;
                background: var(--line-on-white);
                bottom: calc(-1 * var(--grid-gap) / 2);
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .images-block {
    &__article-table {
      margin-top: 30px;
    }

    &__article {
      overflow: hidden;
    }

    &__vl-button {
      width: 46px;
      height: 46px;
      margin-right: 14px;

      &:before {
        margin-left: 1px;
        border-width: 8px 0 8px 14px;
      }
    }

    &__video-link {
      left: 18px;
      bottom: 16px;
    }

    &__article-image {
      margin-top: 30px;
    }

    &__slider-wrapper {
      margin-top: 50px;
    }

    &__upper-description {
      padding-top: 15px;
    }

    &__images-wrapper {
      margin-top: 12px;

      &_description {
        margin-top: 30px;
      }

      &_no-description {
        .images-block {
          &__list {
            padding-top: 9px;
          }
        }
      }


      &_1 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__picture {
                  height: calc(644 / 774 * var(--grid-column4));
                }

                &__item {
                  &:nth-child(1) {
                    grid-column: span 4;

                    &:before {
                      width: var(--grid-column4);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  height: calc(465 / 731 * var(--grid-column4));
                }

                &__item {
                  grid-column: span 4;

                  &:before {
                    width: var(--grid-column4);
                  }
                }
              }
            }
          }
        }
      }

      &_2 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__picture {
                  height: calc(644 / 774 * var(--grid-column4));
                }

                &__item {
                  grid-column: span 4;

                  &:nth-child(2) {
                    &:after {
                      top: calc(-1 / 2 * var(--grid-gap));
                      bottom: unset;
                    }
                  }

                  &:nth-child(1) {
                    &:before {
                      width: var(--grid-column4);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  height: calc(465 / 731 * var(--grid-column4));
                }

                &__item {
                  grid-column: span 4;

                  &:nth-child(2) {
                    &:after {
                      top: calc(-1 / 2 * var(--grid-gap));
                      bottom: unset;
                    }
                  }

                  &:nth-child(1) {
                    &:before {
                      width: var(--grid-column4);
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_3 {
        &.images-block {
          &__images-wrapper {
            &_plannings {
              .images-block {
                &__item {
                  grid-column: span 4;

                  &:nth-child(1),
                  &:nth-child(2) {
                    .images-block {
                      &__picture {
                        height: calc(644 / 774 * var(--grid-column4));
                      }
                    }

                    &:before {
                      width: var(--grid-column4);
                    }
                  }

                  &:nth-child(3) {
                    .images-block {
                      &__picture {
                        height: calc(494 / 774 * var(--grid-column4));
                      }
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: 100%;
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }

            &_common {
              .images-block {
                &__picture {
                  height: calc(494 / 774 * var(--grid-column4));
                }

                &__item {
                  grid-column: span 4;

                  &:nth-child(1),
                  &:nth-child(2) {
                    &:before {
                      width: var(--grid-column4);
                    }
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    &:before {
                      position: absolute;
                      content: '';
                      height: 1px;
                      width: 100%;
                      top: calc(-1 * var(--grid-gap) / 2);
                      background: var(--line-on-white);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__images-wrapper {
      &:not(.images-block__images-wrapper_1) {
        .images-block {
          &__list {
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }

    &__item {
      &.basic-slider__item.swiper-slide {
        &:last-child {
          &::after {
            width: 100%;
          }
        }

        .images-block {
          &__picture {
            height: calc((var(--grid-column4) - 18px) * 266 / 320);
          }
        }
      }
    }

    &__link-to-form {
      margin-top: 30px;

      &_list {
        margin-top: calc(var(--grid-gap) / 2);
      }
    }

    &_bottom-line {
      .images-block {
        &__list {
          &.grid-block {
            padding-bottom: calc(var(--grid-gap) / 2);
            border-bottom: 1px solid var(--line-on-white);
          }
        }
      }
    }
  }
}