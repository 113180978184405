.index-page {
  &__wrap-video-link {
    overflow: hidden;
  }

  // "На десктопе полоску разделитель - спляжа" - after review
  //&__index-reviews {
  //  .reviews-slider {
  //    border-bottom: 1px solid var(--line-on-white);
  //  }
  //}
}

@include respond-up('s-large') {
  .index-page {
    &__form-block {
      margin-top: 60px;
    }

    &__wrap-video-link {
      margin-top: 40px;
    }
  }
}


@include respond('medium') {
  .index-page {
    &__form-block {
      margin-top: 40px;
    }

    &__wrap-video-link {
      margin-top: 100px;
    }
  }
}


@include respond-down('medium') {
  .index-page {
    &__index-reviews {
      .reviews-slider {
        border-bottom: 1px solid var(--line-on-white);
      }
    }
  }
}

@include respond-down('small') {
  .index-page {
    &__form-block {
      margin-top: 30px;
    }

    &__wrap-video-link {
      margin-top: 50px;
    }
  }
}