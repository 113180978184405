
.checkbox-list__input-label{
  &:hover{
    cursor: pointer;
    background-color: var(--accent-brown);
    color: var(--base-white-100);
  }
}
.checkbox-list__value-item.active {
  label{
    background-color: var(--accent-brown);
    color: var(--base-white-100);
  }
}
