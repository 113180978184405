.about-production {
  border-top: 1px solid var(--line-on-white);
  display: flex;

  &__wrap-text{
    display: flex;
    flex-direction: column;
  }

  &__wrap-description{
    display: flex;
    gap: var(--grid-gap);
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-block__picture{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@include respond-up('s-large') {
  .about-production {
    margin-top: 150px;
    justify-content: space-between;
    &__title{
      margin-top: 50px;
    }
    &__wrap-description{
      margin-top: 40px;
      display: flex;
      flex-direction: row;
    }
    .video-block__picture{
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 382 / 513);
    }
    &__link{
      margin-top: 50px;
    }

    .images-block-small__video-link-title-wrap {
      width: var(--grid-column3);
    }
  }
}

@include respond-up('medium') {
  .about-production {
    padding-top: 20px;

    &__wrap-text{
      width: var(--grid-column6);
    }

    &__link{
      width: 295px;
    }
  }
}

@include respond('medium') {
  .about-production {
    margin-top: 100px;

    &__title{
      margin-top: 30px;
    }

    &__wrap-description{
      margin-top: 30px;
    }

    .video-block__picture{
      width: var(--grid-column6);
      height: calc(var(--grid-column6) * 490 / 774);
    }

    &__link{
      margin-top: 40px;
    }

    &__wrap-picture{
      margin-top: 50px;
    }

    .images-block-small__video-link-title-wrap {
      width: var(--grid-column4);
    }
  }
}

@include respond-down('medium') {
  .about-production {
    flex-direction: column;
  }
}

@include respond-down('small') {
  .about-production {
    margin-top: 50px;
    padding-top: 10px;


    &__title{
      margin-top: 20px;
    }

    &__wrap-description{
      margin-top: 15px;
      flex-direction: column;
    }

    .video-block__picture{
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * 214 / 338);
    }

    &__link{
      margin-top: 20px;
      width: 100%;
    }

    &__wrap-picture{
      margin-top: 30px;
    }


    .images-block-small__video-link-title-wrap {
      width: var(--grid-column3);
    }
  }
}